<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/dpmptsp_logo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">National Single Window for Investment (NSWI)</span>
                <span v-else>National Single Window for Investment (NSWI)</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data NSWI</span>
                    <span v-else>NSWI Data</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="container-fluid feature bg-trans py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div class="cardputih4 card-outline card-outline-tabs bg-trans">
            <div class="cardputih3-header p-0 border-bottom-0">
                <div class="container-fluid feature py-3">
                    <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="sub-style">
                            <h2 class="sub-title px-3 mb-0">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Klasifikasi Data</span>
                                <span v-else>Data Classification</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-sm-12">&nbsp;</div>
                    <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                                href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                                aria-selected="true">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                    Realisasi Perusahaan</span>
                                <span v-else>Company Realization</span>
                            </a>
                        </li>
                        <li class="nav-item" @click="iumkperizinan()">
                            <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                                href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
                                aria-selected="false">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                    IUMK</span>
                                <span v-else>IUMK</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                            aria-labelledby="custom-tabs-four-home-tab">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                <p class="first-letter-besar">
                                    Mencakup kegiatan penanaman modal yang dilakukan perusahaan di setiap lokasi dan
                                    bidang usaha investasi, kecuali bidang usaha perdagangan.
                                </p>
                            </span>
                            <span v-else>
                                <p class="first-letter-besar">
                                    Includes investment activities carried out by the company in every location and
                                    investment business sector, except the trading business sector.
                                </p>
                            </span>
                            <div class="container-fluid feature py-3">
                                <div class="section-title mb-0">
                                    <div class="sub-style">
                                        <h4 class="sub-title px-3 mb-0">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Perusahaan</span>
                                            <span v-else>Company Data</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row px-2">
                                <div class="col-sm-12">
                                    <label>
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                        <span v-else>Select Year</span>
                                    </label>
                                    <v-select :options="datatahunsektor" :reduce="(label) => label.code" label="label"
                                        v-model="defaultSelectedtahunsektor" @update:modelValue="ontahunsektorChange"
                                        style="background-color: #ffffff;"></v-select>
                                </div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="col-sm-12 wow fadeInUp" data-wow-delay="0.1s">
                                    <BarChartSektorJmlInvesPerusahaan height="450"
                                        :options="chartOptionsSektorJmlInvesPerusahaan"
                                        :series="seriesSektorJmlInvesPerusahaan">
                                    </BarChartSektorJmlInvesPerusahaan>
                                </div>
                                <div class="col-sm-12 wow fadeInUp" data-wow-delay="0.1s">
                                    <BarChartSektorJmlPerusahaan height="450" :options="chartOptionsSektorJmlPerusahaan"
                                        :series="seriesSektorJmlPerusahaan">
                                    </BarChartSektorJmlPerusahaan>
                                </div>
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Data
                                                    Realisasi Perusahaan</span>
                                                <span v-else>Company Realization Data </span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabelinvestasi">
                                                    <thead>
                                                        <tr>
                                                            <td class="levelmerah">No</td>
                                                            <td class="levelmerah">Sektor<br>(Sector)
                                                            </td>
                                                            <td class="levelmerah">Jumlah Investasi<br>(Investment
                                                                Amount)</td>
                                                            <td class="levelmerah">
                                                                Jumlah Perusahaan<br>(Number of Companies)
                                                            </td>
                                                            <td class="levelmerah">
                                                                Jumlah Tenaga Kerja<br>(Total Manpower)
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in sektor" :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="komponenakhir">
                                                                    {{ urutlist + 1 }}
                                                                </td>
                                                                <td class="komponenakhir text-justify">
                                                                    {{ datalist.Nama_Sektor }}
                                                                </td>
                                                                <td class="komponenakhir text-right">
                                                                    <span
                                                                        v-if="(bahasa === null) || (bahasa == 'ina')">Rp.
                                                                        {{
                                                                            G_numFormatKoma(datalist.Nilai_Investasi_Rupiah)
                                                                        }} M</span>
                                                                    <span v-else>{{
                                                                        G_numFormatKoma(datalist.Nilai_Investasi_Dollar)
                                                                        }} Billion</span>
                                                                </td>
                                                                <td class="komponenakhir text-right">
                                                                    {{ G_numFormat(datalist.JumlahPerusahaan) }}
                                                                </td>
                                                                <td class="komponenakhir text-right">
                                                                    {{ G_numFormat(datalist.TKI) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <h6 style="font-size: x-small;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: NSWI</span>
                                                <span v-else>Source: NSWI</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                            aria-labelledby="custom-tabs-four-profile-tab">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                <p class="first-letter-besar">
                                    Pemerintah telah menetapkan Izin Usaha Mikro dan Kecil (IUMK). Pada dasarnya, IUMK
                                    merupakan simplifikasi
                                    izin bagi para pelaku usaha mikro dan kecil dalam bentuk naskah 1 lembar yang dapat
                                    ditetapkan oleh Pemda
                                    (Camat) hingga tingkat Kelurahan/Desa.
                                </p>
                            </span>
                            <span v-else>
                                <p class="first-letter-besar">
                                    The government has established Micro and Small Business Permits (IUMK). Basically,
                                    IUMK is a simplified
                                    permit for micro and small business actors in the form of a 1-sheet document which
                                    can be determined by the
                                    Regional Government (District Head) up to the Subdistrict/Village level.
                                </p>
                            </span>
                            <div class="container-fluid feature py-3">
                                <div class="section-title mb-0">
                                    <div class="sub-style">
                                        <h4 class="sub-title px-3 mb-0">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Izin Usaha Mikro
                                                dan Kecil</span>
                                            <span v-else>Micro and Small Business License Data</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row px-2">
                                <div class="col-sm-12">
                                    <label>
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                        <span v-else>Select Year</span>
                                    </label>
                                    <v-select :options="datatahuniumk" :reduce="(label) => label.code" label="label"
                                        v-model="defaultSelectedtahuniumk" @update:modelValue="ontahuniumkChange"
                                        style="background-color: #ffffff;"></v-select>
                                </div>
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="col-lg-6 text-center">
                                    <DonutsChartStatusIUMK type="donut" :options="chartOptionsstatusiumk"
                                        :series="seriesstatusiumk" height="350">
                                    </DonutsChartStatusIUMK>
                                </div>
                                <div class="col-lg-6 text-center">
                                    <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                        <div class="card cardputih3">
                                            <div class="cardputih3-header border-transparent">
                                                <h6 class="float-left">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Status IUMK</span>
                                                    <span v-else>IUMK Status</span>
                                                </h6>
                                                <div class="card-tools float-right">
                                                    <button type="button" class="btn btn-tool"
                                                        data-card-widget="collapse">
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body p-0">
                                                <div class="table-responsive table-wrapper">
                                                    <table class="table m-0" id="tabelinvestasi">
                                                        <thead>
                                                            <tr>
                                                                <td class="levelmerah">No</td>
                                                                <td class="levelmerah">Status
                                                                </td>
                                                                <td class="levelmerah">
                                                                    Jumlah<br>(Amount)
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <template v-for="(datalist, urutlist) in iumk"
                                                            :key="urutlist">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="komponenakhir">
                                                                        {{ urutlist + 1 }}
                                                                    </td>
                                                                    <td class="komponenakhir text-justify">
                                                                        {{ datalist.Status }}
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        {{ G_numFormat(datalist.Jumlah) }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="card-footer p-3">
                                                <h6 style="font-size: x-small;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: NSWI</span>
                                                    <span v-else>Source: NSWI</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, vSelect, BarChartSektorJmlInvesPerusahaan: VueApexCharts
        , BarChartSektorJmlPerusahaan: VueApexCharts, DonutsChartStatusIUMK: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            datapesan: '',
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            sektor: [],
            datamastertahunsektor: [],
            datatahunsektor: [],
            defaultSelectedtahunsektor: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunsektor: '',

            iumk: [],
            datamastertahuniumk: [],
            datatahuniumk: [],
            defaultSelectedtahuniumk: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahuniumk: '',

            seriesstatusiumk: [],
            chartOptionsstatusiumk: {
                chart: {
                    type: 'donut',
                },
                colors: ['#20B2AA', '#FFA500', '#32CD32', '#FF0000', '#20B2AA', '#32CD32'],
                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            seriesSektorJmlInvesPerusahaan: [],
            chartOptionsSektorJmlInvesPerusahaan: {
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                colors: ['#f9a867', '#6a6ba5'],
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return parseFloat(val).toFixed(2).toString().replaceAll(".", ",");
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '8px',
                        colors: ['#ff7f00', '#02366a'],
                    }
                },
                xaxis: {
                    categories: [],
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#02366a',
                                colorTo: '#111',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    },
                    labels: {
                        trim: true,
                        rotate: -90,
                        hideOverlappingLabels: true,
                        style: {
                            colors: [],
                            fontSize: '10px',
                        },
                    },
                    title: {
                        text: 'Sektor (Sector)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return parseFloat(val).toFixed(2).toString().replaceAll(".", ",");
                        }
                    }
                },
                title: {
                    text: 'Nilai Investasi',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Investment Value)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            seriesSektorJmlPerusahaan: [],
            chartOptionsSektorJmlPerusahaan: {
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                colors: ['#6a6ba5', '#f9a867'],
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '8px',
                        colors: ['#02366a', '#ff7f00'],
                    }
                },
                xaxis: {
                    categories: [],
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#02366a',
                                colorTo: '#111',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    },
                    labels: {
                        trim: true,
                        rotate: -90,
                        hideOverlappingLabels: true,
                        style: {
                            colors: [],
                            fontSize: '10px',
                        },
                    },
                    title: {
                        text: 'Sektor (Sector)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    }
                },
                title: {
                    text: 'Jumlah Perusahaan & Tenaga Kerja ',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Number of Companies & Workers)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

        };
    },
    methods: {
        ontahunsektorChange(a) {
            try {
                this.selectedtahunsektor = this.datatahunsektor[a].tag;
                this.sektorperizinan(this.selectedtahunsektor);
            }
            catch (err) {
                return;
            }
        },
        ontahuniumkChange(a) {
            try {
                this.selectedtahuniumk = this.datatahuniumk[a].tag;
                this.iumkperizinan(this.selectedtahuniumk);
            }
            catch (err) {
                return;
            }
        },
        async iumkperizinan(tahun) {
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafikNSWI-GetResikoIUMK?tahun=" + this.selectedtahuniumk).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.iumk = Response.data.content.data[0].tabel;
                        this.seriesstatusiumk = Response.data.content.data[0].Jumlah;
                        this.chartOptionsstatusiumk = {
                            labels: Response.data.content.data[0].Status,
                        };
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.iumk = [];
                this.seriesstatusiumk = [];
                this.chartOptionsstatusiumk = {
                    labels: []
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
        async sektorperizinan(tahun) {
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafikNSWI-GetBarSektorPerusahaan?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.sektor = Response.data.content.detail;
                        this.chartOptionsSektorJmlInvesPerusahaan = {
                            xaxis: {
                                categories: Response.data.content.data[0].NamaSektor,
                            }
                        }
                        this.seriesSektorJmlInvesPerusahaan = [
                            {
                                name: 'Nilai Investasi (Milyard Rupiah)',
                                data: Response.data.content.data[0].NilaiInvestasiRupiah
                            },
                            {
                                name: 'Investment Value (Billion Dollars)',
                                data: Response.data.content.data[0].NilaiInvestasiDollar
                            },
                        ];
                        this.chartOptionsSektorJmlPerusahaan = {
                            xaxis: {
                                categories: Response.data.content.data[0].NamaSektor,
                            }
                        }
                        this.seriesSektorJmlPerusahaan = [
                            {
                                name: 'Jumlah Perusahaan / Number of Companies',
                                data: Response.data.content.data[0].JumlahPerusahaan
                            },
                            {
                                name: 'Jumlah Tenaga Kerja / Number of Workers',
                                data: Response.data.content.data[0].TKI
                            },
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsSektorJmlInvesPerusahaan = {
                    xaxis: {
                        categories: [],
                    }
                };
                this.seriesSektorJmlInvesPerusahaan = [];
                this.chartOptionsSektorJmlPerusahaan = {
                    xaxis: {
                        categories: [],
                    },
                };
                this.seriesSektorJmlPerusahaan = [];
                console.log(error);
                this.halamanloading = false;
            });

        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            await mainAPI.get("datakitaGrafikNSWI-GetTahunSektorPerusahaan").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamastertahunsektor = Response.data.content.data;
                        this.datamastertahunsektor.forEach((item) => {
                            this.datatahunsektor.push({
                                label: item.Tahun,
                                code: this.datamastertahunsektor.indexOf(item) + 1,
                                tag: item.Tahun,
                            });
                        });
                        this.datatahunsektor.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datatahunsektor = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("datakitaGrafikNSWI-GetTahunIUMK").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamastertahuniumk = Response.data.content.data;
                        this.datamastertahuniumk.forEach((item) => {
                            this.datatahuniumk.push({
                                label: item.Tahun,
                                code: this.datamastertahuniumk.indexOf(item) + 1,
                                tag: item.Tahun,
                            });
                        });
                        this.datatahuniumk.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamastertahuniumk = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("datakitaGrafikNSWI-GetBarSektorPerusahaan").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.sektor = Response.data.content.detail;
                        this.chartOptionsSektorJmlInvesPerusahaan = {
                            xaxis: {
                                categories: Response.data.content.data[0].NamaSektor,
                            }
                        }
                        if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                            this.seriesSektorJmlInvesPerusahaan = [
                                {
                                    name: 'Nilai Investasi (Milyard Rupiah)',
                                    data: Response.data.content.data[0].NilaiInvestasiRupiah
                                },
                            ];
                        }
                        else {
                            this.seriesSektorJmlInvesPerusahaan = [
                                {
                                    name: 'Investment Value (Billion Dollars)',
                                    data: Response.data.content.data[0].NilaiInvestasiDollar
                                },
                            ];
                        }

                        this.chartOptionsSektorJmlPerusahaan = {
                            xaxis: {
                                categories: Response.data.content.data[0].NamaSektor,
                            }
                        }
                        this.seriesSektorJmlPerusahaan = [
                            {
                                name: 'Jumlah Perusahaan / Number of Companies',
                                data: Response.data.content.data[0].JumlahPerusahaan
                            },
                            {
                                name: 'Jumlah Tenaga Kerja / Number of Workers',
                                data: Response.data.content.data[0].TKI
                            },
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsSektorJmlInvesPerusahaan = {
                    xaxis: {
                        categories: [],
                    }
                };
                this.seriesSektorJmlInvesPerusahaan = [];
                this.chartOptionsSektorJmlPerusahaan = {
                    xaxis: {
                        categories: [],
                    },
                };
                this.seriesSektorJmlPerusahaan = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>