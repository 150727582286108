<template>
    <!-- Copyright Start -->
    <div class="container-fluid copyright py-4">
        <div class="container">
            <div class="row g-6 align-items-center">
                <div class="col-md-4 text-center text-md-start mb-md-0">
                    <span class="text-white">
                        <a href="#" class="merahmaroon">
                            <i class="fas fa-copyright text-light me-2"></i> DATAKITA
                        </a>
                        <p>Data Kompilasi Ijin dan Investasi Kota Semarang</p>
                        <p>{{ tahun }} All right reserved.<br></p>
                        Designed By <a href="https://izin.semarangkota.go.id/" target="_blank"><span
                                class="merahmaroon">DPMPTSP KOTA SEMARANG</span> </a>
                    </span>
                </div>
                <div class="col-md-8 text-center text-md-start mb-md-0">
                    <a href="#" class="merahmaroon">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Pengunjung</span>
                        <span v-else>Number of visitors</span>
                    </a><br>
                    <a href="#" class="text-white">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Hari ini: {{ G_numFormat(harini) }}</span>
                        <span v-else>Today: {{ G_numFormat(harini) }}</span>
                    </a><br>
                    <a href="#" class="text-white">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Bulan ini: {{ G_numFormat(bulanini) }}</span>
                        <span v-else>This month: {{ G_numFormat(bulanini) }}</span>
                    </a><br>
                    <a href="#" class="text-white">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Tahun ini: {{ G_numFormat(tahunini) }}</span>
                        <span v-else>This year: {{ G_numFormat(tahunini) }}</span>
                    </a>
                    <br>
                    <a href="#" class="text-white">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Total Pengunjung: {{ G_numFormat(totalpengunjung) }}</span>
                        <span v-else>Total visitor: {{ G_numFormat(totalpengunjung) }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- Copyright End -->
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

export default {
    components: {
        swal
    },
    data() {
        return {
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            harini: 0,
            bulanini: 0,
            tahunini: 0,
            totalpengunjung: 0,
        };
    },
    methods: {
        async ambildata() {
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            await mainAPI.get("datakitaVisitor-GetVisitor").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.harini = Response.data.hariini;
                        this.bulanini = Response.data.bulanini;
                        this.tahunini = Response.data.tahunini;
                        this.totalpengunjung = Response.data.total;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>