<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'Investasi'">
    <Investasi/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiData'">
    <InvestasiData/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataKBLI'">
    <InvestasiDataKBLI/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataResiko'">
    <InvestasiDataResiko/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataStatus'">
    <InvestasiDataStatus/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataSektor'">
    <InvestasiDataSektor/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDataPerusahaan'">
    <InvestasiDataPerusahaan/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetail'">
    <InvestasiDetail/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailKBLI'">
    <InvestasiDetailKBLI/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailResiko'">
    <InvestasiDetailResiko/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailStatus'">
    <InvestasiDetailStatus/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailSektor'">
    <InvestasiDetailSektor/>
  </div>
  <div class="content" v-if="route.name == 'InvestasiDetailPerusahaan'">
    <InvestasiDetailPerusahaan/>
  </div>
  <div class="content" v-if="route.name == 'Perizinan'">
    <Perizinan/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'NSWI'">
    <NSWI/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'KBLI'">
    <KBLI/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKBLI'">
    <ProsesKBLI/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import Investasi from './components/views/Publik/Investasi.vue';
import InvestasiData from './components/views/Publik/InvestasiData.vue';
import InvestasiDataKBLI from './components/views/Publik/InvestasiDataKBLI.vue';
import InvestasiDataResiko from './components/views/Publik/InvestasiDataResiko.vue';
import InvestasiDataStatus from './components/views/Publik/InvestasiDataStatus.vue';
import InvestasiDataSektor from './components/views/Publik/InvestasiDataSektor.vue';
import InvestasiDataPerusahaan from './components/views/Publik/InvestasiDataPerusahaan.vue';
import InvestasiDetail from './components/views/Publik/InvestasiDetail.vue';
import InvestasiDetailKBLI from './components/views/Publik/InvestasiDetailKBLI.vue';
import InvestasiDetailResiko from './components/views/Publik/InvestasiDetailResiko.vue';
import InvestasiDetailStatus from './components/views/Publik/InvestasiDetailStatus.vue';
import InvestasiDetailSektor from './components/views/Publik/InvestasiDetailSektor.vue';
import InvestasiDetailPerusahaan from './components/views/Publik/InvestasiDetailPerusahaan.vue';
import Perizinan from './components/views/Publik/Perizinan.vue';
import Login from './components/views/Publik/Login.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import NSWI from './components/views/Publik/NSWI.vue';
import GIS from './components/views/Publik/GIS.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import KBLI from './components/views/Backoffice/KBLI/KBLI.vue';
import ProsesKBLI from './components/views/Backoffice/KBLI/ProsesKBLI.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Investasi,InvestasiData,InvestasiDataKBLI,InvestasiDataResiko
    ,InvestasiDataStatus,InvestasiDataSektor,InvestasiDataPerusahaan
    ,InvestasiDetail,InvestasiDetailKBLI,InvestasiDetailResiko
    ,InvestasiDetailStatus,InvestasiDetailSektor,InvestasiDetailPerusahaan
    ,Perizinan,Login,KontakKami,NSWI,GIS,Dashboard
    ,Slider,ProsesSlider,InfoBeranda,ProsesInfoBeranda
    ,KategoriData,ProsesKategoriData,KBLI,ProsesKBLI,LayerPeta,ProsesLayerPeta
  },
  mounted() {
    document.title = "DATAKITA";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
