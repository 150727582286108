<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/dpmptsp_logo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">DATA INVESTASI</span>
                <span v-else>INVESTMENT DATA</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a :href="'/Investasi/' + tabaktif">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Investasi</span>
                        <span v-else>Investment</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data KBLI</span>
                    <span v-else>KBLI Investment Data</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="container-fluid feature py-3">
        <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
            <div class="sub-style">
                <h4 class="sub-title px-3 mb-0">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Investasi KBLI {{ tulisKBLI }} Tahun {{
                        tulissecretencData }}</span>
                    <span v-else>KBLI {{ tulisKBLI }} Investment Data for {{ tulissecretencData }}</span>
                </h4>
            </div>
        </div>
    </div>
    <div class="container-fluid py-3 text-center wow fadeInUp" data-wow-delay="0.1s">
        <button type="button" class="btn btn-sm btn-danger3 text-white lebar2 wow fadeInUp" data-wow-delay="0.1s"
            @click="kembali()" data-toggle="tooltip" data-placement="left" title="Kembali.."><i
                class="fas fa-reply"></i>
            <span v-if="(bahasa === null) || (bahasa == 'ina')"> Kembali</span>
            <span v-else> Back</span>
        </button>
    </div>
    <div class="container-fluid feature py-4 wow fadeInUp" data-wow-delay="0.1s">
        <div class="row px-2">
            <div class="col-sm-3 wow fadeInUp" data-wow-delay="0.1s">
                <div class="card cardputih3">
                    <div class="cardputih3-header border-transparent">
                        <h6 class="float-left">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Ringkasan KBLI {{ tulisKBLI }} Tahun {{
                                tulissecretencData
                                }}</span>
                            <span v-else>Summary KBLI {{ tulisKBLI }} Year of {{ tulissecretencData }}</span>
                        </h6>
                        <div class="card-tools float-right">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="info-box bg-trans">
                                    <span class="info-box-icon bg-merah elevation-1">
                                        <i class="fas fa-chart-pie"></i>
                                    </span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Total
                                                Investasi KBLI</span>
                                            <span v-else>KBLI Total Investment</span>
                                        </span>
                                        <span class="info-box-number">
                                            {{ G_numFormatKoma(jumlah) }}
                                            <small>M</small>
                                        </span>
                                    </div>
                                    <!-- /.info-box-content -->
                                </div>
                                <!-- /.info-box -->
                            </div>
                            <div class="col-sm-12">
                                <div class="info-box bg-trans">
                                    <span class="info-box-icon bg-merah elevation-1">
                                        <i class="fas fa-charging-station"></i>
                                    </span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Investasi
                                                KBLI Tertinggi</span>
                                            <span v-else>KBLI Highest Investment</span>
                                        </span>
                                        <span class="info-box-number">
                                            {{ G_numFormatKoma(maximal) }}
                                            <small>M</small>
                                        </span>
                                    </div>
                                    <!-- /.info-box-content -->
                                </div>
                                <!-- /.info-box -->
                            </div>
                            <div class="col-sm-12">
                                <div class="info-box bg-trans">
                                    <span class="info-box-icon bg-merah elevation-1">
                                        <i class="fas fa-check-square"></i>
                                    </span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah
                                                Data</span>
                                            <span v-else>Amount of data</span>
                                        </span>
                                        <span class="info-box-number">
                                            {{ G_numFormat(count) }}
                                            <small>
                                                Record
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Proyek</span>
                                                <span v-else>Project</span>
                                            </small>
                                        </span>
                                    </div>
                                    <!-- /.info-box-content -->
                                </div>
                                <!-- /.info-box -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-9 wow fadeInUp" data-wow-delay="0.1s">
                <BarChartTotalInvestasiKecamatan height="450" :options="chartOptionsTotalInvestasiKecamatan"
                    :series="seriesTotalInvestasiKecamatan" @dataPointSelection="dataPointSelectionKecamatan">
                </BarChartTotalInvestasiKecamatan>
            </div>
            <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                <div class="col-lg-12 text-center">
                    <div class="card cardputih3">
                        <div class="cardputih3-header border-transparent">
                            <h6 class="float-left">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Investasi KBLI {{ tulisKBLI }}
                                    Per Kecamatan Tahun {{ tulissecretencData }}</span>
                                <span v-else>KBLI {{ tulisKBLI }} Investment Data Per District Year of {{
                                    tulissecretencData }}</span>
                            </h6>
                            <div class="card-tools float-right">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabelinvestasi">
                                    <thead>
                                        <tr>
                                            <td class="levelmerah">No</td>
                                            <td class="levelmerah">Kecamatan<br><i>Subdistrict</i></td>
                                            <td class="levelmerah">Luas Lahan (m<sup>2</sup>)<br><i>Land Area
                                                    (m<sup>2</sup>)</i>
                                            </td>
                                            <td class="levelmerah">
                                                Nilai Investasi<br><i>Investment Value</i></td>
                                            <td class="levelmerah">
                                                Nilai Kumulatif Investasi (Rp.)<br><i>Investment Cumulative Value
                                                    (IDR)</i>
                                            </td>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in datamasterJumlahInvesKecamatan"
                                        :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="komponenakhir">
                                                    {{ urutlist + 1 }}
                                                </td>
                                                <td class="komponenakhir">
                                                    <a href="#" @click="dataSelectionKecamatan(datalist.Kecamatan)">{{
                                                        datalist.Kecamatan }}</a>
                                                </td>
                                                <td class="komponenakhir">
                                                    {{ G_numFormatKoma(datalist.LuasTanahm2) }}
                                                </td>
                                                <td class="komponenakhir">
                                                    {{ G_numFormatKoma(datalist.JumlahInves) }} M
                                                </td>
                                                <td class="komponenakhir">
                                                    {{ G_numFormatKoma(datalist.JumlahKumulatif) }} M
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer p-3">
                            <h6 style="font-size: x-small;">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                    RBA</span>
                                <span v-else>Source: OSS RBA</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid py-3 text-center wow fadeInUp" data-wow-delay="0.1s">
        <button type="button" class="btn btn-sm btn-danger3 text-white lebar2 wow fadeInUp" data-wow-delay="0.1s"
            @click="kembali()" data-toggle="tooltip" data-placement="left" title="Kembali.."><i
                class="fas fa-reply"></i>
            <span v-if="(bahasa === null) || (bahasa == 'ina')"> Kembali</span>
            <span v-else> Back</span>
        </button>
    </div>

    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import VueApexCharts from "vue3-apexcharts";
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal, BarChartTotalInvestasiKecamatan: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            datapesan: '',
            jumlah: 0,
            maximal: 0,
            minimal: 0,
            count: 0,
            datamaster: [],
            datamasterJumlahInvesKecamatan: [],
            serieskecamatan: [],
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            secretencDataawal: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            secretencDatatemp: '',
            secretencData: '',
            tulissecretencData: '',
            tulisKBLI: '',
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('kbli|' + this.secretencData, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            seriesTotalInvestasiKecamatan: [],
            chartOptionsTotalInvestasiKecamatan: {
                colors: [
                    '#6a6ba5',
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                chart: {
                    height: '350',
                    width: '90%',
                    type: 'line',
                    stacked: 'true',
                    zoom: {
                        enabled: true,
                        type: 'x'
                    }
                },
                tooltip: {
                    followCursor: true,
                    shared: false
                },
                markers: {
                    size: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0
                },
                stroke: {
                    width: 2
                },
                xaxis: {
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: true,
                    },
                    categories: [],
                    title: {
                        text: 'Kecamatan (Subdistrict)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(2).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " M";
                    },
                    offsetY: -5,
                    style: {
                        fontSize: '8px',
                        colors: ['#5c6165', '#ff7f00'],
                    }
                },
                legend: {
                    showForSingleSeries: true
                },
                fill: {
                    opacity: 1
                },
                title: {
                    text: 'Nilai Investasi Per Kecamatan',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Investment Value Per District)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
        };
    },
    methods: {
        dataPointSelectionKecamatan(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            this.secretencDatatemp = this.secretencDataawal.split('|');
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.secretencData + '|' + this.serieskecamatan[dataPointIndex] + '|' + this.secretencDatatemp[1], 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDetailKBLI/" + bytes);
        },
        dataSelectionKecamatan(kecamatan) {
            this.secretencDatatemp = this.secretencDataawal.split('|');
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.secretencData + '|' + kecamatan + '|' + this.secretencDatatemp[1], 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDetailKBLI/" + bytes);
        },
        kembali() {
            window.location.replace("/Investasi/" + this.tabaktif);
        },
        jumpTo(komponenid) {
            const element = document.getElementById(komponenid);
            element.scrollIntoView();
        },
        async ambildata() {
            this.halamanloading = true;
            this.secretencDatatemp = this.secretencDataawal.split('|');
            this.secretencData = this.secretencDatatemp[0];
            if (this.secretencData == '') {
                if ((this.bahasa === null) || (this.bahasa == 'ina'))
                    this.tulissecretencData = ' Seluruh Tahun';
                else {
                    this.tulissecretencData = ' All Years';
                }
            }
            else {
                this.tulissecretencData = this.secretencData;
            }
            this.tabaktif = encodeURIComponent(CryptoJS.AES.encrypt('kbli|' + this.secretencData, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            await mainAPI.get("datakitaKBLI-GetKBLIID?kode=" + this.secretencDatatemp[1]).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.tulisKBLI = Response.data.content.data[0].nama;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.tulisKBLI = '';
                this.halamanloading = false;
            });

            await mainAPI.get("datakitaResume-GetResumeInvestasi?tahun=" + this.secretencData + "&kbli=" + this.secretencDatatemp[1]).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.jumlah = Response.data.content.data[0].jumlah;
                        this.maximal = Response.data.content.data[0].maximal;
                        this.minimal = Response.data.content.data[0].minimal;
                        this.count = Response.data.content.data[0].count;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.halamanloading = false;
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarTotalKecamatanInvestasi?tahun=" + this.secretencData + "&kbli=" + this.secretencDatatemp[1]).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterJumlahInvesKecamatan = [];
                        this.serieskecamatan = Response.data.content.data[0].Kecamatan;
                        this.datamasterJumlahInvesKecamatan = Response.data.content.data[0].tabel;
                        this.chartOptionsTotalInvestasiKecamatan = {
                            xaxis: {
                                categories: Response.data.content.data[0].Kecamatan
                            },
                            yaxis: {
                                tickAmount: 10,
                                labels: {
                                    formatter: function (value) {
                                        return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " M";
                                    }

                                },
                            },
                        };
                        this.seriesTotalInvestasiKecamatan = [
                            {
                                "name": "Total Investasi (Total  Investment)",
                                "type": "line",
                                "data": Response.data.content.data[0].TotalInvestasi
                            },
                            {
                                "name": "Nilai Investasi (Investment Value)",
                                "type": "bar",
                                "data": Response.data.content.data[0].JumlahInvestasi
                            },
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsTotalInvestasiKecamatan = {
                    xaxis: {
                        categories: []
                    },
                };
                this.seriesTotalInvestasiKecamatan = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();

    },
}
</script>

<style></style>