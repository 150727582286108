import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Investasi/:id',
    name: 'Investasi',
    component: () => import('../../components/views/Publik/Investasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiData/:id',
    name: 'InvestasiData',
    component: () => import('../../components/views/Publik/InvestasiData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDataKBLI/:id',
    name: 'InvestasiDataKBLI',
    component: () => import('../../components/views/Publik/InvestasiDataKBLI.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDataResiko/:id',
    name: 'InvestasiDataResiko',
    component: () => import('../../components/views/Publik/InvestasiDataResiko.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDataStatus/:id',
    name: 'InvestasiDataStatus',
    component: () => import('../../components/views/Publik/InvestasiDataStatus.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDataSektor/:id',
    name: 'InvestasiDataSektor',
    component: () => import('../../components/views/Publik/InvestasiDataSektor.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDataPerusahaan/:id',
    name: 'InvestasiDataPerusahaan',
    component: () => import('../../components/views/Publik/InvestasiDataPerusahaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDetail/:id',
    name: 'InvestasiDetail',
    component: () => import('../../components/views/Publik/InvestasiDetail.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDetailKBLI/:id',
    name: 'InvestasiDetailKBLI',
    component: () => import('../../components/views/Publik/InvestasiDetailKBLI.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDetailSektor/:id',
    name: 'InvestasiDetailSektor',
    component: () => import('../../components/views/Publik/InvestasiDetailSektor.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDetailPerusahaan/:id',
    name: 'InvestasiDetailPerusahaan',
    component: () => import('../../components/views/Publik/InvestasiDetailPerusahaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDetailResiko/:id',
    name: 'InvestasiDetailResiko',
    component: () => import('../../components/views/Publik/InvestasiDetailResiko.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InvestasiDetailStatus/:id',
    name: 'InvestasiDetailStatus',
    component: () => import('../../components/views/Publik/InvestasiDetailStatus.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Perizinan',
    name: 'Perizinan',
    component: () => import('../../components/views/Publik/Perizinan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KontakKami',
    name: 'KontakKami',
    component: () => import('../../components/views/Publik/KontakKami.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/NSWI',
    name: 'NSWI',
    component: () => import('../../components/views/Publik/NSWI.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/GIS',
    name: 'GIS',
    component: () => import('../../components/views/Publik/GIS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Slider',
    name: 'Slider',
    component: () => import('../../components/views/Backoffice/Slider/Slider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSlider/:id',
    name: 'ProsesSlider',
    component: () => import('../../components/views/Backoffice/Slider/ProsesSlider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InfoBeranda',
    name: 'InfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/InfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfoBeranda/:id',
    name: 'ProsesInfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KategoriData',
    name: 'KategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/KategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategoriData/:id',
    name: 'ProsesKategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/ProsesKategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KBLI',
    name: 'KBLI',
    component: () => import('../../components/views/Backoffice/KBLI/KBLI.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKBLI/:id',
    name: 'ProsesKBLI',
    component: () => import('../../components/views/Backoffice/KBLI/ProsesKBLI.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/LayerPeta',
    name: 'LayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/LayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLayerPeta/:id',
    name: 'ProsesLayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
