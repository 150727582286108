<template>
    <Top />
    <div class="modal fade" id="costumModaldetailinvestasi" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
        aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
        style="margin: 0;">
        <div class="modal-dialog" style="position: absolute;top: 0;">
            <div class="modal-content" style="height: auto;min-width: 100vw;">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Daftar Detail Data Investasi - Jumlah Data :
                            {{ G_numFormat(jumlahdata) }}</span>
                        <span v-else>List of Detailed Investment Data - Amount of data : {{ G_numFormat(jumlahdata)
                            }}</span>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <div class="col-sm-12 text-left">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman</span>
                                    <span v-else>Pages</span>
                                </label>&nbsp;
                                <select v-model="halaman" @change="onChangeHalaman($event)">
                                    <option v-for="index in jumlahhalaman" :key="index">
                                        {{ index }}</option>
                                </select>
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldetailinvestasi">
                                    <thead>
                                        <tr>
                                            <th rowspan="2" style="width: 50px;">No</th>
                                            <th colspan="9">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Identitas
                                                    Proyek</span>
                                                <span v-else>Project Identity</span>
                                            </th>
                                            <th colspan="6">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Lokasi</span>
                                                <span v-else>Location</span>
                                            </th>
                                            <th colspan="3">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">KBLI</span>
                                                <span v-else>KBLI</span>
                                            </th>
                                            <th colspan="6">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Pengguna</span>
                                                <span v-else>User</span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th style="width: 120px;">Id</th>
                                            <th style="width: 80px;">NIB</th>
                                            <th style="width: 80px;">NPWP</th>
                                            <th style="width: 180px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Perusahaan</span>
                                                <span v-else>Company</span>
                                            </th>
                                            <th style="width: 80px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Status<br>Penanaman<br>Modal</span>
                                                <span v-else>Investment<br>Status</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Jenis<br>Perusahaan</span>
                                                <span v-else>Type of company</span>
                                            </th>
                                            <th style="width: 80px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Resiko<br>Proyek</span>
                                                <span v-else>Project<br>Risks</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Proyek</span>
                                                <span v-else>Project</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Skala<br>Usaha</span>
                                                <span v-else>Scale<br>Enterprises</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Alamat<br>Usaha</span>
                                                <span v-else>Business<br>Address</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Kecamatan</span>
                                                <span v-else>District</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Kelurahan</span>
                                                <span v-else>Sub<br>District</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Latitude</span>
                                                <span v-else>Latitude</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Longitude</span>
                                                <span v-else>Longitude</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Luas Tanah
                                                    (m<sup>2</sup>)
                                                </span>
                                                <span v-else>Surface area (m<sup>2</sup>)</span>
                                            </th>
                                            <th style="width: 80px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Kode KBLI</span>
                                                <span v-else>KBLI Code</span>
                                            </th>
                                            <th style="width: 140px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">KBLI</span>
                                                <span v-else>KBLI</span>
                                            </th>
                                            <th style="width: 120px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sektor Pembina
                                                </span>
                                                <span v-else>Development Sector</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Nama</span>
                                                <span v-else>Name</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">NIK</span>
                                                <span v-else>No ID</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Email</span>
                                                <span v-else>Email</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Telp.</span>
                                                <span v-else>Phone</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah<br>Investasi</span>
                                                <span v-else>Investment<br>Amount</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Tenaga<br>Kerja</span>
                                                <span v-else>Labor</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalistdetail, urutlistdetail) in datadetail" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="komponenakhir">
                                                    {{ (halaman * 100) + urutlistdetail + 1 - 100 }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.id_proyek }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.nib }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.npwp_perusahaan }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.nama_perusahaan }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.uraian_status_penanaman_modal }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.uraian_jenis_perusahaan }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.uraian_risiko_proyek }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.nama_proyek }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.uraian_skala_usaha }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.alamat_usaha }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.kecamatan_usaha }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.kelurahan_usaha }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.latitude }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.longitude }}
                                                </td>
                                                <td class="komponenakhir text-center">
                                                    {{ datalistdetail.luas_tanah }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.kbli }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.judul_kbli }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.sektor_pembina }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.nama_user }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.nomor_identitas_user }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.email }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.nomor_telp }}
                                                </td>
                                                <td class="komponenakhir text-right">
                                                    {{ G_numFormatKoma(datalistdetail.jumlah_investasi3) }}
                                                </td>
                                                <td class="komponenakhir text-center">
                                                    {{ datalistdetail.tki }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                            <div class="col-sm-12 text-left">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman</span>
                                    <span v-else>Pages</span>
                                </label>&nbsp;
                                <select v-model="halaman" @change="onChangeHalaman($event)">
                                    <option v-for="index in jumlahhalaman" :key="index">
                                        {{ index }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    </p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-sm btn-danger3 text-white lebar2" data-dismiss="modal"> <i
                            class="far fa-times-circle"></i>
                        Tutup</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/dpmptsp_logo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">DETAIL INVESTASI</span>
                <span v-else>INVESTMENT DETAILS</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a :href="'/Investasi/' + tabaktif">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Investasi</span>
                        <span v-else>Investment</span>
                    </a>
                </li>
                <li class="breadcrumb-item">
                    <a :href="'/InvestasiDataResiko/' + secretencDatakembali">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Resiko Proyek</span>
                        <span v-else>Project Risk Investment Data</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Detail Investasi Resiko Proyek</span>
                    <span v-else>Project Risk Investment Details</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="container-fluid feature py-3">
        <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
            <div class="sub-style">
                <h4 class="sub-title px-3 mb-0">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Investasi Resiko Proyek {{ tulisResiko }}
                        Kecamatan {{ secretencData2 }}
                        Tahun {{ tulisantahun }}</span>
                    <span v-else>{{ tulisResiko }} Investment Data for District {{ secretencData2 }} of year {{
                        tulisantahun_eng }}</span>
                </h4>
            </div>
        </div>
    </div>
    <div class="container-fluid py-3 text-center wow fadeInUp" data-wow-delay="0.1s">
        <button type="button" class="btn btn-sm btn-danger3 text-white lebar2 wow fadeInUp" data-wow-delay="0.1s"
            @click="kembali()" data-toggle="tooltip" data-placement="left" title="Kembali.."><i
                class="fas fa-reply"></i>
            <span v-if="(bahasa === null) || (bahasa == 'ina')"> Kembali</span>
            <span v-else> Back</span>
        </button>
    </div>
    <div class="container-fluid feature py-4 wow fadeInUp" data-wow-delay="0.1s">
        <div class="row px-2">
            <div class="col-sm-3 wow fadeInUp" data-wow-delay="0.1s">
                <div class="card cardputih3">
                    <div class="cardputih3-header border-transparent">
                        <h6 class="float-left">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Ringkasan Resiko Proyek {{ tulisResiko
                                }}
                                Kecamatan {{ secretencData2
                                }} Tahun {{ tulisantahun }}</span>
                            <span v-else>Summary of Project Risk {{ tulisResiko }} {{ secretencData2 }} District Year of
                                {{
                                    tulisantahun_eng
                                }}</span>
                        </h6>
                        <div class="card-tools float-right">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="info-box bg-trans">
                                    <span class="info-box-icon bg-merah elevation-1">
                                        <i class="fas fa-chart-pie"></i>
                                    </span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Total
                                                Investasi</span>
                                            <span v-else>Total Investment</span>
                                        </span>
                                        <span class="info-box-number">
                                            {{ G_numFormatKoma(jumlah) }}
                                            <small>M</small>
                                        </span>
                                    </div>
                                    <!-- /.info-box-content -->
                                </div>
                                <!-- /.info-box -->
                            </div>
                            <div class="col-sm-12">
                                <div class="info-box bg-trans">
                                    <span class="info-box-icon bg-merah elevation-1">
                                        <i class="fas fa-charging-station"></i>
                                    </span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Investasi
                                                Tertinggi</span>
                                            <span v-else>Highest Investment</span>
                                        </span>
                                        <span class="info-box-number">
                                            {{ G_numFormatKoma(maximal) }}
                                            <small>M</small>
                                        </span>
                                    </div>
                                    <!-- /.info-box-content -->
                                </div>
                                <!-- /.info-box -->
                            </div>
                            <div class="col-sm-12">
                                <div class="info-box bg-trans">
                                    <span class="info-box-icon bg-merah elevation-1">
                                        <i class="fas fa-check-square"></i>
                                    </span>
                                    <div class="info-box-content">
                                        <span class="info-box-text">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah
                                                Data</span>
                                            <span v-else>Amount of data</span>
                                        </span>
                                        <span class="info-box-number">
                                            {{ G_numFormat(count) }}
                                            <small>record</small>
                                        </span>
                                    </div>
                                    <!-- /.info-box-content -->
                                </div>
                                <!-- /.info-box -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-9 wow fadeInUp" data-wow-delay="0.1s">
                <BarChartTotalInvestasiKelurahan height="450" :options="chartOptionsTotalInvestasiKelurahan"
                    :series="seriesTotalInvestasiKelurahan" @dataPointSelection="dataPointSelectionKelurahan">
                </BarChartTotalInvestasiKelurahan>
            </div>
            <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                <div class="col-lg-12 text-center">
                    <div class="card cardputih3">
                        <div class="cardputih3-header border-transparent">
                            <h6 class="float-left">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Investasi Resiko Proyek {{
                                    tulisResiko }} Per Kelurahan
                                    Kecamatan {{ secretencData2
                                    }} Tahun {{ tulisantahun }}</span>
                                <span v-else>Summary of Investment Project Risk {{ tulisResiko }} Data Per Sub District
                                    in
                                    District {{ secretencData2
                                    }} Year of {{ tulisantahun_eng }}</span>
                            </h6>
                            <div class="card-tools float-right">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabelinvestasi">
                                    <thead>
                                        <tr>
                                            <td class="levelmerah">No</td>
                                            <td class="levelmerah">Kelurahan<br><i>Subdistrict</i></td>
                                            <td class="levelmerah">Luas Lahan (m<sup>2</sup>)<br><i>Land Area
                                                    (m<sup>2</sup>)</i>
                                            </td>
                                            <td class="levelmerah">
                                                Nilai Investasi<br><i>Investment Value</i></td>
                                            <td class="levelmerah">
                                                Nilai Kumulatif Investasi (Rp.)<br><i>Investment Cumulative Value
                                                    (IDR)</i>
                                            </td>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in datamasterJumlahInvesKelurahan"
                                        :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="komponenakhir">
                                                    {{ urutlist + 1 }}
                                                </td>
                                                <td class="komponenakhir">
                                                    <a href="#" @click="dataSelectionKelurahan(datalist.Kelurahan)">{{
                                                        datalist.Kelurahan }}</a>
                                                </td>
                                                <td class="komponenakhir">
                                                    {{ G_numFormatKoma(datalist.LuasTanahm2) }}
                                                </td>
                                                <td class="komponenakhir">
                                                    {{ G_numFormatKoma(datalist.JumlahInves) }} M
                                                </td>
                                                <td class="komponenakhir">
                                                    {{ G_numFormatKoma(datalist.JumlahKumulatif) }} M
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer p-3">
                            <h6 style="font-size: x-small;">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                    RBA</span>
                                <span v-else>Source: OSS RBA</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid py-3 text-center wow fadeInUp" data-wow-delay="0.1s">
        <button type="button" class="btn btn-sm btn-danger3 text-white lebar2 wow fadeInUp" data-wow-delay="0.1s"
            @click="kembali()" data-toggle="tooltip" data-placement="left" title="Kembali.."><i
                class="fas fa-reply"></i>
            <span v-if="(bahasa === null) || (bahasa == 'ina')"> Kembali</span>
            <span v-else> Back</span>
        </button>
    </div>

    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import VueApexCharts from "vue3-apexcharts";
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal, BarChartTotalInvestasiKelurahan: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            datapesan: '',
            carikata: '',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            jumlah: 0,
            maximal: 0,
            minimal: 0,
            count: 0,
            datadetail: [],
            datamaster: [],
            datamasterJumlahInvesKelurahan: [],
            serieskelurahan: [],
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('risk|', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            secretencDataawal: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            secretencDatakembali: '',
            secretencDatatemp: '',
            secretencData: '',
            secretencData2: '',
            secretencData3: '',
            kecamatan: '',
            kelurahan: '',
            tulisResiko: '',
            tulisantahun: 'Semua Tahun',
            tulisantahun_eng: 'All Years',
            seriesTotalInvestasiKelurahan: [],
            chartOptionsTotalInvestasiKelurahan: {
                colors: [
                    '#6a6ba5',
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                chart: {
                    height: '350',
                    width: '90%',
                    type: 'line',
                    stacked: 'true',
                    zoom: {
                        enabled: true,
                        type: 'x'
                    }
                },
                tooltip: {
                    followCursor: true,
                    shared: false
                },
                markers: {
                    size: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0
                },
                stroke: {
                    width: 2
                },
                xaxis: {
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: true,
                    },
                    categories: [],
                    title: {
                        text: 'Kelurahan (Subdistrict)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(2).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " M";
                    },
                    offsetY: -5,
                    style: {
                        fontSize: '8px',
                        colors: ['#5c6165', '#ff7f00'],
                    }
                },
                legend: {
                    showForSingleSeries: true
                },
                fill: {
                    opacity: 1
                },
                title: {
                    text: 'Nilai Investasi Per Kelurahan',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Investment Value Per Sub District)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datadetail
                : this.datadetail.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            mainAPI.get("datakitaDetail-GetDetailInvestasi?tahun=" + this.secretencData + "&kecamatan=" + this.secretencData2 + "&kelurahan=" + this.kelurahan + "&resiko=" + this.secretencData3 + "&halaman=" + halamannya).then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.halamanloading = false;
            });
        },
        dataPointSelectionKelurahan(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            mainAPI.get("datakitaDetail-GetDetailInvestasi?tahun=" + this.secretencData + "&kecamatan=" + this.secretencData2 + "&kelurahan=" + this.serieskelurahan[dataPointIndex] + "&resiko=" + this.secretencData3).then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        this.kecamatan = Response.data.content.kecamatan;
                        this.kelurahan = Response.data.content.kelurahan;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.kecamatan = '';
                this.kelurahan = '';
                this.halamanloading = false;
            });

        },
        dataSelectionKelurahan(kelurahan) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            mainAPI.get("datakitaDetail-GetDetailInvestasi?tahun=" + this.secretencData + "&kecamatan=" + this.secretencData2 + "&kelurahan=" + kelurahan + "&resiko=" + this.secretencData3).then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        this.kecamatan = Response.data.content.kecamatan;
                        this.kelurahan = Response.data.content.kelurahan;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.kecamatan = '';
                this.kelurahan = '';
                this.halamanloading = false;
            });
        },
        kembali() {
            window.location.replace("/InvestasiDataResiko/" + this.secretencDatakembali);

        },
        jumpTo(komponenid) {
            const element = document.getElementById(komponenid);
            element.scrollIntoView();
        },
        async ambildata() {
            this.halamanloading = true;
            this.secretencDatatemp = this.secretencDataawal.split('|');
            this.secretencData = this.secretencDatatemp[0];
            this.secretencData2 = this.secretencDatatemp[1];
            this.secretencData3 = this.secretencDatatemp[2];
            //alert(this.secretencData3);
            this.secretencDatakembali = encodeURIComponent(CryptoJS.AES.encrypt(this.secretencData + '|' + this.secretencData3, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            if (this.secretencData != '') {
                this.tulisantahun = this.secretencData;
                this.tulisantahun_eng = this.secretencData;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            this.tulisResiko = this.secretencDatatemp[2];


            await mainAPI.get("datakitaResume-GetResumeInvestasi?tahun=" + this.secretencData + "&kecamatan=" + this.secretencData2 + "&resiko=" + this.secretencData3).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.jumlah = Response.data.content.data[0].jumlah;
                        this.maximal = Response.data.content.data[0].maximal;
                        this.minimal = Response.data.content.data[0].minimal;
                        this.count = Response.data.content.data[0].count;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.halamanloading = false;
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarTotalKelurahanInvestasi?tahun=" + this.secretencData + "&kecamatan=" + this.secretencData2 + "&resiko=" + this.secretencData3).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterJumlahInvesKelurahan = [];
                        this.serieskelurahan = Response.data.content.data[0].Kelurahan;
                        this.datamasterJumlahInvesKelurahan = Response.data.content.data[0].tabel;
                        this.chartOptionsTotalInvestasiKelurahan = {
                            xaxis: {
                                categories: Response.data.content.data[0].Kelurahan
                            },
                            yaxis: {
                                tickAmount: 10,
                                labels: {
                                    formatter: function (value) {
                                        return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " M";
                                    }

                                },
                            },
                        };
                        this.seriesTotalInvestasiKelurahan = [
                            {
                                "name": "Total Investasi (Total  Investment)",
                                "type": "line",
                                "data": Response.data.content.data[0].TotalInvestasi
                            },
                            {
                                "name": "Nilai Investasi (Investment Value)",
                                "type": "bar",
                                "data": Response.data.content.data[0].JumlahInvestasi
                            },
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsTotalInvestasiKelurahan = {
                    xaxis: {
                        categories: []
                    },
                };
                this.seriesTotalInvestasiKelurahan = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>