<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/dpmptsp_logo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">INVESTASI</span>
                <span v-else>INVESTMENT</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Investasi</span>
                    <span v-else>Investment</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="container-fluid feature bg-trans py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div class="cardputih4 card-outline card-outline-tabs bg-trans">
            <div class="cardputih3-header p-0 border-bottom-0">
                <div class="container-fluid feature py-3">
                    <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="sub-style">
                            <h2 class="sub-title px-3 mb-0">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Klasifikasi Data</span>
                                <span v-else>Data Classification</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                            href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                            aria-selected="true">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Wilayah Administrasi</span>
                            <span v-else>Administrative Region</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="kblidata()">
                        <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                            href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                KBLI</span>
                            <span v-else>KBLI</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="resikodata()">
                        <a class="nav-link" id="custom-tabs-four-risk-tab" data-toggle="pill"
                            href="#custom-tabs-four-risk" role="tab" aria-controls="custom-tabs-four-risk"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Resiko Proyek</span>
                            <span v-else>Project Risks</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="sektordata()">
                        <a class="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill"
                            href="#custom-tabs-four-messages" role="tab" aria-controls="custom-tabs-four-messages"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Sektor Investasi</span>
                            <span v-else>Investment Sector</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="statusdata()">
                        <a class="nav-link" id="custom-tabs-four-status-tab" data-toggle="pill"
                            href="#custom-tabs-four-status" role="tab" aria-controls="custom-tabs-four-status"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Status Penanaman Modal</span>
                            <span v-else>Investment Status</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="perusahaandata()">
                        <a class="nav-link" id="custom-tabs-four-settings-tab" data-toggle="pill"
                            href="#custom-tabs-four-settings" role="tab" aria-controls="custom-tabs-four-settings"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Jenis Perusahaan</span>
                            <span v-else>Type Company</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="sektornswiperizinan()">
                        <a class="nav-link" id="custom-tabs-four-perusahaan-tab" data-toggle="pill"
                            href="#custom-tabs-four-perusahaan" role="tab" aria-controls="custom-tabs-four-perusahaan"
                            aria-selected="true">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Realisasi Perusahaan</span>
                            <span v-else>Company Realization</span>
                        </a>
                    </li>

                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="custom-tabs-four-tabContent">
                    <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                        aria-labelledby="custom-tabs-four-home-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Profil investasi ini merupakan rekapitulasi nilai investasi yang ada di Kota Semarang,
                                data
                                seri
                                tahunan dan Kecamatan ditampilkan pada menu ini. Untuk melihat detail data dari profil
                                investasi silahkan menekan data grafik yang ada atau memilih data pada tabel yang
                                tersedia.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                This investment profile is a recapitulation of the investment value in Semarang City,
                                annual
                                series and sub-district data are displayed in this menu. To view detailed data from the
                                investment profile, please press the existing graphic data or select data in the table
                                provided.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Nilai Investasi</span>
                                        <span v-else>Investment Value</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-6">
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Ringkasan</span>
                                                <span v-else>Summary</span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="info-box bg-trans">
                                                        <span class="info-box-icon bg-merah elevation-1">
                                                            <i class="fas fa-chart-pie"></i>
                                                        </span>
                                                        <div class="info-box-content">
                                                            <span class="info-box-text">
                                                                <span
                                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Total
                                                                    Investasi</span>
                                                                <span v-else>Total Investment</span>
                                                            </span>
                                                            <span class="info-box-number">
                                                                {{ G_numFormatKoma(jumlah) }}
                                                                <small>M</small>
                                                            </span>
                                                        </div>
                                                        <!-- /.info-box-content -->
                                                    </div>
                                                    <!-- /.info-box -->
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="info-box bg-trans">
                                                        <span class="info-box-icon bg-merah elevation-1">
                                                            <i class="fas fa-chart-line"></i>
                                                        </span>
                                                        <div class="info-box-content">
                                                            <span class="info-box-text">
                                                                <span
                                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Pertumbuhan
                                                                    Investasi</span>
                                                                <span v-else>Investment Growth</span>
                                                            </span>
                                                            <span class="info-box-number">
                                                                {{ G_numFormatKoma(pertumbuhan) }}
                                                                <small>%</small>
                                                            </span>
                                                        </div>
                                                        <!-- /.info-box-content -->
                                                    </div>
                                                    <!-- /.info-box -->
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="info-box bg-trans">
                                                        <span class="info-box-icon bg-merah elevation-1">
                                                            <i class="fas fa-charging-station"></i>
                                                        </span>
                                                        <div class="info-box-content">
                                                            <span class="info-box-text">
                                                                <span
                                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Investasi
                                                                    Tertinggi</span>
                                                                <span v-else>Highest Investment</span>
                                                            </span>
                                                            <span class="info-box-number">
                                                                {{ G_numFormatKoma(maximal) }}
                                                                <small>M</small>
                                                            </span>
                                                        </div>
                                                        <!-- /.info-box-content -->
                                                    </div>
                                                    <!-- /.info-box -->
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="info-box bg-trans">
                                                        <span class="info-box-icon bg-merah elevation-1">
                                                            <i class="fas fa-check-square"></i>
                                                        </span>
                                                        <div class="info-box-content">
                                                            <span class="info-box-text">
                                                                <span
                                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah
                                                                    Data</span>
                                                                <span v-else>Amount of data</span>
                                                            </span>
                                                            <span class="info-box-number">
                                                                {{ G_numFormat(count) }}
                                                                <small>record</small>
                                                            </span>
                                                        </div>
                                                        <!-- /.info-box-content -->
                                                    </div>
                                                    <!-- /.info-box -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <BarChartTotalInvestasi height="450" :options="chartOptionsTotalInvestasi"
                                        :series="seriesTotalInvestasi" @dataPointSelection="dataPointSelectionTahun">
                                    </BarChartTotalInvestasi>
                                </div>
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Data
                                                    Investasi</span>
                                                <span v-else>Investment Data</span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabelinvestasi">
                                                    <thead>
                                                        <tr>
                                                            <td class="levelmerah">No</td>
                                                            <td class="levelmerah">Tahun<br>(Years)</td>
                                                            <td class="levelmerah">
                                                                Nilai<br>Investasi<br>(Investment<br>Value)</td>
                                                            <td class="levelmerah">
                                                                Nilai<br>Kumulatif<br>Investasi<br>(Investment<br>Cumulative<br>Value)
                                                            </td>
                                                            <td class="levelmerah">
                                                                Pertumbuhan<br>Investasi<br>(Investment<br>Growth)</td>
                                                        </tr>
                                                    </thead>
                                                    <template
                                                        v-for="(datalist, urutlist) in datamasterJumlahInvesTahunan"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="komponenakhir">
                                                                    {{ urutlist + 1 }}
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    <a href="#"
                                                                        @click="dataSelectionTahun(datalist.Tahun)">{{
                                                                            datalist.Tahun }}</a>
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormatKoma(datalist.JumlahInves) }} M
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormatKoma(datalist.JumlahKumulatif) }} M
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormatKoma(datalist.PertumbuhanInvestasi) }}
                                                                    %
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <h6 style="font-size: x-small;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                    RBA</span>
                                                <span v-else>Source: OSS RBA</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <BarChartTotalInvestasiKecamatan height="450"
                                        :options="chartOptionsTotalInvestasiKecamatan"
                                        :series="seriesTotalInvestasiKecamatan"
                                        @dataPointSelection="dataPointSelectionKecamatan">
                                    </BarChartTotalInvestasiKecamatan>
                                </div>
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="col-lg-12 text-center">
                                        <div class="card cardputih3">
                                            <div class="cardputih3-header border-transparent">
                                                <h6 class="float-left">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Investasi
                                                        Per Kecamatan</span>
                                                    <span v-else>Investment Data Per District</span>
                                                </h6>
                                                <div class="card-tools float-right">
                                                    <button type="button" class="btn btn-tool"
                                                        data-card-widget="collapse">
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body p-0">
                                                <div class="table-responsive table-wrapper">
                                                    <table class="table m-0" id="tabelinvestasi">
                                                        <thead>
                                                            <tr>
                                                                <td class="levelmerah">No</td>
                                                                <td class="levelmerah">Kecamatan<br>(Subdistrict)</td>
                                                                <td class="levelmerah">
                                                                    Nilai<br>Investasi<br>(Investment<br>Value)</td>
                                                                <td class="levelmerah">
                                                                    Nilai<br>Kumulatif<br>Investasi<br>(Investment<br>Cumulative<br>Value)
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <template
                                                            v-for="(datalist, urutlist) in datamasterJumlahInvesKecamatan"
                                                            :key="urutlist">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="komponenakhir">
                                                                        {{ urutlist + 1 }}
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        <a href="#"
                                                                            @click="dataSelectionKecamatan(datalist.Kecamatan)">{{
                                                                                datalist.Kecamatan }}</a>
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        {{ G_numFormatKoma(datalist.JumlahInves) }} M
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        {{ G_numFormatKoma(datalist.JumlahKumulatif) }}
                                                                        M
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="card-footer p-3">
                                                <h6 style="font-size: x-small;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                        RBA</span>
                                                    <span v-else>Source: OSS RBA</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                        aria-labelledby="custom-tabs-four-profile-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                KBLI adalah Klasifikasi Baku Lapangan Usaha Indonesia, pengklasifikasian
                                aktivitas/kegiatan
                                ekonomi Indonesia yang
                                menghasilkan produk/output, baik berupa barang maupun jasa, berdasarkan lapangan usaha
                                untuk
                                memberikan keseragaman
                                konsep, definisi, dan klasifikasi lapangan usaha dalam perkembangan dan pergeseran
                                kegiatan
                                ekonomi di Indonesia.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                KBLI is the Standard Classification of Indonesian Business Fields, a classification of
                                Indonesian economic
                                activities/activities that produce products/output, both in the form of goods and
                                services,
                                based on business fields
                                to provide uniform concepts, definitions and classifications of business fields in the
                                development and shifts in
                                economic activities in Indonesia.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Nilai Investasi KBLI Tahun
                                            {{ tahunKBLI
                                            }}</span>
                                        <span v-else>KBLI Investment Value Year Of {{ tahunKBLI }}</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahun" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahun" @update:modelValue="ontahunChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-lg-12">&nbsp;</div>
                            <div class="col-lg-12">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <h6 class="float-left">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Ringkasan</span>
                                            <span v-else>Summary</span>
                                        </h6>
                                        <div class="card-tools float-right">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-up"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')"> Nilai
                                                                KBLI
                                                                Tertinggi</span>
                                                            <span v-else>Highest KBLI Value</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datainvestmaxkbli }} - {{ datainvestmaxnama }}
                                                                <span class="text-danger">{{
                                                                    G_numFormatKoma(datainvestmaxnilai) }} M</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-up"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah
                                                                KBLI
                                                                Tertinggi</span>
                                                            <span v-else>Highest KBLI Amount</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datajumlahmaxkbli }} - {{ datajumlahmaxnama }}
                                                                <span class="text-danger">{{
                                                                    G_numFormat(datajumlahmaxnilai) }} Data</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-down"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Nilai
                                                                KBLI
                                                                Terendah</span>
                                                            <span v-else>Lowest KBLI Value</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datainvestminkbli }} - {{ datainvestminnama }}
                                                                <span class="text-danger">{{
                                                                    G_numFormatKoma(datainvestminnilai) }} M</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-down"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah
                                                                KBLI
                                                                Terendah</span>
                                                            <span v-else>Lowest KBLI Amount</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datajumlahminkbli }} - {{ datajumlahminnama }}
                                                                <span class="text-danger">{{
                                                                    G_numFormat(datajumlahminnilai) }} Data</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 text-center">
                                <BarChartTotalKBLI :options="chartOptionsKBLI" :series="seriesKBLI"
                                    @dataPointSelection="dataPointSelectionKBLI" height="950">
                                </BarChartTotalKBLI>
                            </div>
                            <div class="col-lg-12 text-center">
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Investasi
                                                    Berdasarkan KBLI</span>
                                                <span v-else>Investment Amount Based on KBLI</span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabelinvestasi">
                                                    <thead>
                                                        <tr>
                                                            <td class="levelmerah">No</td>
                                                            <td class="levelmerah">KBLI</td>
                                                            <td class="levelmerah">
                                                                Judul<br>(Title)</td>
                                                            <td class="levelmerah">
                                                                Nilai Investasi (Rp.)<br>(Investment Value - IDR)
                                                            </td>
                                                            <td class="levelmerah">
                                                                Jumlah<br>(Amount)
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in datamasterkbli"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="komponenakhir">
                                                                    {{ urutlist + 1 }}
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    <a href="#"
                                                                        @click="dataSelectionKBLI(datalist.kbli)">{{
                                                                            datalist.kbli }}</a>
                                                                </td>
                                                                <td class="komponenakhir text-justify">
                                                                    <a href="#"
                                                                        @click="dataSelectionKBLI(datalist.kbli)">{{
                                                                            datalist.nama }}</a>
                                                                </td>
                                                                <td class="komponenakhir text-right">
                                                                    {{ G_numFormat(datalist.nilai) }}
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormat(datalist.jumlah) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <h6 style="font-size: x-small;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                    RBA</span>
                                                <span v-else>Source: OSS RBA</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-risk" role="tabpanel"
                        aria-labelledby="custom-tabs-four-risk-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Investasi adalah aktivitas menempatkan dana dengan harapan mendapatkan keuntungan di
                                masa
                                depan. Namun, setiap
                                bentuk investasi juga memiliki risiko investasi yang harus dipahami dan dikelola dengan
                                baik
                                oleh para investor.
                                Risiko investasi mengacu pada kemungkinan terjadinya kerugian atau ketidakpastian dalam
                                hasil investasi. Menurut
                                Otoritas Jasa Keuangan, setiap investor memiliki sikap toleransi terhadap risiko
                                investasi
                                yang berbeda-beda.
                                Risiko investasi ini tidak dapat dihindari sepenuhnya. Namun, dengan pemahaman yang baik
                                tentang berbagai jenis
                                risiko investasi tersebut, investor dapat mengelola risiko dan meningkatkan peluang
                                keberhasilan jangka panjang
                                investasi.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                Investment is the activity of placing funds with the hope of getting a profit in the
                                future
                                front. However,
                                every This form of investment also has investment risks that must be understood and
                                managed
                                well by investors.
                                Investment risk refers to the possibility of loss or uncertainty in investment
                                investment
                                results. According to
                                Financial Services Authority, every investor has a tolerant attitude towards investment
                                risk
                                different ones.
                                This investment risk cannot be completely avoided. However, with good understanding
                                about
                                various types
                                investment risk, investors can manage risks and increase opportunities long term success
                                investment.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">RESIKO INVESTASI TAHUN {{
                                            tahunresiko }}</span>
                                        <span v-else>INVESTMENT RISKS YEAR OF {{ tahunresiko }}</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunresiko" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunresiko" @update:modelValue="ontahunresikoChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-lg-12">&nbsp;</div>
                            <div class="col-lg-12 text-center">
                                <DonutsChartResiko type="donut" :options="chartOptionsresiko" :series="seriesresiko"
                                    height="350" @dataPointSelection="dataPointSelectionresiko">
                                </DonutsChartResiko>
                            </div>
                            <div class="col-lg-12 text-center">
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Investasi
                                                    Berdasarkan Resiko Proyek Tahun {{ tahunresiko }}</span>
                                                <span v-else>Investment Amount Based on Project Risk Year Of {{
                                                    tahunresiko }}</span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabelinvestasi">
                                                    <thead>
                                                        <tr>
                                                            <td class="levelmerah">No</td>
                                                            <td class="levelmerah">Resiko Proyek<br>(Project Risk)</td>
                                                            <td class="levelmerah">
                                                                Nilai Investasi (Rp.)<br>(Investment Value - IDR)
                                                            </td>
                                                            <td class="levelmerah">
                                                                Jumlah<br>(Amount)
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in datamasterresiko"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="komponenakhir">
                                                                    {{ urutlist + 1 }}
                                                                </td>
                                                                <td class="komponenakhir text-justify">
                                                                    <a href="#"
                                                                        @click="dataSelectionResiko(datalist.resiko)">{{
                                                                            datalist.resiko }}</a>
                                                                </td>
                                                                <td class="komponenakhir text-right">
                                                                    {{ G_numFormat(datalist.nilai) }}
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormat(datalist.jumlah) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <h6 style="font-size: x-small;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                    RBA</span>
                                                <span v-else>Source: OSS RBA</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-messages" role="tabpanel"
                        aria-labelledby="custom-tabs-four-messages-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Sejumlah sektor prioritas Kota Semarang dapat menjadi peluang investasi bagi para
                                investor
                                yang hendak melakukan
                                investasi di Kota Semarang.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                A number of priority sectors in Semarang City can become investment opportunities for
                                investors who want to
                                invest in Semarang City.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">SEKTOR INVESTASI TAHUN {{
                                            tahunsektor }}</span>
                                        <span v-else>INVESTMENT SECTOR YEAR OF {{ tahunsektor }}</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunsektor" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunsektor" @update:modelValue="ontahunsektorChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-lg-12">&nbsp;</div>
                            <div class="col-lg-12">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <h6 class="float-left">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Ringkasan</span>
                                            <span v-else>Summary</span>
                                        </h6>
                                        <div class="card-tools float-right">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-up"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')"> Nilai
                                                                Sektor
                                                                Tertinggi</span>
                                                            <span v-else>Highest Sector Value</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datainvestmaxnamasektor }}
                                                                <span class="text-danger">{{
                                                                    G_numFormatKoma(datainvestmaxnilaisektor) }}
                                                                    M</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-up"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah
                                                                Sektor
                                                                Tertinggi</span>
                                                            <span v-else>Highest Sector Amount</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datajumlahmaxnamasektor }}
                                                                <span class="text-danger">{{
                                                                    G_numFormat(datajumlahmaxnilaisektor) }} Data</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-down"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Nilai
                                                                Sektor
                                                                Terendah</span>
                                                            <span v-else>Lowest Sector Value</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datainvestminnamasektor }}
                                                                <span class="text-danger">{{
                                                                    G_numFormatKoma(datainvestminnilaisektor) }}
                                                                    M</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-down"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah
                                                                Sektor
                                                                Terendah</span>
                                                            <span v-else>Lowest Sector Amount</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datajumlahminnamasektor }}
                                                                <span class="text-danger">{{
                                                                    G_numFormat(datajumlahminnilaisektor) }} Data</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 text-center">
                                <BarChartTotalSektor :options="chartOptionsSektor" :series="seriesSektor"
                                    @dataPointSelection="dataPointSelectionSektor" height="400">
                                </BarChartTotalSektor>
                            </div>
                            <div class="col-lg-12 text-center">
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Investasi
                                                    Berdasarkan Sektor</span>
                                                <span v-else>Investment Amount Based on Sector</span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabelinvestasi">
                                                    <thead>
                                                        <tr>
                                                            <td class="levelmerah">No</td>
                                                            <td class="levelmerah">
                                                                Sektor<br>(Sector)</td>
                                                            <td class="levelmerah">
                                                                Nilai Investasi (Rp.)<br>(Investment Value - IDR)
                                                            </td>
                                                            <td class="levelmerah">
                                                                Jumlah<br>(Amount)
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in datamastersektor"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="komponenakhir">
                                                                    {{ urutlist + 1 }}
                                                                </td>
                                                                <td class="komponenakhir text-justify">
                                                                    <a href="#"
                                                                        @click="dataSelectionSektor(datalist.sektor)">{{
                                                                            datalist.sektor }}</a>
                                                                </td>
                                                                <td class="komponenakhir text-right">
                                                                    {{ G_numFormat(datalist.nilai) }}
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormat(datalist.jumlah) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <h6 style="font-size: x-small;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                    RBA</span>
                                                <span v-else>Source: OSS RBA</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-status" role="tabpanel"
                        aria-labelledby="custom-tabs-four-status-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Berdasarkan Undang-Undang Nomor 25 Tahun 2007 tentang Penanaman Modal yang dimaksud
                                dengan penanaman modal
                                adalah segala bentuk kegiatan menanam modal, baik oleh penanam modal dalam negeri maupun
                                penanam modal asing
                                untuk melakukan usaha di wilayah negara Republik Indonesia. Penanaman Modal Dalam Negeri
                                (PMDN) adalah
                                kegiatan menanam modal untuk melakukan usaha di wilayah negara Republik Indonesia yang
                                dilakukan oleh
                                penanam modal dalam negeri dengan menggunakan modal dalam negeri. Penanaman Modal Asing
                                (PMA) adalah
                                kegiatan menanam modal untuk melakukan usaha di wilayah negara Republik Indonesia yang
                                dilakukan oleh
                                penanam modal asing, baik yang menggunakan modal asing sepenuhnya maupun yang
                                berpatungan dengan penanam
                                modal dalam negeri.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                Based on Law Number 25 of 2007 concerning Investment, what is meant by investment is all
                                forms of investment
                                activities, both by domestic investors and foreign investors to conduct business in the
                                territory of the
                                Republic of Indonesia. Domestic Investment (PMDN) is an investment activity to conduct
                                business in the
                                territory of the Republic of Indonesia carried out by domestic investors using domestic
                                capital. Foreign
                                Investment (PMA) is the activity of investing capital to conduct business in the
                                territory of the Republic
                                of Indonesia carried out by foreign investors, either using foreign capital entirely or
                                jointly with
                                domestic investors.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">STATUS PENANAMAN MODAL TAHUN
                                            {{
                                                tahunstatus }}</span>
                                        <span v-else>CAPITAL INVESTMENT STATUS YEAR OF {{ tahunstatus }}</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunstatus" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunstatus" @update:modelValue="ontahunstatusChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-lg-12">&nbsp;</div>
                            <div class="col-lg-12 text-center">
                                <DonutsChartResiko type="donut" :options="chartOptionsstatus" :series="seriesstatus"
                                    height="350" @dataPointSelection="dataPointSelectionstatus">
                                </DonutsChartResiko>
                            </div>
                            <div class="col-lg-12 text-center">
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Investasi
                                                    Berdasarkan Status Penanaman Modal Tahun {{ tahunstatus }}</span>
                                                <span v-else>Investment Amount Based on Investment Status Year Of {{
                                                    tahunstatus }}</span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabelinvestasi">
                                                    <thead>
                                                        <tr>
                                                            <td class="levelmerah">No</td>
                                                            <td class="levelmerah">Status Penanaman Modal<br>(Investment
                                                                Status)</td>
                                                            <td class="levelmerah">
                                                                Nilai Investasi (Rp.)<br>(Investment Value - IDR)
                                                            </td>
                                                            <td class="levelmerah">
                                                                Jumlah<br>(Amount)
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in datamasterstatus"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="komponenakhir">
                                                                    {{ urutlist + 1 }}
                                                                </td>
                                                                <td class="komponenakhir text-justify">
                                                                    <a href="#"
                                                                        @click="dataSelectionStatus(datalist.statusmodal)">{{
                                                                            datalist.statusmodal }}</a>
                                                                </td>
                                                                <td class="komponenakhir text-right">
                                                                    {{ G_numFormat(datalist.nilai) }}
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormat(datalist.jumlah) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <h6 style="font-size: x-small;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                    RBA</span>
                                                <span v-else>Source: OSS RBA</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-settings" role="tabpanel"
                        aria-labelledby="custom-tabs-four-settings-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Perusahaan investasi adalah korporasi atau perwalian yang bergerak dalam bisnis
                                menginvestasikan modal
                                gabungan investor dalam sekuritas keuangan. Hal ini paling sering dilakukan melalui dana
                                tertutup atau dana
                                terbuka (juga disebut reksa dana).
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                An investment company is a corporation or trust that is in the business of investing the
                                combined capital of
                                investors in financial securities. This is most often done through closed-end funds or
                                open-ended funds
                                (also called mutual funds).
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">JENIS PERUSAHAAN INVESTASI
                                            TAHUN
                                            {{
                                                tahunstatus }}</span>
                                        <span v-else>TYPES OF INVESTMENT COMPANIES YEAR OF {{ tahunstatus }}</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-lg-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunperusahaan" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunperusahaan"
                                    @update:modelValue="ontahunperusahaanChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-lg-12">&nbsp;</div>
                            <div class="col-lg-12">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <h6 class="float-left">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Ringkasan</span>
                                            <span v-else>Summary</span>
                                        </h6>
                                        <div class="card-tools float-right">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-up"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')"> Nilai
                                                                Perusahaan
                                                                Tertinggi</span>
                                                            <span v-else>Highest Company Value</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datainvestmaxnamaperusahaan }}
                                                                <span class="text-danger">{{
                                                                    G_numFormatKoma(datainvestmaxnilaiperusahaan) }}
                                                                    M</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-up"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah
                                                                Perusahaan
                                                                Tertinggi</span>
                                                            <span v-else>Highest Company Amount</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datajumlahmaxnamaperusahaan }}
                                                                <span class="text-danger">{{
                                                                    G_numFormat(datajumlahmaxnilaiperusahaan) }}
                                                                    Data</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-down"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Nilai
                                                                Perusahaan
                                                                Terendah</span>
                                                            <span v-else>Lowest Company Value</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datainvestminnamaperusahaan }}
                                                                <span class="text-danger">{{
                                                                    G_numFormatKoma(datainvestminnilaiperusahaan) }}
                                                                    M</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="info-box bg-trans">
                                                    <span class="info-box-icon bg-merah elevation-1">
                                                        <i class="fas fa-chevron-circle-down"></i>
                                                    </span>
                                                    <div class="info-box-content">
                                                        <span class="info-box-text">
                                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah
                                                                Perusahaan
                                                                Terendah</span>
                                                            <span v-else>Lowest Company Amount</span>
                                                        </span>
                                                        <span class="info-box-number">
                                                            <small>
                                                                {{ datajumlahminnamaperusahaan }}
                                                                <span class="text-danger">{{
                                                                    G_numFormat(datajumlahminnilaiperusahaan) }}
                                                                    Data</span>
                                                            </small>
                                                        </span>
                                                    </div>
                                                    <!-- /.info-box-content -->
                                                </div>
                                                <!-- /.info-box -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 text-center">
                                <BarChartTotalPerusahaan :options="chartOptionsPerusahaan" :series="seriesPerusahaan"
                                    @dataPointSelection="dataPointSelectionPerusahaan" height="400">
                                </BarChartTotalPerusahaan>
                            </div>
                            <div class="col-lg-12 text-center">
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Jumlah Investasi
                                                    Berdasarkan Perusahaan</span>
                                                <span v-else>Investment Amount Based on Company</span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabelinvestasi">
                                                    <thead>
                                                        <tr>
                                                            <td class="levelmerah">No</td>
                                                            <td class="levelmerah">
                                                                Perusahaan<br>(Company)</td>
                                                            <td class="levelmerah">
                                                                Nilai Investasi (Rp.)<br>(Investment Value - IDR)
                                                            </td>
                                                            <td class="levelmerah">
                                                                Jumlah<br>(Amount)
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in datamasterperusahaan"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="komponenakhir">
                                                                    {{ urutlist + 1 }}
                                                                </td>
                                                                <td class="komponenakhir text-justify">
                                                                    <a href="#"
                                                                        @click="dataSelectionPerusahaan(datalist.perusahaan)">{{
                                                                            datalist.perusahaan }}</a>
                                                                </td>
                                                                <td class="komponenakhir text-right">
                                                                    {{ G_numFormat(datalist.nilai) }}
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormat(datalist.jumlah) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <h6 style="font-size: x-small;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                    RBA</span>
                                                <span v-else>Source: OSS RBA</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-perusahaan" role="tabpanel"
                        aria-labelledby="custom-tabs-four-perusahaan-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Mencakup kegiatan penanaman modal yang dilakukan perusahaan di setiap lokasi dan
                                bidang usaha investasi, kecuali bidang usaha perdagangan.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                Includes investment activities carried out by the company in every location and
                                investment business sector, except the trading business sector.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Perusahaan</span>
                                        <span v-else>Company Data</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunsektornswi" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunsektornswi"
                                    @update:modelValue="ontahunsektornswiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-12 wow fadeInUp" data-wow-delay="0.1s">
                                <BarChartSektorJmlInvesPerusahaan height="450"
                                    :options="chartOptionsSektorJmlInvesPerusahaan"
                                    :series="seriesSektorJmlInvesPerusahaan">
                                </BarChartSektorJmlInvesPerusahaan>
                            </div>
                            <div class="col-sm-12 wow fadeInUp" data-wow-delay="0.1s">
                                <BarChartSektorJmlPerusahaan height="450" :options="chartOptionsSektorJmlPerusahaan"
                                    :series="seriesSektorJmlPerusahaan">
                                </BarChartSektorJmlPerusahaan>
                            </div>
                            <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <h6 class="float-left">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Data
                                                Realisasi Perusahaan</span>
                                            <span v-else>Company Realization Data </span>
                                        </h6>
                                        <div class="card-tools float-right">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="table-responsive table-wrapper">
                                            <table class="table m-0" id="tabelinvestasi">
                                                <thead>
                                                    <tr>
                                                        <td class="levelmerah">No</td>
                                                        <td class="levelmerah">Sektor<br>(Sector)
                                                        </td>
                                                        <td class="levelmerah">Jumlah Investasi<br>(Investment
                                                            Amount)</td>
                                                        <td class="levelmerah">
                                                            Jumlah Perusahaan<br>(Number of Companies)
                                                        </td>
                                                        <td class="levelmerah">
                                                            Jumlah Tenaga Kerja<br>(Total Manpower)
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <template v-for="(datalist, urutlist) in sektornswi" :key="urutlist">
                                                    <tbody>
                                                        <tr>
                                                            <td class="komponenakhir">
                                                                {{ urutlist + 1 }}
                                                            </td>
                                                            <td class="komponenakhir text-justify">
                                                                {{ datalist.Nama_Sektor }}
                                                            </td>
                                                            <td class="komponenakhir text-right">
                                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Rp.
                                                                    {{
                                                                        G_numFormatKoma(datalist.Nilai_Investasi_Rupiah)
                                                                    }} M</span>
                                                                <span v-else>{{
                                                                    G_numFormatKoma(datalist.Nilai_Investasi_Dollar)
                                                                    }} Billion</span>
                                                            </td>
                                                            <td class="komponenakhir text-right">
                                                                {{ G_numFormat(datalist.JumlahPerusahaan) }}
                                                            </td>
                                                            <td class="komponenakhir text-right">
                                                                {{ G_numFormat(datalist.TKI) }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card-footer p-3">
                                        <h6 style="font-size: x-small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: NSWI</span>
                                            <span v-else>Source: NSWI</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card -->
        </div>
    </div>


    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Top, Footer, swal, vSelect, BarChartTotalInvestasi: VueApexCharts
        , BarChartTotalInvestasiKecamatan: VueApexCharts
        , BarChartTotalKBLI: VueApexCharts, DonutsChartResiko: VueApexCharts
        , BarChartTotalSektor: VueApexCharts, BarChartTotalPerusahaan: VueApexCharts
        , BarChartSektorJmlInvesPerusahaan: VueApexCharts
        , BarChartSektorJmlPerusahaan: VueApexCharts, DonutsChartStatusIUMK: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            tabaktif: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            datapesan: '',
            jumlah: 0,
            maximal: 0,
            minimal: 0,
            count: 0,
            pertumbuhan: 0,
            datamastertahun: [],
            datatahun: [],
            defaultSelectedtahun: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahun: '',
            tahunKBLI: '',

            datamastertahunresiko: [],
            datatahunresiko: [],
            defaultSelectedtahunresiko: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunresiko: '',
            tahunresiko: '',

            datamastertahunsektor: [],
            datatahunsektor: [],
            defaultSelectedtahunsektor: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunsektor: '',
            tahunsektor: '',

            datamastertahunperusahaan: [],
            datatahunperusahaan: [],
            defaultSelectedtahunperusahaan: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunperusahaan: '',
            tahunperusahaan: '',

            datamastertahunstatus: [],
            datatahunstatus: [],
            defaultSelectedtahunstatus: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunstatus: '',
            tahunstatus: '',

            datamaster: [],
            datamasterJumlahInvesTahunan: [],
            datamasterJumlahInvesKecamatan: [],
            secretencDatatemp: '',
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            databeranda: [],
            folder_foto: process.env.VUE_APP_URL_API + 'datakita_beranda-Gambar?f=',
            seriestahun: [],
            serieskecamatan: [],
            seriesTotalInvestasi: [],
            chartOptionsTotalInvestasi: {
                colors: [
                    '#6a6ba5',
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                chart: {
                    height: '350',
                    width: '90%',
                    type: 'line',
                    stacked: 'true',
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                tooltip: {
                    followCursor: true,
                    shared: false
                },
                markers: {
                    size: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0
                },
                stroke: {
                    width: 2
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Tahun (Years)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(2).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " M";
                    },
                    offsetY: -5,
                    style: {
                        fontSize: '8px',
                        colors: ['#5c6165', '#ff7f00'],
                    }
                },
                legend: {
                    showForSingleSeries: true
                },
                fill: {
                    opacity: 1
                },
                title: {
                    text: 'Nilai Investasi Tahunan',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Annual Investment Value)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
            seriesTotalInvestasiKecamatan: [],
            chartOptionsTotalInvestasiKecamatan: {
                colors: [
                    '#6a6ba5',
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                chart: {
                    height: '350',
                    width: '90%',
                    type: 'line',
                    stacked: 'true',
                    zoom: {
                        enabled: true,
                        type: 'x'
                    }
                },
                tooltip: {
                    followCursor: true,
                    shared: false
                },
                markers: {
                    size: 0,
                    fillOpacity: 0,
                    strokeOpacity: 0
                },
                stroke: {
                    width: 2
                },
                xaxis: {
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: true,
                    },
                    categories: [],
                    title: {
                        text: 'Kecamatan (Subdistrict)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(2).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " M";
                    },
                    offsetY: -5,
                    style: {
                        fontSize: '8px',
                        colors: ['#5c6165', '#ff7f00'],
                    }
                },
                legend: {
                    showForSingleSeries: true
                },
                fill: {
                    opacity: 1
                },
                title: {
                    text: 'Nilai Investasi Per Kecamatan',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Investment Value Per District)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            seriesKBLI: [],
            chartOptionsKBLI: {
                chart: {
                    type: 'bar',
                    height: 450,
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                legend: {
                    showForSingleSeries: true
                },
                colors: [
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#444'],
                        fontSize: '9px'
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: false
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },

                xaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    },
                    categories: [],
                    title: {
                        text: 'Jumlah KBLI (KBLI Amount)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                title: {
                    text: 'Jumlah Investasi Berdasarkan KBLI',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Investment Amount Based on KBLI)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            seriesresiko: [],
            chartOptionsresiko: {
                chart: {
                    type: 'donut',
                },
                colors: ['#20B2AA', '#FFA500', '#32CD32', '#FF0000', '#20B2AA', '#32CD32'],
                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            seriesstatus: [],
            chartOptionsstatus: {
                chart: {
                    type: 'donut',
                },
                colors: ['#f9a867', '#ad3335', '#32CD32', '#FF0000', '#20B2AA', '#32CD32'],
                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },


            datamasterkbli: [],
            kodekbli: [],
            datainvestmaxkbli: '',
            datainvestmaxnama: '',
            datainvestmaxnilai: 0,
            datainvestminkbli: '',
            datainvestminnama: '',
            datainvestminnilai: 0,
            datajumlahmaxkbli: '',
            datajumlahmaxnama: '',
            datajumlahmaxnilai: 0,
            datajumlahminkbli: '',
            datajumlahminnama: '',
            datajumlahminnilai: 0,

            seriesSektor: [],
            chartOptionsSektor: {
                chart: {
                    type: 'bar',
                    height: 450,
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                legend: {
                    showForSingleSeries: true
                },
                colors: [
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#444'],
                        fontSize: '9px'
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: false
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },

                xaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    },
                    categories: [],
                    title: {
                        text: 'Jumlah Sektor (Sector Amount)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                title: {
                    text: 'Jumlah Investasi Berdasarkan Sektor',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Investment Amount Based on Sector)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            datamastersektor: [],
            sektor: [],
            datainvestmaxnamasektor: '',
            datainvestmaxnilaisektor: 0,
            datainvestminnamasektor: '',
            datainvestminnilaisektor: 0,
            datajumlahmaxnamasektor: '',
            datajumlahmaxnilaisektor: 0,
            datajumlahminnamasektor: '',
            datajumlahminnilaisektor: 0,

            seriesPerusahaan: [],
            chartOptionsPerusahaan: {
                chart: {
                    type: 'bar',
                    height: 350,
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                legend: {
                    showForSingleSeries: true
                },
                colors: [
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#444'],
                        fontSize: '9px'
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: false
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },

                xaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    },
                    categories: [],
                    title: {
                        text: 'Jumlah Perusahaan (Company Amount)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                title: {
                    text: 'Jumlah Investasi Berdasarkan Perusahaan',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Investment Amount Based on Company)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            datamasterperusahaan: [],
            perusahaan: [],
            datainvestmaxnamaperusahaan: '',
            datainvestmaxnilaiperusahaan: 0,
            datainvestminnamaperusahaan: '',
            datainvestminnilaiperusahaan: 0,
            datajumlahmaxnamaperusahaan: '',
            datajumlahmaxnilaiperusahaan: 0,
            datajumlahminnamaperusahaan: '',
            datajumlahminnilaiperusahaan: 0,

            jumlahclikkbli: 0,
            jumlahclikresiko: 0,
            jumlahcliksektor: 0,
            jumlahclikperusahaan: 0,
            jumlahclikstatus: 0,

            datamasterresiko: [],
            datamasterstatus: [],

            sektornswi: [],
            datamastertahunsektornswi: [],
            datatahunsektornswi: [],
            defaultSelectedtahunsektornswi: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunsektornswi: '',
            seriesSektorJmlInvesPerusahaan: [],
            chartOptionsSektorJmlInvesPerusahaan: {
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                colors: ['#f9a867', '#6a6ba5'],
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return parseFloat(val).toFixed(2).toString().replaceAll(".", ",");
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '8px',
                        colors: ['#ff7f00', '#02366a'],
                    }
                },
                xaxis: {
                    categories: [],
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#02366a',
                                colorTo: '#111',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    },
                    labels: {
                        trim: true,
                        rotate: -90,
                        hideOverlappingLabels: true,
                        style: {
                            colors: [],
                            fontSize: '10px',
                        },
                    },
                    title: {
                        text: 'Sektor (Sector)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return parseFloat(val).toFixed(2).toString().replaceAll(".", ",");
                        }
                    }
                },
                title: {
                    text: 'Nilai Investasi',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Investment Value)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            seriesSektorJmlPerusahaan: [],
            chartOptionsSektorJmlPerusahaan: {
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                colors: ['#6a6ba5', '#f9a867'],
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '8px',
                        colors: ['#02366a', '#ff7f00'],
                    }
                },
                xaxis: {
                    categories: [],
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#02366a',
                                colorTo: '#111',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    },
                    labels: {
                        trim: true,
                        rotate: -90,
                        hideOverlappingLabels: true,
                        style: {
                            colors: [],
                            fontSize: '10px',
                        },
                    },
                    title: {
                        text: 'Sektor (Sector)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    }
                },
                title: {
                    text: 'Jumlah Perusahaan & Tenaga Kerja ',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Number of Companies & Workers)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
        };
    },
    methods: {
        dataSelectionResiko(resiko) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahunresiko + '|' + resiko, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDataResiko/" + bytes);
        },
        dataPointSelectionresiko(event) {
            try {
                var el = event.target;
                var dataPointIndex = parseInt(el.getAttribute("j"));
                var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahunresiko + '|' + this.chartOptionsresiko.labels[dataPointIndex], 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
                window.location.replace("/InvestasiDataResiko/" + bytes);
            } catch (error) {

            }
        },
        dataSelectionStatus(status) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahunstatus + '|' + status, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDataStatus/" + bytes);
        },
        dataPointSelectionstatus(event) {
            try {
                var el = event.target;
                var dataPointIndex = parseInt(el.getAttribute("j"));
                var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahunstatus + '|' + this.chartOptionsstatus.labels[dataPointIndex], 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
                window.location.replace("/InvestasiDataStatus/" + bytes);
            } catch (error) {

            }
        },
        async statusdata() {
            if (this.jumlahclikstatus == 0) {
                this.halamanloading = true;
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    },
                });
                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetTahunInvestasi").then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamastertahunstatus = Response.data.content.data;
                            this.datamastertahunstatus.forEach((item) => {
                                this.datatahunstatus.push({
                                    label: item.tahun,
                                    code: this.datamastertahunstatus.indexOf(item) + 1,
                                    tag: item.tahun,
                                });
                            });
                            this.datatahunstatus.unshift({
                                label: 'Semua Tahun',
                                code: 0,
                                tag: '',
                            });
                        }
                        this.jumlahclikstatus = this.jumlahclikstatus + 1;
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.jumlahclikstatus = 0;
                    this.datatahunstatus = [];
                    console.log(error);
                    this.halamanloading = false;
                });

                this.halamanloading = true;
                this.secretencDatatemp = this.tabaktif.split('|');
                if (this.secretencDatatemp[1] != '') {
                    this.selectedtahunstatus = this.secretencDatatemp[1];
                    this.datatahunstatus.forEach((item) => {
                        if (item.tag == this.selectedtahunstatus) {
                            this.defaultSelectedtahunstatus = {
                                code: this.datatahunstatus.indexOf(item) + 1,
                                label: this.selectedtahunstatus,
                                tag: this.selectedtahunstatus,
                            };
                        }
                    });
                }
                if (this.secretencDatatemp[1] === undefined) {
                    this.selectedtahunstatus = '';
                }

                if (this.selectedtahunstatus == '') {
                    if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                        this.tahunstatus = 'Semua Tahun';
                    }
                    else {
                        this.tahunstatus = 'All Years';
                    }
                }
                else {
                    this.tahunstatus = this.selectedtahunstatus;
                }
                this.halamanloading = false;
                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetStatusModal?tahun=" + this.selectedtahunstatus).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamasterstatus = Response.data.content.data[0].tabel;
                            this.seriesstatus = Response.data.content.data[0].nilai;
                            this.chartOptionsstatus = {
                                labels: Response.data.content.data[0].statusmodal,
                            };
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datamasterstatus = [];
                    this.seriesstatus = [];
                    this.chartOptionsstatus = {
                        labels: []
                    };
                    console.log(error);
                    this.halamanloading = false;
                });
            }
        },
        async resikodata() {
            if (this.jumlahclikresiko == 0) {
                this.halamanloading = true;
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    },
                });
                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetTahunInvestasi").then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamastertahunresiko = Response.data.content.data;
                            this.datamastertahunresiko.forEach((item) => {
                                this.datatahunresiko.push({
                                    label: item.tahun,
                                    code: this.datamastertahunresiko.indexOf(item) + 1,
                                    tag: item.tahun,
                                });
                            });
                            this.datatahunresiko.unshift({
                                label: 'Semua Tahun',
                                code: 0,
                                tag: '',
                            });
                        }
                        this.jumlahclikresiko = this.jumlahclikresiko + 1;
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.jumlahclikresiko = 0;
                    this.datatahun = [];
                    console.log(error);
                    this.halamanloading = false;
                });

                this.halamanloading = true;
                this.secretencDatatemp = this.tabaktif.split('|');
                if (this.secretencDatatemp[1] != '') {
                    this.selectedtahunresiko = this.secretencDatatemp[1];
                    this.datatahunresiko.forEach((item) => {
                        if (item.tag == this.selectedtahunresiko) {
                            this.defaultSelectedtahunresiko = {
                                code: this.datatahunresiko.indexOf(item) + 1,
                                label: this.selectedtahunresiko,
                                tag: this.selectedtahunresiko,
                            };
                        }
                    });
                }
                if (this.secretencDatatemp[1] === undefined) {
                    this.selectedtahunresiko = '';
                }

                if (this.selectedtahunresiko == '') {
                    if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                        this.tahunresiko = 'Semua Tahun';
                    }
                    else {
                        this.tahunresiko = 'All Years';
                    }
                }
                else {
                    this.tahunresiko = this.selectedtahunresiko;
                }
                this.halamanloading = false;
                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetResikoProyek?tahun=" + this.selectedtahunresiko).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamasterresiko = Response.data.content.data[0].tabel;
                            this.seriesresiko = Response.data.content.data[0].jumlah;
                            this.chartOptionsresiko = {
                                labels: Response.data.content.data[0].resiko,
                            };
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datamasterresiko = [];
                    this.seriesresiko = [];
                    this.chartOptionsresiko = {
                        labels: []
                    };
                    console.log(error);
                    this.halamanloading = false;
                });
            }
        },
        async kblidata() {
            if (this.jumlahclikkbli == 0) {
                this.halamanloading = true;
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    },
                });
                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetTahunInvestasi").then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamastertahun = Response.data.content.data;
                            this.datamastertahun.forEach((item) => {
                                this.datatahun.push({
                                    label: item.tahun,
                                    code: this.datamastertahun.indexOf(item) + 1,
                                    tag: item.tahun,
                                });
                            });
                            this.datatahun.unshift({
                                label: 'Semua Tahun',
                                code: 0,
                                tag: '',
                            });
                        }
                        this.jumlahclikkbli = this.jumlahclikkbli + 1;
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.jumlahclikkbli = 0;
                    this.datatahun = [];
                    console.log(error);
                    this.halamanloading = false;
                });

                this.halamanloading = true;
                this.secretencDatatemp = this.tabaktif.split('|');
                if (this.secretencDatatemp[1] != '') {
                    this.selectedtahun = this.secretencDatatemp[1];
                    this.datatahun.forEach((item) => {
                        if (item.tag == this.selectedtahun) {
                            this.defaultSelectedtahun = {
                                code: this.datatahun.indexOf(item) + 1,
                                label: this.selectedtahun,
                                tag: this.selectedtahun,
                            };
                        }
                    });
                }
                if (this.secretencDatatemp[1] === undefined) {
                    this.selectedtahun = '';
                }
                await mainAPI.get("datakitaResume-GetResumeKBLI?tahun=" + this.selectedtahun).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datainvestmaxkbli = Response.data.content.data[0].datainvestmax[0].kbli;
                            this.datainvestmaxnama = Response.data.content.data[0].datainvestmax[0].nama;
                            this.datainvestmaxnilai = Response.data.content.data[0].datainvestmax[0].nilai;

                            this.datainvestminkbli = Response.data.content.data[0].datainvestmin[0].kbli;
                            this.datainvestminnama = Response.data.content.data[0].datainvestmin[0].nama;
                            this.datainvestminnilai = Response.data.content.data[0].datainvestmin[0].nilai;

                            this.datajumlahmaxkbli = Response.data.content.data[0].datajumlahkblimax[0].kbli;
                            this.datajumlahmaxnama = Response.data.content.data[0].datajumlahkblimax[0].nama;
                            this.datajumlahmaxnilai = Response.data.content.data[0].datajumlahkblimax[0].jumlah;

                            this.datajumlahminkbli = Response.data.content.data[0].datajumlahkblimin[0].kbli;
                            this.datajumlahminnama = Response.data.content.data[0].datajumlahkblimin[0].nama;
                            this.datajumlahminnilai = Response.data.content.data[0].datajumlahkblimin[0].jumlah;

                            if (this.selectedtahun == '') {
                                if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                                    this.tahunKBLI = 'Semua Tahun';
                                }
                                else {
                                    this.tahunKBLI = 'All Years';
                                }
                            }
                            else {
                                this.tahunKBLI = this.selectedtahun;
                            }
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datainvestmaxkbli = '';
                    this.datainvestmaxnama = '';
                    this.datainvestmaxnilai = 0;

                    this.datainvestminkbli = '';
                    this.datainvestminnama = '';
                    this.datainvestminnilai = 0;

                    this.datajumlahmaxkbli = '';
                    this.datajumlahmaxnama = '';
                    this.datajumlahmaxnilai = 0;

                    this.datajumlahminkbli = '';
                    this.datajumlahminnama = '';
                    this.datajumlahminnilai = 0;
                    console.log(error);
                    this.halamanloading = false;
                });


                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetKBLI?tahun=" + this.selectedtahun).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamasterkbli = Response.data.content.data[0].tabel;
                            this.kodekbli = Response.data.content.data[0].kbli;
                            this.seriesKBLI = [
                                {
                                    "name": "Jumlah KBLI (Number of KBLI)",
                                    "data": Response.data.content.data[0].nilai
                                }];
                            this.chartOptionsKBLI = {
                                xaxis: {
                                    categories: Response.data.content.data[0].nama
                                },
                            };
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datamasterkbli = [];
                    this.seriesKBLI = [{
                        data: []
                    }];
                    this.chartOptionsKBLI = {
                        xaxis: {
                            categories: []
                        },
                    };
                    console.log(error);
                    this.halamanloading = false;
                });
            }
        },
        async sektordata() {
            if (this.jumlahcliksektor == 0) {
                this.halamanloading = true;
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    },
                });
                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetTahunInvestasi").then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamastertahunsektor = Response.data.content.data;
                            this.datamastertahunsektor.forEach((item) => {
                                this.datatahunsektor.push({
                                    label: item.tahun,
                                    code: this.datamastertahunsektor.indexOf(item) + 1,
                                    tag: item.tahun,
                                });
                            });
                            this.datatahunsektor.unshift({
                                label: 'Semua Tahun',
                                code: 0,
                                tag: '',
                            });
                        }
                        this.jumlahcliksektor = this.jumlahcliksektor + 1;
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.jumlahcliksektor = 0;
                    this.datatahunsektor = [];
                    console.log(error);
                    this.halamanloading = false;
                });

                this.halamanloading = true;
                this.secretencDatatemp = this.tabaktif.split('|');
                if (this.secretencDatatemp[1] != '') {
                    this.selectedtahunsektor = this.secretencDatatemp[1];
                    this.datatahunsektor.forEach((item) => {
                        if (item.tag == this.selectedtahunsektor) {
                            this.defaultSelectedtahunsektor = {
                                code: this.datatahunsektor.indexOf(item) + 1,
                                label: this.selectedtahunsektor,
                                tag: this.selectedtahunsektor,
                            };
                        }
                    });
                }
                if (this.secretencDatatemp[1] === undefined) {
                    this.selectedtahunsektor = '';
                }
                await mainAPI.get("datakitaResume-GetResumeSektor?tahun=" + this.selectedtahunsektor).then(
                    Response => {
                        this.datapesan = Response.data.message;

                        if (this.datapesan == 'data diketemukan') {
                            this.datainvestmaxnamasektor = Response.data.content.data[0].datainvestmax[0].sektor;
                            this.datainvestmaxnilaisektor = Response.data.content.data[0].datainvestmax[0].nilai;
                            this.datainvestminnamasektor = Response.data.content.data[0].datainvestmin[0].sektor;
                            this.datainvestminnilaisektor = Response.data.content.data[0].datainvestmin[0].nilai;

                            this.datajumlahmaxnamasektor = Response.data.content.data[0].datajumlahsektormax[0].sektor;
                            this.datajumlahmaxnilaisektor = Response.data.content.data[0].datajumlahsektormax[0].jumlah;

                            this.datajumlahminnamasektor = Response.data.content.data[0].datajumlahsektormin[0].sektor;
                            this.datajumlahminnilaisektor = Response.data.content.data[0].datajumlahsektormin[0].jumlah;
                            if (this.selectedtahunsektor == '') {
                                if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                                    this.tahunsektor = 'Semua Tahun';
                                }
                                else {
                                    this.tahunsektor = 'All Years';
                                }
                            }
                            else {
                                this.tahunsektor = this.selectedtahunsektor;
                            }
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datainvestmaxnamasektor = '';
                    this.datainvestmaxnilaisektor = 0;

                    this.datainvestminnamasektor = '';
                    this.datainvestminnilaisektor = 0;

                    this.datajumlahmaxnamasektor = '';
                    this.datajumlahmaxnilaisektor = 0;

                    this.datajumlahminnamasektor = '';
                    this.datajumlahminnilaisektor = 0;
                    console.log(error);
                    this.halamanloading = false;
                });


                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetDataSektor?tahun=" + this.selectedtahun).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamastersektor = Response.data.content.data[0].tabel;
                            this.sektor = Response.data.content.data[0].sektor;
                            this.seriesSektor = [
                                {
                                    "name": "Jumlah Sektor (Number of Sector)",
                                    "data": Response.data.content.data[0].nilai
                                }];
                            this.chartOptionsSektor = {
                                xaxis: {
                                    categories: Response.data.content.data[0].sektor
                                },
                            };
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datamastersektor = [];
                    this.seriesSektor = [{
                        data: []
                    }];
                    this.chartOptionsSektor = {
                        xaxis: {
                            categories: []
                        },
                    };
                    console.log(error);
                    this.halamanloading = false;
                });
            }

        },
        async perusahaandata() {
            if (this.jumlahclikperusahaan == 0) {
                this.halamanloading = true;
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    },
                });
                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetTahunInvestasi").then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamastertahunperusahaan = Response.data.content.data;
                            this.datamastertahunperusahaan.forEach((item) => {
                                this.datatahunperusahaan.push({
                                    label: item.tahun,
                                    code: this.datamastertahunperusahaan.indexOf(item) + 1,
                                    tag: item.tahun,
                                });
                            });
                            this.datatahunperusahaan.unshift({
                                label: 'Semua Tahun',
                                code: 0,
                                tag: '',
                            });
                        }
                        this.jumlahclikperusahaan = this.jumlahclikperusahaan + 1;
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.jumlahclikperusahaan = 0;
                    this.datatahunperusahaan = [];
                    console.log(error);
                    this.halamanloading = false;
                });

                this.halamanloading = true;
                this.secretencDatatemp = this.tabaktif.split('|');
                if (this.secretencDatatemp[1] != '') {
                    this.selectedtahunperusahaan = this.secretencDatatemp[1];
                    this.datatahunperusahaan.forEach((item) => {
                        if (item.tag == this.selectedtahunperusahaan) {
                            this.defaultSelectedtahunperusahaan = {
                                code: this.datatahunperusahaan.indexOf(item) + 1,
                                label: this.selectedtahunperusahaan,
                                tag: this.selectedtahunperusahaan,
                            };
                        }
                    });
                }
                if (this.secretencDatatemp[1] === undefined) {
                    this.selectedtahunperusahaan = '';
                }
                await mainAPI.get("datakitaResume-GetResumePerusahaan?tahun=" + this.selectedtahunperusahaan).then(
                    Response => {
                        this.datapesan = Response.data.message;

                        if (this.datapesan == 'data diketemukan') {
                            this.datainvestmaxnamaperusahaan = Response.data.content.data[0].datainvestmax[0].perusahaan;
                            this.datainvestmaxnilaiperusahaan = Response.data.content.data[0].datainvestmax[0].nilai;
                            this.datainvestminnamaperusahaan = Response.data.content.data[0].datainvestmin[0].perusahaan;
                            this.datainvestminnilaiperusahaan = Response.data.content.data[0].datainvestmin[0].nilai;

                            this.datajumlahmaxnamaperusahaan = Response.data.content.data[0].datajumlahperusahaanmax[0].perusahaan;
                            this.datajumlahmaxnilaiperusahaan = Response.data.content.data[0].datajumlahperusahaanmax[0].jumlah;

                            this.datajumlahminnamaperusahaan = Response.data.content.data[0].datajumlahperusahaanmin[0].perusahaan;
                            this.datajumlahminnilaiperusahaan = Response.data.content.data[0].datajumlahperusahaanmin[0].jumlah;
                            if (this.selectedtahunperusahaan == '') {
                                if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                                    this.tahunperusahaan = 'Semua Tahun';
                                }
                                else {
                                    this.tahunperusahaan = 'All Years';
                                }
                            }
                            else {
                                this.tahunperusahaan = this.selectedtahunperusahaan;
                            }
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datainvestmaxnamaperusahaan = '';
                    this.datainvestmaxnilaiperusahaan = 0;

                    this.datainvestminnamaperusahaan = '';
                    this.datainvestminnilaiperusahaan = 0;

                    this.datajumlahmaxnamaperusahaan = '';
                    this.datajumlahmaxnilaiperusahaan = 0;

                    this.datajumlahminnamaperusahaan = '';
                    this.datajumlahminnilaiperusahaan = 0;
                    console.log(error);
                    this.halamanloading = false;
                });


                this.halamanloading = true;
                await mainAPI.get("datakitaGrafik-GetDataPerusahaan?tahun=" + this.selectedtahun).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datamasterperusahaan = Response.data.content.data[0].tabel;
                            this.perusahaan = Response.data.content.data[0].perusahaan;
                            this.seriesPerusahaan = [
                                {
                                    "name": "Jumlah Perusahaan (Number of Company)",
                                    "data": Response.data.content.data[0].nilai
                                }];
                            this.chartOptionsPerusahaan = {
                                xaxis: {
                                    categories: Response.data.content.data[0].perusahaan
                                },
                            };
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datamasterperusahaan = [];
                    this.seriesPerusahaan = [{
                        data: []
                    }];
                    this.chartOptionsPerusahaan = {
                        xaxis: {
                            categories: []
                        },
                    };
                    console.log(error);
                    this.halamanloading = false;
                });
            }

        },
        ontahunsektorChange(a) {
            try {
                this.selectedtahunsektor = this.datatahunsektor[a].tag;
                this.ambilSektortahun(this.selectedtahunsektor);
            }
            catch (err) {
                return;
            }
        },
        ontahunsektornswiChange(a) {
            try {
                this.selectedtahunsektornswi = this.datatahunsektornswi[a].tag;
                this.sektornswiperizinan(this.selectedtahunsektornswi);
            }
            catch (err) {
                return;
            }
        },
        ontahunperusahaanChange(a) {
            try {
                this.selectedtahunperusahaan = this.datatahunperusahaan[a].tag;
                this.ambilPerusahaantahun(this.selectedtahunperusahaan);
            }
            catch (err) {
                return;
            }
        },
        ontahunChange(a) {
            try {
                this.selectedtahun = this.datatahun[a].tag;
                this.ambilKBLItahun(this.selectedtahun);
            }
            catch (err) {
                return;
            }
        },
        ontahunresikoChange(a) {
            try {
                this.selectedtahunresiko = this.datatahunresiko[a].tag;
                this.ambilresikotahun(this.selectedtahunresiko);
            }
            catch (err) {
                return;
            }
        },
        ontahunstatusChange(a) {
            try {
                this.selectedtahunstatus = this.datatahunstatus[a].tag;
                this.ambilstatustahun(this.selectedtahunstatus);
            }
            catch (err) {
                return;
            }
        },
        dataPointSelectionSektor(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahunsektor + '|' + this.sektor[dataPointIndex], 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            //alert(this.sektor[dataPointIndex])
            window.location.replace("/InvestasiDataSektor/" + bytes);
        },
        dataPointSelectionPerusahaan(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahunperusahaan + '|' + this.perusahaan[dataPointIndex], 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            //alert(this.perusahaan[dataPointIndex])
            window.location.replace("/InvestasiDataPerusahaan/" + bytes);
        },
        dataPointSelectionKBLI(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahun + '|' + this.kodekbli[dataPointIndex], 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDataKBLI/" + bytes);
        },
        dataSelectionSektor(sektor) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahun + '|' + sektor, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDataSektor/" + bytes);
        },
        dataSelectionPerusahaan(perusahaan) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahun + '|' + perusahaan, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDataPerusahaan/" + bytes);
        },
        dataSelectionKBLI(kbli) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.selectedtahun + '|' + kbli, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDataKBLI/" + bytes);
        },
        dataPointSelectionTahun(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(this.seriestahun[dataPointIndex], 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiData/" + bytes);
        },
        dataSelectionTahun(tahun) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(tahun, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiData/" + bytes);
        },
        dataPointSelectionKecamatan(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            //alert(this.serieskecamatan[dataPointIndex]);
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt('|' + this.serieskecamatan[dataPointIndex], 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDetail/" + bytes);
        },
        dataSelectionKecamatan(kecamatan) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt('|' + kecamatan, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/InvestasiDetail/" + bytes);
        },
        jumpTo(komponenid) {
            const element = document.getElementById(komponenid);
            element.scrollIntoView();
        },
        async sektornswiperizinan(tahun) {
            if (tahun === undefined) {
                tahun = '';
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafikNSWI-GetBarSektorPerusahaan?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.sektornswi = Response.data.content.detail;
                        this.chartOptionsSektorJmlInvesPerusahaan = {
                            xaxis: {
                                categories: Response.data.content.data[0].NamaSektor,
                            }
                        }
                        this.seriesSektorJmlInvesPerusahaan = [
                            {
                                name: 'Nilai Investasi (Milyard Rupiah)',
                                data: Response.data.content.data[0].NilaiInvestasiRupiah
                            },
                            {
                                name: 'Investment Value (Billion Dollars)',
                                data: Response.data.content.data[0].NilaiInvestasiDollar
                            },
                        ];
                        this.chartOptionsSektorJmlPerusahaan = {
                            xaxis: {
                                categories: Response.data.content.data[0].NamaSektor,
                            }
                        }
                        this.seriesSektorJmlPerusahaan = [
                            {
                                name: 'Jumlah Perusahaan / Number of Companies',
                                data: Response.data.content.data[0].JumlahPerusahaan
                            },
                            {
                                name: 'Jumlah Tenaga Kerja / Number of Workers',
                                data: Response.data.content.data[0].TKI
                            },
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.sektornswi = [];
                this.chartOptionsSektorJmlInvesPerusahaan = {
                    xaxis: {
                        categories: [],
                    }
                };
                this.seriesSektorJmlInvesPerusahaan = [];
                this.chartOptionsSektorJmlPerusahaan = {
                    xaxis: {
                        categories: [],
                    },
                };
                this.seriesSektorJmlPerusahaan = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafikNSWI-GetTahunSektorPerusahaan").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamastertahunsektornswi = Response.data.content.data;
                        this.datamastertahunsektornswi.forEach((item) => {
                            this.datatahunsektornswi.push({
                                label: item.Tahun,
                                code: this.datamastertahunsektornswi.indexOf(item) + 1,
                                tag: item.Tahun,
                            });
                        });
                        this.datatahunsektornswi.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datatahunsektornswi = [];
                console.log(error);
                this.halamanloading = false;
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarTotalInvestasi").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterJumlahInvesTahunan = [];
                        this.datamasterJumlahInvesTahunan = Response.data.content.data[0].tabel;
                        this.seriestahun = Response.data.content.data[0].tahun;
                        var totalpertumbuhan = this.datamasterJumlahInvesTahunan.reduce((a, { PertumbuhanInvestasi: e }) => a + e, 0);
                        var jumlahdata = this.datamasterJumlahInvesTahunan.length;
                        if (jumlahdata == 0) {
                            this.pertumbuhan = 0;
                        }
                        else {
                            this.pertumbuhan = totalpertumbuhan / (jumlahdata - 1);
                        }
                        this.chartOptionsTotalInvestasi = {
                            xaxis: {
                                categories: Response.data.content.data[0].tahun
                            },
                            yaxis: {
                                tickAmount: 10,
                                labels: {
                                    formatter: function (value) {
                                        return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " M";
                                    }

                                },
                            },
                        };
                        this.seriesTotalInvestasi = [
                            {
                                "name": "Nilai Kumulatif Investasi (Cumulative Investment Value)",
                                "type": "line",
                                "data": Response.data.content.data[0].TotalInvestasi
                            },
                            {
                                "name": "Nilai Investasi (Investment Value)",
                                "type": "bar",
                                "data": Response.data.content.data[0].JumlahInvestasi
                            },
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamasterJumlahInvesTahunan = [];
                this.chartOptionsTotalInvestasi = {
                    xaxis: {
                        categories: []
                    },
                };
                this.seriesTotalInvestasi = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("datakitaResume-GetResumeInvestasi").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.jumlah = Response.data.content.data[0].jumlah;
                        this.maximal = Response.data.content.data[0].maximal;
                        this.minimal = Response.data.content.data[0].minimal;
                        this.count = Response.data.content.data[0].count;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarTotalKecamatanInvestasi").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterJumlahInvesKecamatan = [];
                        this.serieskecamatan = Response.data.content.data[0].Kecamatan;
                        this.datamasterJumlahInvesKecamatan = Response.data.content.data[0].tabel;
                        this.chartOptionsTotalInvestasiKecamatan = {
                            xaxis: {
                                categories: Response.data.content.data[0].Kecamatan
                            },
                            yaxis: {
                                tickAmount: 10,
                                labels: {
                                    formatter: function (value) {
                                        return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " M";
                                    }

                                },
                            },
                        };
                        this.seriesTotalInvestasiKecamatan = [
                            {
                                "name": "Total Investasi (Total  Investment)",
                                "type": "line",
                                "data": Response.data.content.data[0].TotalInvestasi
                            },
                            {
                                "name": "Nilai Investasi (Investment Value)",
                                "type": "bar",
                                "data": Response.data.content.data[0].JumlahInvestasi
                            },
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsTotalInvestasiKecamatan = {
                    xaxis: {
                        categories: []
                    },
                };
                this.seriesTotalInvestasiKecamatan = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.secretencDatatemp = this.tabaktif.split('|');

            if (this.tabaktif == '') {
                $('.nav-tabs a[href="#custom-tabs-four-home"]').tab('show');
            }
            else if (this.secretencDatatemp[0] == 'kbli') {
                $('.nav-tabs a[href="#custom-tabs-four-profile"]').tab('show');
                this.kblidata();
            }
            else if (this.secretencDatatemp[0] == 'risk') {
                $('.nav-tabs a[href="#custom-tabs-four-risk"]').tab('show');
                this.resikodata();
            }
            else if (this.secretencDatatemp[0] == 'sektor') {
                $('.nav-tabs a[href="#custom-tabs-four-messages"]').tab('show');
                this.sektordata();
            }
            else if (this.secretencDatatemp[0] == 'status') {
                $('.nav-tabs a[href="#custom-tabs-four-status"]').tab('show');
                this.statusdata();
            }
            else {
                $('.nav-tabs a[href="#custom-tabs-four-settings"]').tab('show');
                this.perusahaandata();
            }
        },
        async ambilSektortahun(tahun) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            await mainAPI.get("datakitaResume-GetResumeSektor?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;

                    if (this.datapesan == 'data diketemukan') {
                        this.datainvestmaxnamasektor = Response.data.content.data[0].datainvestmax[0].sektor;
                        this.datainvestmaxnilaisektor = Response.data.content.data[0].datainvestmax[0].nilai;
                        this.datainvestminnamasektor = Response.data.content.data[0].datainvestmin[0].sektor;
                        this.datainvestminnilaisektor = Response.data.content.data[0].datainvestmin[0].nilai;

                        this.datajumlahmaxnamasektor = Response.data.content.data[0].datajumlahsektormax[0].sektor;
                        this.datajumlahmaxnilaisektor = Response.data.content.data[0].datajumlahsektormax[0].jumlah;

                        this.datajumlahminnamasektor = Response.data.content.data[0].datajumlahsektormin[0].sektor;
                        this.datajumlahminnilaisektor = Response.data.content.data[0].datajumlahsektormin[0].jumlah;
                        if (this.selectedtahunsektor == '') {
                            if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                                this.tahunsektor = 'Semua Tahun';
                            }
                            else {
                                this.tahunsektor = 'All Years';
                            }
                        }
                        else {
                            this.tahunsektor = tahun;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datainvestmaxnamasektor = '';
                this.datainvestmaxnilaisektor = 0;

                this.datainvestminnamasektor = '';
                this.datainvestminnilaisektor = 0;

                this.datajumlahmaxnamasektor = '';
                this.datajumlahmaxnilaisektor = 0;

                this.datajumlahminnamasektor = '';
                this.datajumlahminnilaisektor = 0;
                console.log(error);
                this.halamanloading = false;
            });


            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetDataSektor?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamastersektor = Response.data.content.data[0].tabel;
                        this.sektor = Response.data.content.data[0].sektor;
                        this.seriesSektor = [
                            {
                                "name": "Jumlah Sektor (Number of Sector)",
                                "data": Response.data.content.data[0].nilai
                            }];
                        this.chartOptionsSektor = {
                            xaxis: {
                                categories: Response.data.content.data[0].sektor
                            },
                        };
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamastersektor = [];
                this.seriesSektor = [{
                    data: []
                }];
                this.chartOptionsSektor = {
                    xaxis: {
                        categories: []
                    },
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
        async ambilPerusahaantahun(tahun) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            await mainAPI.get("datakitaResume-GetResumePerusahaan?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;

                    if (this.datapesan == 'data diketemukan') {
                        this.datainvestmaxnamaperusahaan = Response.data.content.data[0].datainvestmax[0].perusahaan;
                        this.datainvestmaxnilaiperusahaan = Response.data.content.data[0].datainvestmax[0].nilai;
                        this.datainvestminnamaperusahaan = Response.data.content.data[0].datainvestmin[0].perusahaan;
                        this.datainvestminnilaiperusahaan = Response.data.content.data[0].datainvestmin[0].nilai;

                        this.datajumlahmaxnamaperusahaan = Response.data.content.data[0].datajumlahperusahaanmax[0].perusahaan;
                        this.datajumlahmaxnilaiperusahaan = Response.data.content.data[0].datajumlahperusahaanmax[0].jumlah;

                        this.datajumlahminnamaperusahaan = Response.data.content.data[0].datajumlahperusahaanmin[0].perusahaan;
                        this.datajumlahminnilaiperusahaan = Response.data.content.data[0].datajumlahperusahaanmin[0].jumlah;
                        if (this.selectedtahunperusahaan == '') {
                            if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                                this.tahunperusahaan = 'Semua Tahun';
                            }
                            else {
                                this.tahunperusahaan = 'All Years';
                            }
                        }
                        else {
                            this.tahunperusahaan = tahun;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datainvestmaxnamaperusahaan = '';
                this.datainvestmaxnilaiperusahaan = 0;

                this.datainvestminnamaperusahaan = '';
                this.datainvestminnilaiperusahaan = 0;

                this.datajumlahmaxnamaperusahaan = '';
                this.datajumlahmaxnilaiperusahaan = 0;

                this.datajumlahminnamaperusahaan = '';
                this.datajumlahminnilaiperusahaan = 0;
                console.log(error);
                this.halamanloading = false;
            });


            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetDataPerusahaan?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterperusahaan = Response.data.content.data[0].tabel;
                        this.perusahaan = Response.data.content.data[0].perusahaan;
                        this.seriesPerusahaan = [
                            {
                                "name": "Jumlah Perusahaan (Number of Company)",
                                "data": Response.data.content.data[0].nilai
                            }];
                        this.chartOptionsPerusahaan = {
                            xaxis: {
                                categories: Response.data.content.data[0].perusahaan
                            },
                        };
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamasterperusahaan = [];
                this.seriesPerusahaan = [{
                    data: []
                }];
                this.chartOptionsPerusahaan = {
                    xaxis: {
                        categories: []
                    },
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
        async ambilKBLItahun(tahun) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            this.halamanloading = true;
            await mainAPI.get("datakitaResume-GetResumeKBLI?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datainvestmaxkbli = Response.data.content.data[0].datainvestmax[0].kbli;
                        this.datainvestmaxnama = Response.data.content.data[0].datainvestmax[0].nama;
                        this.datainvestmaxnilai = Response.data.content.data[0].datainvestmax[0].nilai;

                        this.datainvestminkbli = Response.data.content.data[0].datainvestmin[0].kbli;
                        this.datainvestminnama = Response.data.content.data[0].datainvestmin[0].nama;
                        this.datainvestminnilai = Response.data.content.data[0].datainvestmin[0].nilai;

                        this.datajumlahmaxkbli = Response.data.content.data[0].datajumlahkblimax[0].kbli;
                        this.datajumlahmaxnama = Response.data.content.data[0].datajumlahkblimax[0].nama;
                        this.datajumlahmaxnilai = Response.data.content.data[0].datajumlahkblimax[0].jumlah;

                        this.datajumlahminkbli = Response.data.content.data[0].datajumlahkblimin[0].kbli;
                        this.datajumlahminnama = Response.data.content.data[0].datajumlahkblimin[0].nama;
                        this.datajumlahminnilai = Response.data.content.data[0].datajumlahkblimin[0].jumlah;

                        if (this.selectedtahun == '') {
                            if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                                this.tahunKBLI = 'Semua Tahun';
                            }
                            else {
                                this.tahunKBLI = 'All Years';
                            }
                        }
                        else {
                            this.tahunKBLI = tahun;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datainvestmaxkbli = '';
                this.datainvestmaxnama = '';
                this.datainvestmaxnilai = 0;

                this.datainvestminkbli = '';
                this.datainvestminnama = '';
                this.datainvestminnilai = 0;

                this.datajumlahmaxkbli = '';
                this.datajumlahmaxnama = '';
                this.datajumlahmaxnilai = 0;

                this.datajumlahminkbli = '';
                this.datajumlahminnama = '';
                this.datajumlahminnilai = 0;
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetKBLI?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterkbli = Response.data.content.data[0].tabel;
                        this.kodekbli = Response.data.content.data[0].kbli;
                        this.seriesKBLI = [
                            {
                                "name": "Jumlah KBLI (Number of KBLI)",
                                "data": Response.data.content.data[0].nilai
                            }];
                        this.chartOptionsKBLI = {
                            xaxis: {
                                categories: Response.data.content.data[0].nama
                            },
                        };
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamasterkbli = [];
                this.seriesKBLI = [{
                    data: []
                }];
                this.chartOptionsKBLI = {
                    xaxis: {
                        categories: []
                    },
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
        async ambilstatustahun(tahun) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            if (this.selectedtahunstatus == '') {
                if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                    this.tahunstatus = 'Semua Tahun';
                }
                else {
                    this.tahunstatus = 'All Years';
                }
            }
            else {
                this.tahunstatus = tahun;
            }
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetStatusModal?tahun=" + status).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterstatus = Response.data.content.data[0].tabel;
                        this.seriesstatus = Response.data.content.data[0].nilai;
                        this.chartOptionsstatus = {
                            labels: Response.data.content.data[0].statusmodal,
                        };
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamasterstatus = [];
                this.seriesstatus = [];
                this.chartOptionsstatus = {
                    labels: []
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
        async ambilresikotahun(tahun) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            if (this.selectedtahunresiko == '') {
                if ((this.bahasa === null) || (this.bahasa == 'ina')) {
                    this.tahunresiko = 'Semua Tahun';
                }
                else {
                    this.tahunresiko = 'All Years';
                }
            }
            else {
                this.tahunresiko = tahun;
            }
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetResikoProyek?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterresiko = Response.data.content.data[0].tabel;
                        this.seriesresiko = Response.data.content.data[0].jumlah;
                        this.chartOptionsresiko = {
                            labels: Response.data.content.data[0].resiko,
                        };
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamasterresiko = [];
                this.seriesresiko = [];
                this.chartOptionsresiko = {
                    labels: []
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>