<template>
    <Top />
    <div class="modal fade" id="costumModaldetailinvestasi" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
        aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
        style="margin: 0;">
        <div class="modal-dialog" style="position: absolute;top: 0;">
            <div class="modal-content" style="height: auto;min-width: 100vw;">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Daftar Detail Data Perizinan - Jumlah Data :
                            {{ G_numFormat(jumlahdata) }} - Jumlah Halaman {{ G_numFormat(jumlahhalaman) }}</span>
                        <span v-else>List of Detailed Licensing Data - Amount of data : {{ G_numFormat(jumlahdata)
                            }} - Number of pages {{ G_numFormat(jumlahhalaman) }}</span>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <div class="col-sm-12 text-left">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman</span>
                                    <span v-else>Pages</span>
                                </label>&nbsp;
                                <select v-model="halaman" @change="onChangeHalaman($event)">
                                    <option v-for="index in jumlahhalaman" :key="index">
                                        {{ index }}</option>
                                </select>
                            </div>
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabeldetailizin">
                                    <thead>
                                        <tr>
                                            <th style="width: 50px;">No</th>
                                            <th style="width: 80px;">Id Proyek</th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">NIB</span>
                                                <span v-else>NIB</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Perusahaan</span>
                                                <span v-else>Company</span>
                                            </th>
                                            <th style="width: 60px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Status<br>Penanaman<br>Modal</span>
                                                <span v-else>Investment<br>Status</span>
                                            </th>
                                            <th style="width: 80px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Provinsi</span>
                                                <span v-else>Province</span>
                                            </th>
                                            <th style="width: 80px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Kota</span>
                                                <span v-else>City</span>
                                            </th>
                                            <th style="width: 50px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Basis<br>Resiko</span>
                                                <span v-else>Risk<br>Basis</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">KBLI</span>
                                                <span v-else>KBLI</span>
                                            </th>
                                            <th style="width: 150px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Jenis<br>Perizinan</span>
                                                <span v-else>Licensing<br>Type</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Dokumen</span>
                                                <span v-else>Document</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Kewenangan</span>
                                                <span v-else>Authority</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span
                                                    v-if="(bahasa === null) || (bahasa == 'ina')">Uraian<br>Kewenangan</span>
                                                <span v-else>Description of<br>Authority</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Status</span>
                                                <span v-else>Status</span>
                                            </th>
                                            <th style="width: 100px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sektor</span>
                                                <span v-else>Sector</span>
                                            </th>
                                            <th style="width: 50px;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Tahun</span>
                                                <span v-else>Years</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalistdetail, urutlistdetail) in datadetail" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td class="komponenakhir">
                                                    {{ (halaman * 100) + urutlistdetail + 1 - 100 }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.id_proyek }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.nib }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.nama_perusahaan }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.uraian_status_penanaman_modal }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.propinsi }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.kab_kota }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.kd_resiko }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.kbli }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.uraian_jenis_perizinan }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.nama_dokumen }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.kewenangan }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.uraian_kewenangan }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.uraian_status_respon }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.kl_sektor }}
                                                </td>
                                                <td class="komponenakhir text-justify">
                                                    {{ datalistdetail.tahun_pengambilan_data }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                            <div class="col-sm-12 text-left">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Halaman</span>
                                    <span v-else>Pages</span>
                                </label>&nbsp;
                                <select v-model="halaman" @change="onChangeHalaman($event)">
                                    <option v-for="index in jumlahhalaman" :key="index">
                                        {{ index }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    </p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-sm btn-danger3 text-white lebar2" data-dismiss="modal"> <i
                            class="far fa-times-circle"></i>
                        Tutup</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/dpmptsp_logo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">PERIZINAN</span>
                <span v-else>PERMISSIONS</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Perizinan</span>
                    <span v-else>Permissions</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <div class="container-fluid feature bg-trans py-2 wow fadeInUp" data-wow-delay="0.1s">
        <div class="cardputih4 card-outline card-outline-tabs bg-trans">
            <div class="cardputih3-header p-0 border-bottom-0">
                <div class="container-fluid feature py-3">
                    <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="sub-style">
                            <h2 class="sub-title px-3 mb-0">
                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Klasifikasi Data</span>
                                <span v-else>Data Classification</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                            href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                            aria-selected="true">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Jenis Perizinan</span>
                            <span v-else>Licensing Type</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="resikoperizinan()">
                        <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                            href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Resiko Perizinan</span>
                            <span v-else>Licensing Risk</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="statusperizinan()">
                        <a class="nav-link" id="custom-tabs-four-risk-tab" data-toggle="pill"
                            href="#custom-tabs-four-risk" role="tab" aria-controls="custom-tabs-four-risk"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Status Perizinan</span>
                            <span v-else>Licensing Status</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="modalperizinan()">
                        <a class="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill"
                            href="#custom-tabs-four-messages" role="tab" aria-controls="custom-tabs-four-messages"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Status Modal Perizinan</span>
                            <span v-else>Licensing Capital Status</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="kbliperizinan()">
                        <a class="nav-link" id="custom-tabs-four-status-tab" data-toggle="pill"
                            href="#custom-tabs-four-status" role="tab" aria-controls="custom-tabs-four-status"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                KBLI Perizinan</span>
                            <span v-else>KBLI Licensing</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="sektorperizinan()">
                        <a class="nav-link" id="custom-tabs-four-settings-tab" data-toggle="pill"
                            href="#custom-tabs-four-settings" role="tab" aria-controls="custom-tabs-four-settings"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                Sektor Perizinan</span>
                            <span v-else>Licensing Sector</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="iumkperizinan()">
                        <a class="nav-link" id="custom-tabs-four-iumk-tab" data-toggle="pill"
                            href="#custom-tabs-four-iumk" role="tab" aria-controls="custom-tabs-four-iumk"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                IUMK</span>
                            <span v-else>IUMK</span>
                        </a>
                    </li>
                    <li class="nav-item" @click="simbgperizinan()">
                        <a class="nav-link" id="custom-tabs-four-simbg-tab" data-toggle="pill"
                            href="#custom-tabs-four-simbg" role="tab" aria-controls="custom-tabs-four-simbg"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                SIMBG</span>
                            <span v-else>SIMBG</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-four-mpp-tab" data-toggle="pill"
                            href="#custom-tabs-four-mpp" role="tab" aria-controls="custom-tabs-four-mpp"
                            aria-selected="false">
                            <span v-if="(bahasa === null) || (bahasa == 'ina')">
                                MPP</span>
                            <span v-else>MPP</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="custom-tabs-four-tabContent">
                    <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                        aria-labelledby="custom-tabs-four-home-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Sejak diberlakukannya Undang-Undang Nomor 11 Tahun 2020 Tentang Cipta Kerja (UU Cipta
                                Kerja), pemerintah melakukan pembaharuan terkait mekanisme penetapan perizinan berusaha
                                dan menyesuaikannya dengan Peraturan pemerintah Nomor 5 Tahun 2021 Tentang
                                Penyelenggaraan Perizinan Berusaha Berbasis Risiko.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                Since the enactment of Law Number 11 of 2020 concerning Job Creation (UU Job Creation),
                                the government has reformed the mechanism for determining business licensing and adapted
                                it to government Regulation Number 5 of 2021 concerning the Implementation of Risk-Based
                                Business Licensing.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Jenis Perizinan</span>
                                        <span v-else>Licensing Type</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahun" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahun" @update:modelValue="ontahunChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-6">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <BarChartJenisIzin height="500" :options="chartOptionsJenisIzin"
                                            :series="seriesJenisIzin" @dataPointSelection="dataPointSelectionJenis">
                                        </BarChartJenisIzin>
                                    </div>
                                    <div class="card-footer p-3 text-center">
                                        <h6 style="font-size: x-small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                RBA</span>
                                            <span v-else>Source: OSS RBA</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-pie"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Total
                                                        Perizinan</span>
                                                    <span v-else>Total Licensing</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ G_numFormat(jenisizintotal) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-line"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Perizinan
                                                        Tertinggi</span>
                                                    <span v-else>Highest Licensing</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namajenisizinmax }} {{ G_numFormat(jenisizinmax) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-charging-station"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Perizinan
                                                        Terendah</span>
                                                    <span v-else>Lowest Licensing</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namajenisizinmin }} {{ G_numFormat(jenisizinmin) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                        <div class="card cardputih3">
                                            <div class="cardputih3-header border-transparent">
                                                <h6 class="float-left">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data
                                                        Jenis Perizinan</span>
                                                    <span v-else>Licensing Type Data</span>
                                                </h6>
                                                <div class="card-tools float-right">
                                                    <button type="button" class="btn btn-tool"
                                                        data-card-widget="collapse">
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body p-0">
                                                <div class="table-responsive table-wrapper">
                                                    <table class="table m-0" id="tabelinvestasi">
                                                        <thead>
                                                            <tr>
                                                                <td class="levelmerah">No</td>
                                                                <td class="levelmerah">Jenis<br>(Type)</td>
                                                                <td class="levelmerah">
                                                                    Jumlah<br>(Amount)
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <template v-for="(datalist, urutlist) in jenisizin"
                                                            :key="urutlist">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="komponenakhir">
                                                                        {{ urutlist + 1 }}
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        <a href="#"
                                                                            @click="dataSelectionJenis(datalist.Jenis)">{{
                                                                                datalist.Jenis }}</a>
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        {{ G_numFormat(datalist.Jumlah) }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="card-footer p-3">
                                                <h6 style="font-size: x-small;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                        RBA</span>
                                                    <span v-else>Source: OSS RBA</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                <button type="button" class="btn btn-sm btn-danger3 text-white lebar2"
                                    @click="bukasiimut()" data-toggle="tooltip" data-placement="left"
                                    title="Batalkan proses.."><i class="far fa-address-card"></i> Perizinan Si
                                    IMUT</button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                        aria-labelledby="custom-tabs-four-profile-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Perizinan berusaha yang sebelumnya berbasis izin maka saat ini penetapan perizinan
                                berusaha didasarkan kepada tingkat risiko usaha. Dimana semua perizinan akan melalui
                                satu pintu yaitu One Single Submission Risk Based Approach (OSS - RBA) atau lebih
                                dikenal dengan sebutan OSS Berbasis Risiko.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                Business licensing was previously based on permits, so currently the determination of
                                business licensing is based on the level of business risk. Where all permits will go
                                through one door, namely One Single Submission Risk Based Approach (OSS - RBA) or better
                                known as Risk Based OSS.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Resiko Perizinan</span>
                                        <span v-else>Licensing Risk</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunresiko" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunresiko" @update:modelValue="ontahunresikoChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-6">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <BarChartResikoIzin height="500" :options="chartOptionsResikoIzin"
                                            :series="seriesResikoIzin"
                                            @dataPointSelection="dataPointSelectionJenisResiko">
                                        </BarChartResikoIzin>
                                    </div>
                                    <div class="card-footer p-3 text-center">
                                        <h6 style="font-size: x-small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                RBA</span>
                                            <span v-else>Source: OSS RBA</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-pie"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Total
                                                        Perizinan</span>
                                                    <span v-else>Total Licensing</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ G_numFormat(resikoizintotal) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-line"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Resiko Perizinan
                                                        Tertinggi</span>
                                                    <span v-else>Highest Licensing Risk</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namaresikoizinmax }} {{ G_numFormat(resikoizinmax) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-charging-station"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Resiko Perizinan
                                                        Terendah</span>
                                                    <span v-else>Lowest Licensing Risk</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namaresikoizinmin }} {{ G_numFormat(resikoizinmin) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                        <div class="card cardputih3">
                                            <div class="cardputih3-header border-transparent">
                                                <h6 class="float-left">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data
                                                        Jenis Resiko Perizinan</span>
                                                    <span v-else>Licensing Risk Type Data</span>
                                                </h6>
                                                <div class="card-tools float-right">
                                                    <button type="button" class="btn btn-tool"
                                                        data-card-widget="collapse">
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body p-0">
                                                <div class="table-responsive table-wrapper">
                                                    <table class="table m-0" id="tabelinvestasi">
                                                        <thead>
                                                            <tr>
                                                                <td class="levelmerah">No</td>
                                                                <td class="levelmerah">Jenis<br>(Type)</td>
                                                                <td class="levelmerah">
                                                                    Jumlah<br>(Amount)
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <template v-for="(datalist, urutlist) in resikoizin"
                                                            :key="urutlist">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="komponenakhir">
                                                                        {{ urutlist + 1 }}
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        <a href="#"
                                                                            @click="dataSelectionJenisResiko(datalist.Jenis)">{{
                                                                                datalist.Jenis }}</a>
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        {{ G_numFormat(datalist.Jumlah) }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="card-footer p-3">
                                                <h6 style="font-size: x-small;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                        RBA</span>
                                                    <span v-else>Source: OSS RBA</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-risk" role="tabpanel"
                        aria-labelledby="custom-tabs-four-risk-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Perizinan Berusaha Terintegrasi Secara Elektronik atau Online Single Submission yang
                                selanjutnya disingkat OSS adalah Perizinan Berusaha yang diterbitkan oleh Lembaga OSS
                                untuk dan atas nama menteri, pimpinan lembaga, gubernur, atau bupati/wali kota kepada
                                Pelaku Usaha melalui sistem elektronik yang terintegrasi.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                Electronically Integrated Business Licensing or Online Single Submission, hereinafter
                                abbreviated to OSS, is a Business Licensing issued by the OSS Institution for and on
                                behalf of ministers, heads of institutions, governors, or regents/mayors to Business
                                Actors through an integrated electronic system.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Status Perizinan</span>
                                        <span v-else>Licensing Status</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunstatus" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunstatus" @update:modelValue="ontahunstatusChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-6">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <BarChartStatusResponIzin height="500" :options="chartOptionsStatusResponIzin"
                                            :series="seriesStatusResponIzin"
                                            @dataPointSelection="dataPointSelectionJenisStatus">
                                        </BarChartStatusResponIzin>
                                    </div>
                                    <div class="card-footer p-3 text-center">
                                        <h6 style="font-size: x-small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                RBA</span>
                                            <span v-else>Source: OSS RBA</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-pie"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Total
                                                        Perizinan</span>
                                                    <span v-else>Total Licensing</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ G_numFormat(statusizintotal) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-line"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Status Perizinan
                                                        Tertinggi</span>
                                                    <span v-else>Highest Licensing Status</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namastatusizinmax }} {{ G_numFormat(statusizinmax) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-charging-station"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Status Perizinan
                                                        Terendah</span>
                                                    <span v-else>Lowest Licensing Status</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namastatusizinmin }} {{ G_numFormat(statusizinmin) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                        <div class="card cardputih3">
                                            <div class="cardputih3-header border-transparent">
                                                <h6 class="float-left">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data
                                                        Status Perizinan</span>
                                                    <span v-else>Licensing Status Type Data</span>
                                                </h6>
                                                <div class="card-tools float-right">
                                                    <button type="button" class="btn btn-tool"
                                                        data-card-widget="collapse">
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body p-0">
                                                <div class="table-responsive table-wrapper">
                                                    <table class="table m-0" id="tabelinvestasi">
                                                        <thead>
                                                            <tr>
                                                                <td class="levelmerah">No</td>
                                                                <td class="levelmerah">Status</td>
                                                                <td class="levelmerah">
                                                                    Jumlah<br>(Amount)
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <template v-for="(datalist, urutlist) in statusizin"
                                                            :key="urutlist">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="komponenakhir">
                                                                        {{ urutlist + 1 }}
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        <a href="#"
                                                                            @click="dataSelectionJenisStatus(datalist.Jenis)">{{
                                                                                datalist.Jenis }}</a>
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        {{ G_numFormat(datalist.Jumlah) }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="card-footer p-3">
                                                <h6 style="font-size: x-small;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                        RBA</span>
                                                    <span v-else>Source: OSS RBA</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-messages" role="tabpanel"
                        aria-labelledby="custom-tabs-four-messages-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Badan Koordinasi Penanaman Modal (BKPM) tentunya sudah tidak asing lagi bagi para pelaku
                                usaha di Indonesia. BKPM adalah Lembaga Pemerintah Non Kementerian yang bertugas untuk
                                melaksanakan koordinasi kebijakan dan pelayanan di bidang penanaman modal berdasarkan
                                ketentuan peraturan perundang-undangan. Sebagai penghubung utama antara dunia usaha dan
                                pemerintah, BKPM diberi mandat untuk mendorong investasi langsung, baik dari dalam
                                negeri maupun luar negeri, melalui penciptaan iklim investasi yang kondusif.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                The Investment Coordinating Board (BKPM) is certainly no stranger to business actors in
                                Indonesia. BKPM is a non-ministerial government institution tasked with coordinating
                                policies and services in the investment sector based on statutory provisions. As the
                                main link between the business world and the government, BKPM is given the mandate to
                                encourage direct investment, both from within the country and abroad, through creating a
                                conducive investment climate.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Status Modal
                                            Perizinan</span>
                                        <span v-else>Licensing Capital Status</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunmodal" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunmodal" @update:modelValue="ontahunmodalChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-6">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <BarChartStatusModalIzin height="500" :options="chartOptionsStatusModalIzin"
                                            :series="seriesStatusModalIzin"
                                            @dataPointSelection="dataPointSelectionJenisModal">
                                        </BarChartStatusModalIzin>
                                    </div>
                                    <div class="card-footer p-3 text-center">
                                        <h6 style="font-size: x-small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                RBA</span>
                                            <span v-else>Source: OSS RBA</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-pie"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Total
                                                        Perizinan</span>
                                                    <span v-else>Total Licensing</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ G_numFormat(modalizintotal) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-line"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Modal Perizinan
                                                        Tertinggi</span>
                                                    <span v-else>Highest Capital Status</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namamodalizinmax }} {{ G_numFormat(modalizinmax) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-charging-station"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Modal Perizinan
                                                        Terendah</span>
                                                    <span v-else>Lowest Capital Status</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namamodalizinmin }} {{ G_numFormat(modalizinmin) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                        <div class="card cardputih3">
                                            <div class="cardputih3-header border-transparent">
                                                <h6 class="float-left">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Data
                                                        Status Modal Perizinan</span>
                                                    <span v-else>Licensing Capital Status Type Data</span>
                                                </h6>
                                                <div class="card-tools float-right">
                                                    <button type="button" class="btn btn-tool"
                                                        data-card-widget="collapse">
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body p-0">
                                                <div class="table-responsive table-wrapper">
                                                    <table class="table m-0" id="tabelinvestasi">
                                                        <thead>
                                                            <tr>
                                                                <td class="levelmerah">No</td>
                                                                <td class="levelmerah">Status Modal<br>Capital Status
                                                                </td>
                                                                <td class="levelmerah">
                                                                    Jumlah<br>(Amount)
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <template v-for="(datalist, urutlist) in modalizin"
                                                            :key="urutlist">
                                                            <tbody>
                                                                <tr>
                                                                    <td class="komponenakhir">
                                                                        {{ urutlist + 1 }}
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        <a href="#"
                                                                            @click="dataSelectionJenisModal(datalist.Jenis)">{{
                                                                                datalist.Jenis }}</a>
                                                                    </td>
                                                                    <td class="komponenakhir">
                                                                        {{ G_numFormat(datalist.Jumlah) }}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="card-footer p-3">
                                                <h6 style="font-size: x-small;">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                        RBA</span>
                                                    <span v-else>Source: OSS RBA</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-status" role="tabpanel"
                        aria-labelledby="custom-tabs-four-status-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                KBLI adalah Klasifikasi Baku Lapangan Usaha Indonesia, pengklasifikasian
                                aktivitas/kegiatan
                                ekonomi Indonesia yang
                                menghasilkan produk/output, baik berupa barang maupun jasa, berdasarkan lapangan usaha
                                untuk
                                memberikan keseragaman
                                konsep, definisi, dan klasifikasi lapangan usaha dalam perkembangan dan pergeseran
                                kegiatan
                                ekonomi di Indonesia.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                KBLI is the Standard Classification of Indonesian Business Fields, a classification of
                                Indonesian economic
                                activities/activities that produce products/output, both in the form of goods and
                                services,
                                based on business fields
                                to provide uniform concepts, definitions and classifications of business fields in the
                                development and shifts in
                                economic activities in Indonesia.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">KBLI</span>
                                        <span v-else>KBLI</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunkbli" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunkbli" @update:modelValue="ontahunkbliChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-pie"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Total
                                                        Perizinan</span>
                                                    <span v-else>Total Licensing</span>
                                                </span>
                                                <span class="info-box-number">
                                                    <br>
                                                    {{ G_numFormat(kbliizintotal) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-line"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">KBLI Perizinan
                                                        Tertinggi</span>
                                                    <span v-else>Highest KBLI Status</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namakbliizinmax }}<br>{{ G_numFormat(kbliizinmax) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-charging-station"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">KBLI Perizinan
                                                        Terendah</span>
                                                    <span v-else>Lowest KBLI Status</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namakbliizinmin }}<br>{{ G_numFormat(kbliizinmin) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">
                                <BarChartTotalKBLI :options="chartOptionsKBLI" :series="seriesKBLI"
                                    @dataPointSelection="dataPointSelectionKBLI" height="950">
                                </BarChartTotalKBLI>
                            </div>
                            <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <h6 class="float-left">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Data
                                                KBLI Perizinan</span>
                                            <span v-else>Licensing KBLI Type Data</span>
                                        </h6>
                                        <div class="card-tools float-right">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="table-responsive table-wrapper">
                                            <table class="table m-0" id="tabelinvestasi">
                                                <thead>
                                                    <tr>
                                                        <td class="levelmerah">No</td>
                                                        <td class="levelmerah">Kode<br>(Code)
                                                        </td>
                                                        <td class="levelmerah">KBLI</td>
                                                        <td class="levelmerah">
                                                            Jumlah<br>(Amount)
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <template v-for="(datalist, urutlist) in kbliizin" :key="urutlist">
                                                    <tbody>
                                                        <tr>
                                                            <td class="komponenakhir">
                                                                {{ urutlist + 1 }}
                                                            </td>
                                                            <td class="komponenakhir">
                                                                <a href="#"
                                                                    @click="dataSelectionJenisKBLI(datalist.kbli)">{{
                                                                        datalist.kbli }}</a>
                                                            </td>
                                                            <td class="komponenakhir text-justify">
                                                                <a href="#"
                                                                    @click="dataSelectionJenisKBLI(datalist.kbli)">{{
                                                                        datalist.nama }}</a>
                                                            </td>
                                                            <td class="komponenakhir">
                                                                {{ G_numFormat(datalist.jumlah) }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card-footer p-3">
                                        <h6 style="font-size: x-small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                RBA</span>
                                            <span v-else>Source: OSS RBA</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-settings" role="tabpanel"
                        aria-labelledby="custom-tabs-four-settings-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Sejumlah sektor prioritas Kota Semarang dapat menjadi peluang investasi bagi para
                                investor
                                yang hendak melakukan
                                investasi di Kota Semarang.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                A number of priority sectors in Semarang City can become investment opportunities for
                                investors who want to
                                invest in Semarang City.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">SEKTOR PERIZINAN</span>
                                        <span v-else>LICENSING SECTOR</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunsektor" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunsektor" @update:modelValue="ontahunsektorChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-pie"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Total
                                                        Perizinan</span>
                                                    <span v-else>Total Licensing</span>
                                                </span>
                                                <span class="info-box-number">
                                                    <br>
                                                    {{ G_numFormat(sektorizintotal) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-chart-line"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Sektor Perizinan
                                                        Tertinggi</span>
                                                    <span v-else>Highest Sector Status</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namasektorizinmax }}<br>{{ G_numFormat(sektorizinmax) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="info-box bg-trans">
                                            <span class="info-box-icon bg-merah elevation-1">
                                                <i class="fas fa-charging-station"></i>
                                            </span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">
                                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Sektor Perizinan
                                                        Terendah</span>
                                                    <span v-else>Lowest Sector Status</span>
                                                </span>
                                                <span class="info-box-number">
                                                    {{ namasektorizinmin }}<br>{{ G_numFormat(sektorizinmin) }}
                                                    <small>Data</small>
                                                </span>
                                            </div>
                                            <!-- /.info-box-content -->
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 text-center">
                                <BarChartTotalSektor :options="chartOptionsSektor" :series="seriesSektor"
                                    @dataPointSelection="dataPointSelectionSektor" height="500">
                                </BarChartTotalSektor>
                            </div>
                            <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <h6 class="float-left">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Data
                                                KBLI Perizinan</span>
                                            <span v-else>Licensing KBLI Type Data</span>
                                        </h6>
                                        <div class="card-tools float-right">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="table-responsive table-wrapper">
                                            <table class="table m-0" id="tabelinvestasi">
                                                <thead>
                                                    <tr>
                                                        <td class="levelmerah">No</td>
                                                        <td class="levelmerah">Sektor<br>(Sector)
                                                        </td>
                                                        <td class="levelmerah">
                                                            Jumlah<br>(Amount)
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <template v-for="(datalist, urutlist) in sektorizin" :key="urutlist">
                                                    <tbody>
                                                        <tr>
                                                            <td class="komponenakhir">
                                                                {{ urutlist + 1 }}
                                                            </td>
                                                            <td class="komponenakhir text-justify">
                                                                <a href="#"
                                                                    @click="dataSelectionJenisSektor(datalist.nama)">{{
                                                                        datalist.nama }}</a>
                                                            </td>
                                                            <td class="komponenakhir">
                                                                {{ G_numFormat(datalist.jumlah) }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card-footer p-3">
                                        <h6 style="font-size: x-small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: OSS
                                                RBA</span>
                                            <span v-else>Source: OSS RBA</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-iumk" role="tabpanel"
                        aria-labelledby="custom-tabs-four-iumk-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Pemerintah telah menetapkan Izin Usaha Mikro dan Kecil (IUMK). Pada dasarnya, IUMK
                                merupakan simplifikasi
                                izin bagi para pelaku usaha mikro dan kecil dalam bentuk naskah 1 lembar yang dapat
                                ditetapkan oleh Pemda
                                (Camat) hingga tingkat Kelurahan/Desa.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                The government has established Micro and Small Business Permits (IUMK). Basically,
                                IUMK is a simplified
                                permit for micro and small business actors in the form of a 1-sheet document which
                                can be determined by the
                                Regional Government (District Head) up to the Subdistrict/Village level.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Data Izin Usaha Mikro
                                            dan Kecil</span>
                                        <span v-else>Micro and Small Business License Data</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahuniumk" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahuniumk" @update:modelValue="ontahuniumkChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-lg-6 text-center">
                                <DonutsChartStatusIUMK type="donut" :options="chartOptionsstatusiumk"
                                    :series="seriesstatusiumk" height="350">
                                </DonutsChartStatusIUMK>
                            </div>
                            <div class="col-lg-6 text-center">
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Status IUMK</span>
                                                <span v-else>IUMK Status</span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabelinvestasi">
                                                    <thead>
                                                        <tr>
                                                            <td class="levelmerah">No</td>
                                                            <td class="levelmerah">Status
                                                            </td>
                                                            <td class="levelmerah">
                                                                Jumlah<br>(Amount)
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in iumk" :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="komponenakhir">
                                                                    {{ urutlist + 1 }}
                                                                </td>
                                                                <td class="komponenakhir text-justify">
                                                                    {{ datalist.Status }}
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormat(datalist.Jumlah) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <h6 style="font-size: x-small;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: NSWI</span>
                                                <span v-else>Source: NSWI</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-simbg" role="tabpanel"
                        aria-labelledby="custom-tabs-four-simbg-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Gedung (SIMBG) adalah sistem elektronik berbasis web yang digunakan untuk melaksanakan
                                proses penyelenggaraan PBG, SLF, SBKBG, RTB, dan Pendataan disertai dengan informasi
                                terkait
                                penyelenggaraan bangunan gedung.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                Building (SIMBG) is a web-based electronic system used to carry out the process of
                                implementing PBG, SLF, SBKBG, RTB, and Data Collection accompanied by information
                                related to
                                building management.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Monitoring Data</span>
                                        <span v-else>Data Monitoring</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <label>
                                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pilih Tahun</span>
                                    <span v-else>Select Year</span>
                                </label>
                                <v-select :options="datatahunsimbg" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtahunsimbg" @update:modelValue="ontahunsimbgChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-lg-6 text-center">
                                <DonutsChartStatusSIMBG type="donut" :options="chartOptionsstatussimbg"
                                    :series="seriesstatussimbg" height="350">
                                </DonutsChartStatusSIMBG>
                            </div>
                            <div class="col-lg-6 text-center">
                                <div class="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="card cardputih3">
                                        <div class="cardputih3-header border-transparent">
                                            <h6 class="float-left">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Fungsi
                                                    Bangunan</span>
                                                <span v-else>Building Function</span>
                                            </h6>
                                            <div class="card-tools float-right">
                                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                    <i class="fas fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body p-0">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabelinvestasi">
                                                    <thead>
                                                        <tr>
                                                            <td class="levelmerah">No</td>
                                                            <td class="levelmerah">Fungsi Bangunan<br>(Building
                                                                Function)
                                                            </td>
                                                            <td class="levelmerah">
                                                                Jumlah<br>(Amount)
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in simbg" :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="komponenakhir">
                                                                    {{ urutlist + 1 }}
                                                                </td>
                                                                <td class="komponenakhir text-justify">
                                                                    {{ datalist.Status }}
                                                                </td>
                                                                <td class="komponenakhir">
                                                                    {{ G_numFormat(datalist.Jumlah) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="card-footer p-3">
                                            <h6 style="font-size: x-small;">
                                                <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: SIMBG</span>
                                                <span v-else>Source: SIMBG</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-mpp" role="tabpanel"
                        aria-labelledby="custom-tabs-four-mpp-tab">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">
                            <p class="first-letter-besar">
                                Mal Pelayanan Publik (MPP) adalah pengintegrasian Pelayanan Publik yang diberikan oleh
                                kementerian, lembaga, pemerintah daerah provinsi dan kabupaten/kota, badan usaha milik
                                negara, badan usaha milik daerah, serta swasta secara terpadu pada satu tempat sebagai
                                upaya
                                meningkatkan kecepatan, kemudahan, jangkauan, kenyamanan, dan keamanan pelayanan.
                            </p>
                        </span>
                        <span v-else>
                            <p class="first-letter-besar">
                                Public Service Mall (MPP) is the integration of Public Services provided by ministries,
                                institutions, provincial and district/city governments, state-owned enterprises,
                                regional-owned enterprises, and the private sector in an integrated manner in one place
                                as
                                an effort to increase speed, convenience and reach , comfort and security of service.
                            </p>
                        </span>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Monitoring Data</span>
                                        <span v-else>Data Monitoring</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <div class="card cardputih3">
                                    <div class="cardputih3-header border-transparent">
                                        <h6 class="float-left">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Layanan MPP</span>
                                            <span v-else>MPP Services</span>
                                        </h6>
                                        <div class="card-tools float-right">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="table-responsive table-wrapper">
                                            <table class="table m-0" id="tabelinvestasi">
                                                <thead>
                                                    <tr>
                                                        <td class="levelmerah">No</td>
                                                        <td class="levelmerah">Layanan MPP<br>(MPP Services)
                                                        </td>
                                                        <td class="levelmerah">
                                                            Jumlah<br>(Amount)
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <template v-for="(datalist, urutlist) in mpp" :key="urutlist">
                                                    <tbody>
                                                        <tr>
                                                            <td class="komponenakhir">
                                                                {{ urutlist + 1 }}
                                                            </td>
                                                            <td class="komponenakhir text-justify">
                                                                {{ datalist.Nama }}
                                                            </td>
                                                            <td class="komponenakhir">
                                                                {{ G_numFormat(datalist.Jumlah) }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card-footer p-3">
                                        <h6 style="font-size: x-small;">
                                            <span v-if="(bahasa === null) || (bahasa == 'ina')">Sumber: MPP</span>
                                            <span v-else>Source: MPP</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- /.card -->
        </div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, vSelect, BarChartJenisIzin: VueApexCharts, BarChartResikoIzin: VueApexCharts
        , BarChartStatusResponIzin: VueApexCharts, BarChartStatusModalIzin: VueApexCharts
        , BarChartTotalKBLI: VueApexCharts, BarChartTotalSektor: VueApexCharts
        , DonutsChartStatusIUMK: VueApexCharts, DonutsChartStatusSIMBG: VueApexCharts
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            datapesan: '',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            datadetail: [],
            parametertahun: '',
            parameterjenis: '',
            parameterresiko: '',
            parameterstatus: '',
            parametermodal: '',
            parameterkbli: '',
            parametersektor: '',
            jumlahclikkbli: 0,
            jumlahclikresiko: 0,
            jumlahcliksektor: 0,
            jumlahclikmodal: 0,
            jumlahclikstatus: 0,
            datamastertahun: [],
            datatahun: [],
            defaultSelectedtahun: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahun: '',
            datamastertahunresiko: [],
            datatahunresiko: [],
            defaultSelectedtahunresiko: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunresiko: '',
            datamastertahunstatus: [],
            datatahunstatus: [],
            defaultSelectedtahunstatus: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunstatus: '',
            datamastertahunmodal: [],
            datatahunmodal: [],
            defaultSelectedtahunmodal: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunmodal: '',
            datamastertahunkbli: [],
            datatahunkbli: [],
            defaultSelectedtahunkbli: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunkbli: '',
            datamastertahunsektor: [],
            datatahunsektor: [],
            defaultSelectedtahunsektor: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunsektor: '',
            seriesJenisIzin: [],
            datatahunizin: [],
            datajenisizin: [],
            chartOptionsJenisIzin: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                dataLabels: {
                    formatter: (val) => {
                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Tahun (Year)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                fill: {
                    opacity: 1
                },
                colors: ['#6a6ba5', '#f9a867', '#ad3335', '#202135'],
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center'
                },
                title: {
                    text: 'Jenis Izin',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Permission Type)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
            datatahunresikoizin: [],
            datajenisresikoizin: [],
            seriesResikoIzin: [],
            chartOptionsResikoIzin: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                dataLabels: {
                    formatter: (val) => {
                        return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Tahun (Year)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                fill: {
                    opacity: 1
                },
                colors: ['#202135', '#f9a867', '#DAA520', '#228B22', '#ad3335'],
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center'
                },
                title: {
                    text: 'Resiko Izin',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Permission Risk)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            datatahunstatusizin: [],
            datajenisstatusizin: [],
            seriesStatusResponIzin: [],
            chartOptionsStatusResponIzin: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                dataLabels: {
                    formatter: (val) => {
                        return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Tahun (Year)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                fill: {
                    opacity: 1
                },
                colors: ['#6a6ba5', '#f9a867', '#ad3335', '#202135'],
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center'
                },
                title: {
                    text: 'Status Respon',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Response Status)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
            datatahunmodalizin: [],
            datajenismodalizin: [],
            seriesStatusModalIzin: [],
            chartOptionsStatusModalIzin: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                dataLabels: {
                    formatter: (val) => {
                        return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                xaxis: {
                    categories: [],
                    title: {
                        text: 'Tahun (Year)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                fill: {
                    opacity: 1
                },
                colors: ['#6a6ba5', '#f9a867', '#ad3335', '#202135'],
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return parseFloat(val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center'
                },
                title: {
                    text: 'Status Modal',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Capital Status)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            datatahunkbliizin: [],
            datajeniskbliizin: [],
            datamasterkbli: [],
            kodekbli: [],
            seriesKBLI: [],
            chartOptionsKBLI: {
                chart: {
                    type: 'bar',
                    height: 450,
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                legend: {
                    showForSingleSeries: true
                },
                colors: [
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#444'],
                        fontSize: '9px'
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: false
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },

                xaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    },
                    categories: [],
                    title: {
                        text: 'Jumlah KBLI (KBLI Amount)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                title: {
                    text: 'Jumlah Izin Berdasarkan KBLI',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Licensing Amount Based on KBLI)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            datatahunsektorizin: [],
            datajenissektorizin: [],
            datamastersektor: [],
            sektor: [],
            seriesSektor: [],
            chartOptionsSektor: {
                chart: {
                    type: 'bar',
                    height: 450,
                    zoom: {
                        enabled: true,
                        type: 'x'
                    },
                },
                legend: {
                    showForSingleSeries: true
                },
                colors: [
                    '#f9a867',
                    '#2a6b9b',
                    '#6c98b7',
                    '#abc4d6',
                    '#d5e2eb'
                ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#444'],
                        fontSize: '9px'
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: false
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },

                xaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(0).toString().replaceAll(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    },
                    categories: [],
                    title: {
                        text: 'Jumlah Sektor (Sector Amount)',
                        offsetX: 0,
                        offsetY: 0,
                    },
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                title: {
                    text: 'Jumlah Izin Berdasarkan Sektor',
                    floating: true,
                    offsetY: -5,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                subtitle: {
                    text: '(Licensing Amount Based on Sector)',
                    floating: true,
                    offsetY: 10,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            datamastersektor: [],
            sektor: [],

            jenisizintotal: 0,
            jenisizinmin: 0,
            namajenisizinmin: '',
            jenisizinmax: 0,
            namajenisizinmax: '',
            jenisizin: [],

            resikoizintotal: 0,
            resikoizinmin: 0,
            namaresikoizinmin: '',
            resikoizinmax: 0,
            namaresikoizinmax: '',
            resikoizin: [],

            statusizintotal: 0,
            statusizinmin: 0,
            namastatusizinmin: '',
            statusizinmax: 0,
            namastatusizinmax: '',
            statusizin: [],

            modalizintotal: 0,
            modalizinmin: 0,
            namamodalizinmin: '',
            modalizinmax: 0,
            namamodalizinmax: '',
            modalizin: [],

            kbliizintotal: 0,
            kbliizinmin: 0,
            namakbliizinmin: '',
            kbliizinmax: 0,
            namakbliizinmax: '',
            kbliizin: [],

            sektorizintotal: 0,
            sektorizinmin: 0,
            namasektorizinmin: '',
            sektorizinmax: 0,
            namasektorizinmax: '',
            sektorizin: [],

            iumk: [],
            datamastertahuniumk: [],
            datatahuniumk: [],
            defaultSelectedtahuniumk: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahuniumk: '',

            seriesstatusiumk: [],
            chartOptionsstatusiumk: {
                chart: {
                    type: 'donut',
                },
                colors: ['#20B2AA', '#FFA500', '#32CD32', '#FF0000', '#20B2AA', '#32CD32'],
                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },

            datamastertahunsimbg: [],
            datatahunsimbg: [],
            defaultSelectedtahunsimbg: {
                code: 0,
                label: 'Semua Tahun',
                tag: '',
            },
            selectedtahunsimbg: '',
            simbg: [],

            seriesstatussimbg: [],
            chartOptionsstatussimbg: {
                chart: {
                    type: 'donut',
                },
                colors: ['#20B2AA', '#FFA500', '#32CD32', '#FF0000', '#20B2AA', '#32CD32'],
                tooltip: {
                    enabled: true,
                    formatter: function (value) {
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    formatter: function (value) {
                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        return parseFloat(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                    },
                                },
                                total: {
                                    formatter: function (w) {
                                        let setTotal = w.globals.seriesTotals.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                                        let val = (setTotal / 1).toFixed(0).replace('.', ',')
                                        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    },
                                    show: true,
                                },
                            }
                        }
                    }
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            mpp: [],
        };
    },
    methods: {
        bukasiimut() {
            window.open("https://siimut.semarangkota.go.id/");
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.parametertahun + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor + "&halaman=" + halamannya).then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.halamanloading = false;
            });
        },
        dataPointSelectionSektor(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            //alert(this.selectedtahunsektor);
            //alert(this.datajenissektorizin[dataPointIndex]);
            this.parametertahun = this.selectedtahunsektor;
            this.parameterjenis = '';
            this.parameterresiko = '';
            this.parameterstatus = '';
            this.parametermodal = '';
            this.parameterkbli = '';
            this.parametersektor = this.datajenissektorizin[dataPointIndex];
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.parametertahun + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor+ "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataPointSelectionKBLI(event) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            //alert(this.selectedtahunkbli);
            //alert(this.kodekbli[dataPointIndex]);
            this.parametertahun = this.selectedtahunkbli;
            this.parameterjenis = '';
            this.parameterresiko = '';
            this.parameterstatus = '';
            this.parametermodal = '';
            this.parameterkbli = this.kodekbli[dataPointIndex];
            this.parametersektor = '';
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.parametertahun + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor+ "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataSelectionJenisSektor(sektor) {
            //alert(this.selectedtahunsektor);
            //alert(sektor);
            this.parametertahun = this.selectedtahunsektor;
            this.parameterjenis = '';
            this.parameterresiko = '';
            this.parameterstatus = '';
            this.parametermodal = '';
            this.parameterkbli = '';
            this.parametersektor = sektor;
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.parametertahun + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor+ "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataSelectionJenisKBLI(kbli) {
            //alert(this.selectedtahunkbli);
            //alert(kbli);
            this.parametertahun = this.selectedtahunkbli;
            this.parameterjenis = '';
            this.parameterresiko = '';
            this.parameterstatus = '';
            this.parametermodal = '';
            this.parameterkbli = kbli;
            this.parametersektor = '';
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.parametertahun + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor+ "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataPointSelectionJenisModal(event, chartContext, config) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            //alert(this.datatahunmodalizin[dataPointIndex]);
            //alert(this.datajenismodalizin[config.seriesIndex]);
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.parametertahun = this.datatahunmodalizin[dataPointIndex];
            this.parameterjenis = '';
            this.parameterresiko = '';
            this.parameterstatus = '';
            this.parametermodal = this.datajenismodalizin[config.seriesIndex];
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.datatahunmodalizin[dataPointIndex] + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.datajenismodalizin[config.seriesIndex] + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor+ "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.halamanloading = false;
            });
        },
        dataPointSelectionJenisStatus(event, chartContext, config) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            //alert(this.datatahunstatusizin[dataPointIndex]);
            //alert(this.datajenisstatusizin[config.seriesIndex]);
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.parametertahun = this.datatahunstatusizin[dataPointIndex];
            this.parameterjenis = '';
            this.parameterresiko = '';
            this.parameterstatus = this.datajenisstatusizin[config.seriesIndex];
            this.parametermodal = '';
            this.parameterkbli = '';
            this.parametersektor = '';
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.datatahunstatusizin[dataPointIndex] + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.datajenisstatusizin[config.seriesIndex] + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor+ "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataPointSelectionJenisResiko(event, chartContext, config) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.parametertahun = this.datatahunresikoizin[dataPointIndex];
            this.parameterjenis = '';
            this.parameterresiko = this.datajenisresikoizin[config.seriesIndex];
            this.parameterstatus = '';
            this.parametermodal = '';
            this.parameterkbli = '';
            this.parametersektor = '';
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.datatahunresikoizin[dataPointIndex] + "&jenis=" + this.parameterjenis + "&resiko=" + this.datajenisresikoizin[config.seriesIndex] + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor+ "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataPointSelectionJenis(event, chartContext, config) {
            var el = event.target;
            //var seriesIndex = parseInt(el.getAttribute("i"));
            var dataPointIndex = parseInt(el.getAttribute("j"));
            //alert(this.datatahunizin[dataPointIndex]);
            //alert(this.datajenisizin[config.seriesIndex]);
            //console.log(config);
            //return;
            this.parametertahun = this.datatahunizin[dataPointIndex];
            this.parameterjenis = this.datajenisizin[config.seriesIndex];
            this.parameterresiko = '';
            this.parameterstatus = '';
            this.parametermodal = '';
            this.parameterkbli = '';
            this.parametersektor = '';
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.datatahunizin[dataPointIndex] + "&jenis=" + this.datajenisizin[config.seriesIndex] + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor).then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataSelectionJenisModal(modal) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.parametertahun = this.selectedtahunmodal;
            this.parameterjenis = '';
            this.parameterresiko = '';
            this.parameterstatus = '';
            this.parametermodal = modal;
            this.parameterkbli = '';
            this.parametersektor = '';
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.selectedtahunmodal + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor+ "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataSelectionJenisStatus(status) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.parametertahun = this.selectedtahunstatus;
            this.parameterjenis = '';
            this.parameterresiko = '';
            this.parameterstatus = status;
            this.parametermodal = '';
            this.parameterkbli = '';
            this.parametersektor = '';
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.selectedtahunstatus + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor+ "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataSelectionJenisResiko(resiko) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.parametertahun = this.selectedtahunresiko;
            this.parameterjenis = '';
            this.parameterresiko = resiko;
            this.parameterstatus = '';
            this.parametermodal = '';
            this.parameterkbli = '';
            this.parametersektor = '';
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.selectedtahunresiko + "&jenis=" + this.parameterjenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor + "&notcountjenis=Persyarat Dasar").then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        dataSelectionJenis(jenis) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.parametertahun = this.selectedtahun;
            this.parameterjenis = jenis;
            this.parameterresiko = '';
            this.parameterstatus = '';
            this.parametermodal = '';
            this.parameterkbli = '';
            this.parametersektor = '';
            mainAPI.get("datakitaDetail-GetDetailIzin?tahun=" + this.selectedtahun + "&jenis=" + jenis + "&resiko=" + this.parameterresiko + "&statusrespon=" + this.parameterstatus + "&modal=" + this.parametermodal + "&kbli=" + this.parameterkbli + "&sektor=" + this.parametersektor).then(
                Response => {
                    this.datadetail = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadetail = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 100) + 1;
                        }
                        $('#costumModaldetailinvestasi').modal('show');
                        this.halamanloading = false;
                    }
                    else {
                        this.halamanloading = false;
                    }

                }
            ).catch(error => {
                console.log(error);
                this.datadetail = [];
                this.jumlahdata = 0;
                this.jumlahhalaman = 0;
                this.mulai = 0;
                this.halaman = 1;
                this.parametertahun = '';
                this.parameterjenis = '';
                this.parameterresiko = '';
                this.parameterstatus = '';
                this.parametermodal = '';
                this.parameterkbli = '';
                this.parametersektor = '';
                this.halamanloading = false;
            });
        },
        ontahunChange(a) {
            try {
                this.selectedtahun = this.datatahun[a].tag;
                this.ambilJenisIzintahun(this.selectedtahun);
            }
            catch (err) {
                return;
            }
        },
        ontahunresikoChange(a) {
            try {
                this.selectedtahunresiko = this.datatahunresiko[a].tag;
                this.resikoperizinan(this.selectedtahunresiko);
            }
            catch (err) {
                return;
            }
        },
        ontahunstatusChange(a) {
            try {
                this.selectedtahunstatus = this.datatahunstatus[a].tag;
                this.statusperizinan(this.selectedtahunstatus);
            }
            catch (err) {
                return;
            }
        },
        ontahunmodalChange(a) {
            try {
                this.selectedtahunmodal = this.datatahunmodal[a].tag;
                this.modalperizinan(this.selectedtahunmodal);
            }
            catch (err) {
                return;
            }
        },
        ontahunkbliChange(a) {
            try {
                this.selectedtahunkbli = this.datatahunkbli[a].tag;
                this.kbliperizinan(this.selectedtahunkbli);
            }
            catch (err) {
                return;
            }
        },
        ontahunsektorChange(a) {
            try {
                this.selectedtahunsektor = this.datatahunsektor[a].tag;
                this.sektorperizinan(this.selectedtahunsektor);
            }
            catch (err) {
                return;
            }
        },
        ontahuniumkChange(a) {
            try {
                this.selectedtahuniumk = this.datatahuniumk[a].tag;
                this.iumkperizinan();
            }
            catch (err) {
                return;
            }
        },
        ontahunsimbgChange(a) {
            try {
                this.selectedtahunsimbg = this.datatahunsimbg[a].tag;
                this.simbgperizinan();
            }
            catch (err) {
                return;
            }
        },
        async simbgperizinan() {
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafikSIMBG-GetMonitoringSIMBG?tahun=" + this.selectedtahunsimbg).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.simbg = Response.data.content.data[0].tabel;
                        this.seriesstatussimbg = Response.data.content.data[0].Jumlah;
                        this.chartOptionsstatussimbg = {
                            labels: Response.data.content.data[0].Status,
                        };
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.simbg = [];
                this.seriesstatussimbg = [];
                this.chartOptionsstatussimbg = {
                    labels: []
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
        async iumkperizinan() {
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafikNSWI-GetResikoIUMK?tahun=" + this.selectedtahuniumk).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.iumk = Response.data.content.data[0].tabel;
                        this.seriesstatusiumk = Response.data.content.data[0].Jumlah;
                        this.chartOptionsstatusiumk = {
                            labels: Response.data.content.data[0].Status,
                        };
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.iumk = [];
                this.seriesstatusiumk = [];
                this.chartOptionsstatusiumk = {
                    labels: []
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
        async ambilJenisIzintahun(tahun) {
            this.halamanloading = true;
            this.jenisizintotal = 0;
            this.jenisizinmin = 0;
            this.namajenisizinmin = '';
            this.jenisizinmax = 0;
            this.namajenisizinmax = '';
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            await mainAPI.get("datakitaGrafik-GetBarJenisIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahunizin = Response.data.content.data[0].Tahun;
                        this.datajenisizin = Response.data.content.data[0].NamaJenis;
                        this.chartOptionsJenisIzin = {
                            xaxis: {
                                categories: Response.data.content.data[0].Tahun
                            },
                        }
                        this.seriesJenisIzin = Response.data.content.data[0].Jenis
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsJenisIzin = {
                    xaxis: {
                        categories: []
                    },
                }
                this.datatahunizin = [];
                this.datajenisizin = [];
                this.seriesJenisIzin = [];
                console.log(error);
                this.halamanloading = false;
            });

            await mainAPI.get("datakitaData-GetDataJenisIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.jenisizin = [];
                        this.jenisizintotal = Response.data.content.data[0].total;
                        this.jenisizinmin = Response.data.content.data[0].jumlahmin[0].Jumlah;
                        this.namajenisizinmin = Response.data.content.data[0].jumlahmin[0].Jenis;
                        this.jenisizinmax = Response.data.content.data[0].jumlahmax[0].Jumlah;
                        this.namajenisizinmax = Response.data.content.data[0].jumlahmax[0].Jenis;
                        this.jenisizin = Response.data.content.data[0].tabel;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.jenisizintotal = 0;
                this.jenisizinmin = 0;
                this.namajenisizinmin = '';
                this.jenisizinmax = 0;
                this.namajenisizinmax = '';
                this.jenisizin = [];
                this.halamanloading = false;
            });
        },
        async sektorperizinan(tahun) {
            if (tahun === undefined) {
                tahun = this.selectedtahunsektor;
            }
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarSektorIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.sektorizin = [];
                        this.sektorizintotal = Response.data.content.data[0].total;
                        this.sektorizinmin = Response.data.content.data[0].jumlahmin[0].jumlah;
                        this.namasektorizinmin = Response.data.content.data[0].jumlahmin[0].nama;
                        this.sektorizinmax = Response.data.content.data[0].jumlahmax[0].jumlah;
                        this.namasektorizinmax = Response.data.content.data[0].jumlahmax[0].nama;
                        this.sektorizin = Response.data.content.data[0].tabel;

                        this.datatahunsektorizin = [];
                        this.datajenissektorizin = [];
                        this.datatahunsektorizin = Response.data.content.data[0].Tahun;
                        this.datajenissektorizin = Response.data.content.data[0].nama;
                        this.datamastersektor = Response.data.content.data[0].tabel;
                        this.seriesSektor = [
                            {
                                "name": "Jumlah Sektor (Number of Sector)",
                                "data": Response.data.content.data[0].jumlah
                            }];
                        this.chartOptionsSektor = {
                            xaxis: {
                                categories: Response.data.content.data[0].nama
                            },
                        };
                    }
                    this.jumlahcliksektor = this.jumlahcliksektor + 1;
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.jumlahcliksektor = 0;
                this.sektorizin = [];
                this.sektorizintotal = 0;
                this.sektorizinmin = 0;
                this.namasektorizinmin = '';
                this.sektorizinmax = 0;
                this.namasektorizinmax = '';
                this.datatahunsektorizin = [];
                this.datajenissektorizin = [];
                this.datamastersektor = [];
                this.seriesSektor = [{
                    data: []
                }];
                this.chartOptionsSektor = {
                    xaxis: {
                        categories: []
                    },
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
        async kbliperizinan(tahun) {
            if (tahun === undefined) {
                tahun = this.selectedtahunkbli;
            }
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarKBLIIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.kbliizin = [];
                        this.kbliizintotal = Response.data.content.data[0].total;
                        this.kbliizinmin = Response.data.content.data[0].jumlahmin[0].jumlah;
                        this.namakbliizinmin = Response.data.content.data[0].jumlahmin[0].nama;
                        this.kbliizinmax = Response.data.content.data[0].jumlahmax[0].jumlah;
                        this.namakbliizinmax = Response.data.content.data[0].jumlahmax[0].nama;
                        this.kbliizin = Response.data.content.data[0].tabel;

                        this.datatahunkbliizin = [];
                        this.datajeniskbliizin = [];
                        this.datatahunkbliizin = Response.data.content.data[0].Tahun;
                        this.datajeniskbliizin = Response.data.content.data[0].nama;
                        this.datamasterkbli = Response.data.content.data[0].tabel;
                        this.kodekbli = Response.data.content.data[0].kbli;
                        this.seriesKBLI = [
                            {
                                "name": "Jumlah KBLI (Number of KBLI)",
                                "data": Response.data.content.data[0].jumlah
                            }];
                        this.chartOptionsKBLI = {
                            xaxis: {
                                categories: Response.data.content.data[0].nama
                            },
                        };
                    }
                    this.jumlahclikkbli = this.jumlahclikkbli + 1;
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.jumlahclikkbli = 0;
                this.kbliizin = [];
                this.kbliizintotal = 0;
                this.kbliizinmin = 0;
                this.namakbliizinmin = '';
                this.kbliizinmax = 0;
                this.namakbliizinmax = '';
                this.datatahunkbliizin = [];
                this.datajeniskbliizin = [];
                this.datamasterkbli = [];
                this.seriesKBLI = [{
                    data: []
                }];
                this.chartOptionsKBLI = {
                    xaxis: {
                        categories: []
                    },
                };
                console.log(error);
                this.halamanloading = false;
            });
        },
        async modalperizinan(tahun) {
            if (tahun === undefined) {
                tahun = this.selectedtahunmodal;
            }
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarStatusModalJenisIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahunmodalizin = [];
                        this.datajenismodalizin = [];
                        this.datatahunmodalizin = Response.data.content.data[0].Tahun;
                        this.datajenismodalizin = Response.data.content.data[0].NamaJenis;
                        this.chartOptionsStatusModalIzin = {
                            xaxis: {
                                categories: Response.data.content.data[0].Tahun
                            },
                        }
                        this.seriesStatusModalIzin = Response.data.content.data[0].Jenis
                    }
                    this.jumlahclikmodal = this.jumlahclikmodal + 1;
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.jumlahclikmodal = 0;
                this.datatahunmodalizin = [];
                this.datajenismodalizin = [];
                this.chartOptionsStatusModalIzin = {
                    xaxis: {
                        categories: []
                    },
                }
                this.seriesStatusModalIzin = [];
                console.log(error);
                this.halamanloading = false;
            });

            await mainAPI.get("datakitaData-GetDataJenisModalIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.modalizin = [];
                        this.modalizintotal = Response.data.content.data[0].total;
                        this.modalizinmin = Response.data.content.data[0].jumlahmin[0].Jumlah;
                        this.namamodalizinmin = Response.data.content.data[0].jumlahmin[0].Jenis;
                        this.modalizinmax = Response.data.content.data[0].jumlahmax[0].Jumlah;
                        this.namamodalizinmax = Response.data.content.data[0].jumlahmax[0].Jenis;
                        this.modalizin = Response.data.content.data[0].tabel;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.modalizintotal = 0;
                this.modalizinmin = 0;
                this.namamodalizinmin = '';
                this.modalizinmax = 0;
                this.namamodalizinmax = '';
                this.modalizin = [];
                this.halamanloading = false;
            });
        },
        async statusperizinan(tahun) {
            if (tahun === undefined) {
                tahun = this.selectedtahunstatus;
            }
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarStatusResponJenisIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahunstatusizin = [];
                        this.datajenisstatusizin = [];
                        this.datatahunstatusizin = Response.data.content.data[0].Tahun;
                        this.datajenisstatusizin = Response.data.content.data[0].NamaJenis;
                        this.chartOptionsStatusResponIzin = {
                            xaxis: {
                                categories: Response.data.content.data[0].Tahun
                            },
                        }
                        this.seriesStatusResponIzin = Response.data.content.data[0].Jenis
                    }
                    this.jumlahclikstatus = this.jumlahclikstatus + 1;
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.jumlahclikstatus = 0;
                this.datatahunstatusizin = [];
                this.datajenisstatusizin = [];
                this.chartOptionsStatusResponIzin = {
                    xaxis: {
                        categories: []
                    },
                }
                this.seriesStatusResponIzin = [];
                console.log(error);
                this.halamanloading = false;
            });

            await mainAPI.get("datakitaData-GetDataJenisStatusResponIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.statusizin = [];
                        this.statusizintotal = Response.data.content.data[0].total;
                        this.statusizinmin = Response.data.content.data[0].jumlahmin[0].Jumlah;
                        this.namastatusizinmin = Response.data.content.data[0].jumlahmin[0].Jenis;
                        this.statusizinmax = Response.data.content.data[0].jumlahmax[0].Jumlah;
                        this.namastatusizinmax = Response.data.content.data[0].jumlahmax[0].Jenis;
                        this.statusizin = Response.data.content.data[0].tabel;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.statusizintotal = 0;
                this.statusizinmin = 0;
                this.namastatusizinmin = '';
                this.statusizinmax = 0;
                this.namastatusizinmax = '';
                this.statusizin = [];
                this.halamanloading = false;
            });
        },
        async resikoperizinan(tahun) {
            if (tahun === undefined) {
                tahun = this.selectedtahunresiko;
            }
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarResikoJenisIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahunresikoizin = [];
                        this.datajenisresikoizin = [];
                        this.datatahunresikoizin = Response.data.content.data[0].Tahun;
                        this.datajenisresikoizin = Response.data.content.data[0].NamaJenis;
                        this.chartOptionsResikoIzin = {
                            xaxis: {
                                categories: Response.data.content.data[0].Tahun
                            },
                        }
                        this.seriesResikoIzin = Response.data.content.data[0].Jenis
                    }
                    this.jumlahclikresiko = this.jumlahclikresiko + 1;
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.jumlahclikresiko = 0;
                this.datatahunresikoizin = [];
                this.datajenisresikoizin = [];
                this.chartOptionsResikoIzin = {
                    xaxis: {
                        categories: []
                    },
                }
                this.seriesResikoIzin = [];
                console.log(error);
                this.halamanloading = false;
            });

            await mainAPI.get("datakitaData-GetDataJenisResikoIzin?tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.resikoizin = [];
                        this.resikoizintotal = Response.data.content.data[0].total;
                        this.resikoizinmin = Response.data.content.data[0].jumlahmin[0].Jumlah;
                        this.namaresikoizinmin = Response.data.content.data[0].jumlahmin[0].Jenis;
                        this.resikoizinmax = Response.data.content.data[0].jumlahmax[0].Jumlah;
                        this.namaresikoizinmax = Response.data.content.data[0].jumlahmax[0].Jenis;
                        this.resikoizin = Response.data.content.data[0].tabel;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.resikoizintotal = 0;
                this.resikoizinmin = 0;
                this.namaresikoizinmin = '';
                this.resikoizinmax = 0;
                this.namaresikoizinmax = '';
                this.resikoizin = [];
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer DKMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafikNSWI-GetTahunIUMK").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamastertahuniumk = Response.data.content.data;
                        this.datamastertahuniumk.forEach((item) => {
                            this.datatahuniumk.push({
                                label: item.Tahun,
                                code: this.datamastertahuniumk.indexOf(item) + 1,
                                tag: item.Tahun,
                            });
                        });
                        this.datatahuniumk.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamastertahuniumk = [];
                console.log(error);
                this.halamanloading = false;
            });
            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetBarJenisIzin").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahunizin = [];
                        this.datajenisizin = [];
                        this.datatahunizin = Response.data.content.data[0].Tahun;
                        this.datajenisizin = Response.data.content.data[0].NamaJenis;
                        this.chartOptionsJenisIzin = {
                            xaxis: {
                                categories: Response.data.content.data[0].Tahun
                            },
                        }
                        this.seriesJenisIzin = Response.data.content.data[0].Jenis
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.chartOptionsJenisIzin = {
                    xaxis: {
                        categories: []
                    },
                }
                this.datatahunizin = [];
                this.datajenisizin = [];
                this.seriesJenisIzin = [];
                console.log(error);
                this.halamanloading = false;
            });

            await mainAPI.get("datakitaData-GetDataJenisIzin").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.jenisizin = [];
                        this.jenisizintotal = Response.data.content.data[0].total;
                        this.jenisizinmin = Response.data.content.data[0].jumlahmin[0].Jumlah;
                        this.namajenisizinmin = Response.data.content.data[0].jumlahmin[0].Jenis;
                        this.jenisizinmax = Response.data.content.data[0].jumlahmax[0].Jumlah;
                        this.namajenisizinmax = Response.data.content.data[0].jumlahmax[0].Jenis;
                        this.jenisizin = Response.data.content.data[0].tabel;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.jenisizintotal = 0;
                this.jenisizinmin = 0;
                this.namajenisizinmin = '';
                this.jenisizinmax = 0;
                this.namajenisizinmax = '';
                this.jenisizin = [];
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("datakitaGrafik-GetTahunIzin").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamastertahun = Response.data.content.data;
                        this.datamastertahun.forEach((item) => {
                            this.datatahun.push({
                                label: item.tahun,
                                code: this.datamastertahun.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.datatahun.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });

                        this.datamastertahunresiko = Response.data.content.data;
                        this.datamastertahunresiko.forEach((item) => {
                            this.datatahunresiko.push({
                                label: item.tahun,
                                code: this.datamastertahunresiko.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.datatahunresiko.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });

                        this.datamastertahunstatus = Response.data.content.data;
                        this.datamastertahunstatus.forEach((item) => {
                            this.datatahunstatus.push({
                                label: item.tahun,
                                code: this.datamastertahunstatus.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.datatahunstatus.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });

                        this.datamastertahunmodal = Response.data.content.data;
                        this.datamastertahunmodal.forEach((item) => {
                            this.datatahunmodal.push({
                                label: item.tahun,
                                code: this.datamastertahunmodal.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.datatahunmodal.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });

                        this.datamastertahunkbli = Response.data.content.data;
                        this.datamastertahunkbli.forEach((item) => {
                            this.datatahunkbli.push({
                                label: item.tahun,
                                code: this.datamastertahunkbli.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.datatahunkbli.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });

                        this.datamastertahunsektor = Response.data.content.data;
                        this.datamastertahunsektor.forEach((item) => {
                            this.datatahunsektor.push({
                                label: item.tahun,
                                code: this.datamastertahunsektor.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.datatahunsektor.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datatahun = [];
                this.datatahunresiko = [];
                this.datatahunstatus = [];
                this.datatahunmodal = [];
                this.datatahunkbli = [];
                this.datatahunsektor = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            await mainAPI.get("datakitaTahunSIMBG-GetTahunSIMBG").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamastertahunsimbg = Response.data.content.data;
                        this.datamastertahunsimbg.forEach((item) => {
                            this.datatahunsimbg.push({
                                label: item.tahun,
                                code: this.datamastertahunsimbg.indexOf(item) + 1,
                                tag: item.tahun,
                            });
                        });
                        this.datatahunsimbg.unshift({
                            label: 'Semua Tahun',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datatahunsimbg = [];
                console.log(error);
                this.halamanloading = false;
            });

            this.halamanloading = true;
            this.mpp = [];
            await mainAPI.get("datakitaMPP-GetDataMPP").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.mpp = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.mpp = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>