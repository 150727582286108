<template>
    <div class="container-fluid bg-dark px-5 d-none d-lg-block">
        <div class="row gx-0 align-items-center" style="height: 45px;">
            <div class="col-lg-8 text-center text-lg-start mb-lg-0">
                <div class="d-flex flex-wrap">
                    <a href="#" class="text-light me-4">DPMPTSP KOTA SEMARANG</a>
                    <a href="#" class="text-light me-4"><i class="fas fa-phone-alt text-danger me-2"></i>024-3585944</a>
                    <a href="#" class="text-light me-0"><i
                            class="fas fa-envelope text-danger me-2"></i>dpmptsp@semarangkota.go.id</a>
                </div>
            </div>
            <div class="col-lg-4 text-center text-lg-end">
                <div class="d-flex align-items-center justify-content-end">
                    <a href="#" class="btn nav-fill me-6 text-white">Bahasa / Language</a>
                    <a href="#" class="btn btn-light btn-square border rounded-circle nav-fill me-3"
                        :class="[((bahasa === null) || (bahasa == 'ina') ? 'btn-pmis' : '')]"
                        @click="gantibahasa('ina')"><img src="../../../../../src/assets/img/ina.jpg" height="20"></a>
                    <a href="#" class="btn btn-light btn-square border rounded-circle nav-fill me-3"
                        :class="[((bahasa == 'eng') ? 'btn-pmis' : '')]" @click="gantibahasa('eng')"><img
                            src="../../../../../src/assets/img/eng.jpg" height="20"></a>
                </div>
            </div>
        </div>
    </div>
    <!-- Topbar End -->
    <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0">
            <a href="/" class="navbar-brand p-0">
                <h1 class="text-danger m-0 pmisdanger"><img src="../../../../../src/assets/img/LOGOSEMAR INVEST2.png"
                        height="40"> SEMAR INVEST</h1>
                <!-- <img src="img/logo.png" alt="Logo"> -->
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav ms-auto py-0">
                    <a href="/"
                        :class="[((route.name == 'Beranda') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">BERANDA</span>
                        <span v-else>HOME</span>
                    </a>
                    <a :href="'/Investasi/' + tabaktif"
                        :class="[(((route.name == 'Investasi')
                            || route.name == 'InvestasiData' || route.name == 'InvestasiDataKBLI'
                            || route.name == 'InvestasiDataResiko' || route.name == 'InvestasiDataStatus'
                            || route.name == 'InvestasiDataSektor' || route.name == 'InvestasiDataPerusahaan'
                            || route.name == 'InvestasiDetail' || route.name == 'InvestasiDetailKBLI'
                            || route.name == 'InvestasiDetailResiko' || route.name == 'InvestasiDetailStatus'
                            || route.name == 'InvestasiDetailSektor'
                            || route.name == 'InvestasiDetailPerusahaan') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">INVESTASI</span>
                        <span v-else>INVESTMENT</span>
                    </a>
                    <a href="/Perizinan"
                        :class="[((route.name == 'Perizinan') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">PERIZINAN</span>
                        <span v-else>PERMISSIONS</span>
                    </a>
                    <a href="/GIS"
                        :class="[((route.name == 'GIS') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">PETA</span>
                        <span v-else>MAP</span>
                    </a>
                    <a href="/KontakKami"
                        :class="[((route.name == 'KontakKami') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">KONTAK KAMI</span>
                        <span v-else>CONTACT US</span>
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        swal
    },
    data() {
        return {
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
        };
    },
    methods: {
        gantibahasa(bahasanya) {
            localStorage.setItem("bahasasistem", bahasanya);
            location.reload();
        },
    },
    mounted() {

    },
}
</script>

<style></style>