<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/LayerPeta">Layer Peta</a></li>
                                <li class="breadcrumb-item active">Pengaturan Data Layer Peta</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Data Layer Peta</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Kategori <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="kategori" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkategori" @update:modelValue="onkategoriChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Urutan Menu Layer</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="far fa-clone"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="urut_menu"
                                                    name="urut_menu" placeholder="Urutan Menu Layer"
                                                    v-model="urut_menu">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Layer <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama"
                                                    name="nama" placeholder="Nama Layer" v-model="nama">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Layer English<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama_eng"
                                                    name="nama_eng" placeholder="Nama Layer English" v-model="nama_eng">
                                            </div>
                                        </div>
                                        <div class="col-sm-6 float-left">
                                            <label>Sumber Peta</label>
                                            <v-select :options="asalpeta" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedasalpeta" @update:modelValue="onasalpetaChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6 float-left">
                                            <label>API Peta</label>
                                            <v-select :options="apipeta" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedapipeta" @update:modelValue="onapipetaChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12 float-left">&nbsp;</div>
                                        <!--PETA 1-->
                                        <div class="col-sm-12"
                                            :style="[(selectedasalpeta == 1 ? ':block' : 'display:none')]">
                                            <label>File<small class="text-info"> (*geojson files)</small>
                                            </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_peta" name="fileToUploadfilenya_peta"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept=".geojson">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_peta" name="filenya_peta" placeholder="File Peta">
                                            </div>
                                        </div>
                                        <div class="col-sm-12"
                                            :style="[(selectedasalpeta == 1 ? ':block' : 'display:none')]">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-9">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_peta">
                                                            <span id="tulisanpersenfilenya_peta">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_peta"></span>
                                                </div>
                                                <div class="col-sm-3 text-center">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadfilepeta('filenya_peta', '.geojson')">Upload
                                                        File</button>
                                                    <button type="button" class="btn btn-danger btn-sm lebar"
                                                        @click="clearfilepeta('filenya_peta')">Hapus File</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--PETA 1 END-->
                                        <div class="col-sm-12 float-left">
                                            <label>Tipe Peta</label>
                                            <v-select :options="tipepeta" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedTipePeta" @update:modelValue="onTipePetaChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12 float-left">&nbsp;</div>
                                        <!--IKON PETA 1-->
                                        <div class="col-sm-12"
                                            :style="[(selectedtipepeta == 2 ? ':block' : 'display:none')]">
                                            <label>Ikon<small class="text-info"> (*rekomendasi images files png
                                                    transparent 25px x
                                                    25px)</small>
                                            </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_ikon" name="fileToUploadfilenya_ikon"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="image/*">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_ikon" name="filenya_ikon" placeholder="File Ikon">
                                            </div>
                                        </div>
                                        <div class="col-sm-12"
                                            :style="[(selectedtipepeta == 2 ? ':block' : 'display:none')]">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-9">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_ikon">
                                                            <span id="tulisanpersenfilenya_ikon">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_ikon"></span>
                                                </div>
                                                <div class="col-sm-3 text-center">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadfile('filenya_ikon', 'image/*')">Upload
                                                        File</button>
                                                    <button type="button" class="btn btn-danger btn-sm lebar"
                                                        @click="clearfilepeta('filenya_ikon')">Hapus File</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--IKON PETA 1 END-->
                                        <div class="col-sm-6 mb-3"
                                            :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                            <label>Fill Color</label>
                                            <Vue3ColorPicker v-model="fill" mode="solid" :showColorList="false"
                                                :showEyeDrop="false" type="HEX" />
                                        </div>
                                        <div class="col-sm-6 mb-3"
                                            :style="[((selectedtipepeta == 1 || selectedtipepeta == 3) ? ':block' : 'display:none')]">
                                            <label>Stroke Color</label>
                                            <Vue3ColorPicker v-model="stroke" mode="solid" :showColorList="false"
                                                :showEyeDrop="false" type="HEX" />
                                        </div>
                                        <div class="col-sm-6"
                                            :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                            <label>Outline Width</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-arrows-alt-h"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm"
                                                    id="fill_width" name="fill_width" placeholder="Outline Width"
                                                    v-model="fill_width">
                                            </div>
                                        </div>
                                        <div class="col-sm-6"
                                            :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                            <label>Tipe Fill Polygon</label>
                                            <v-select :options="tipe_fill" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedTipeFill" @update:modelValue="onTipeFillChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6"
                                            :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                            <label>Tipe Pattern Polygon</label>
                                            <v-select :options="tipe_pattern" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedTipePattern"
                                                @update:modelValue="onTipePatternChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6"
                                            :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                            <label>Sudut Derajat Pattern</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="far fa-clone"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="sudut"
                                                    name="sudut" placeholder="Urutan Layer" v-model="sudut">
                                            </div>
                                        </div>
                                        <div class="col-sm-6"
                                            :style="[(selectedtipepeta == 3 ? ':block' : 'display:none')]">
                                            <label>Line Width</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-arrows-alt-h"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm"
                                                    id="stroke_width" name="stroke_width" placeholder="Line Width"
                                                    v-model="stroke_width">
                                            </div>
                                        </div>
                                        <div class="col-sm-6"
                                            :style="[(selectedtipepeta == 3 ? ':block' : 'display:none')]">
                                            <label>Dash Line Start</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-ellipsis-h"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm"
                                                    id="dash_start" name="dash_start" placeholder="Dash Line Start"
                                                    v-model="dash_start">
                                            </div>
                                        </div>
                                        <div class="col-sm-6"
                                            :style="[(selectedtipepeta == 3 ? ':block' : 'display:none')]">
                                            <label>Dash Line End</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-ellipsis-h"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="dash_end"
                                                    name="dash_end" placeholder="Dash Line End" v-model="dash_end">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Urutan Lapisan Layer</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="far fa-clone"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="urut"
                                                    name="urut" placeholder="Urutan Lapisan Layer" v-model="urut">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, Vue3ColorPicker
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            nama: '',
            nama_eng: '',
            validasi: false,
            kategori: [],
            datakategori: [],
            defaultSelectedkategori: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedkategori: 0,

            filenya_peta: '',
            fill: '#00BFFF',
            stroke: '#000000',
            fill_width: '1',
            stroke_width: '1',
            dash_start: '1',
            dash_end: '1',
            filenya_ikon: '',
            urut: '1',
            urut_menu: '0',

            asalpeta: [],
            dataasalpeta: [],
            defaultSelectedasalpeta: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedasalpeta: 0,

            apipeta: [],
            dataapipeta: [],
            defaultSelectedapipeta: {
                code: 0,
                label: 'Tidak Ada',
                tag: 0,
            },
            selectedapipeta: 0,

            tipepeta: [],
            datatipepeta: [],
            defaultSelectedTipePeta: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedtipepeta: 0,

            tipe_fill: [],
            datatipe_fill: [],
            defaultSelectedTipeFill: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedtipe_fill: 1,

            tipe_pattern: [],
            datatipe_pattern: [],
            defaultSelectedTipePattern: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedtipe_pattern: 1,
            sudut: '0',
        }
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        onkategoriChange(a) {
            try {
                this.selectedkategori = this.kategori[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onTipeFillChange(a) {
            try {
                this.filenya_peta = document.getElementById('filenya_peta').value;
                this.filenya_ikon = document.getElementById('filenya_ikon').value;
                this.selectedtipe_fill = this.tipe_fill[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onTipePatternChange(a) {
            try {
                this.filenya_peta = document.getElementById('filenya_peta').value;
                this.filenya_ikon = document.getElementById('filenya_ikon').value;
                this.selectedtipe_pattern = this.tipe_pattern[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onTipePetaChange(a) {
            try {
                this.filenya_peta = document.getElementById('filenya_peta').value;
                this.filenya_ikon = document.getElementById('filenya_ikon').value;
                this.selectedtipepeta = this.tipepeta[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onasalpetaChange(a) {
            try {
                this.selectedasalpeta = this.asalpeta[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onapipetaChange(a) {
            try {
                this.selectedapipeta = this.apipeta[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/LayerPeta");
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakita-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        uploadfilepeta(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            //alert(elmnt.files[0].name);
            //alert(elmnt.files[0].name.slice(-7));
            try {
                if (elmnt.files[0].name == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan File geojson..',
                    });
                    return false;
                }
                if (elmnt.files[0].name === undefined) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan File geojson..',
                    });
                    return false;
                }
                if (elmnt.files[0].name.slice(-7).toUpperCase() != 'GEOJSON') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Kesalahan Format Data Peta..',
                    });
                    return false;
                }

                var fd = new FormData();
                fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
                fd.append("folderToUpload", idnya);
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        "Content-Type": "form-data",
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                mainAPI.post("datakita-FilesUpload", fd, {
                    onUploadProgress: function (uploadEvent) {
                        document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                        document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    }
                }).then(
                    function (res) {
                        document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                        if (res.data.filename != undefined) {
                            document.getElementById(idnya).value = res.data.filename;
                            //this.filenya_peta = document.getElementById(idnya).value;
                        }
                    }
                ).catch(function (e) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    document.getElementById(idnya).value = '';
                    console.log(e);
                }
                )
            } catch (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan File geojson..',
                });
                return false;
            }

        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("datakita-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async AmbilData() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPIDataSingel.get("datakita_kategori-GetKategori").then(
                Response => {
                    this.datakategori = [];
                    this.kategori = [];
                    this.datakategori = Response.data.content.data;
                    this.datakategori.forEach((item) => {
                        this.kategori.push({
                            label: item.nama,
                            code: this.datakategori.indexOf(item) + 1,
                            tag: item.id,
                        });
                    });
                    this.kategori.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
            this.halamanloading = true;
            const mainAPI5 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI5.get("mtipepeta_datakita-GetTipePeta").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipepeta = [];
                        this.tipepeta = [];
                        this.datatipepeta = Response.data.content.data;
                        this.datatipepeta.forEach((item) => {
                            this.tipepeta.push({
                                label: item.nama,
                                code: this.datatipepeta.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipepeta.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI6 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI6.get("mtipepeta_datakita-GetFillTipe").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipe_fill = [];
                        this.tipe_fill = [];
                        this.datatipe_fill = Response.data.content.data;
                        this.datatipe_fill.forEach((item) => {
                            this.tipe_fill.push({
                                label: item.nama,
                                code: this.datatipe_fill.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipe_fill.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI7 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI7.get("mtipepeta_datakita-GetJenisPattern").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipe_pattern = [];
                        this.tipe_pattern = [];
                        this.datatipe_pattern = Response.data.content.data;
                        this.datatipe_pattern.forEach((item) => {
                            this.tipe_pattern.push({
                                label: item.nama,
                                code: this.datatipe_pattern.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipe_pattern.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI8 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI8.get("maps_datakita-GetAsalPeta").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataasalpeta = [];
                        this.asalpeta = [];
                        this.dataasalpeta = Response.data.content.data;
                        this.dataasalpeta.forEach((item) => {
                            this.asalpeta.push({
                                label: item.nama,
                                code: this.dataasalpeta.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.asalpeta.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI9 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI9.get("maps_datakita-GetAPIPeta").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataapipeta = [];
                        this.apipeta = [];
                        this.dataapipeta = Response.data.content.data;
                        this.dataapipeta.forEach((item) => {
                            this.apipeta.push({
                                label: item.nama,
                                code: this.dataapipeta.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.apipeta.unshift({
                            label: 'Tidak Ada',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPIDataSingel.get("datakita_kategori-GetKategori").then(
                Response => {
                    this.datakategori = [];
                    this.kategori = [];
                    this.datakategori = Response.data.content.data;
                    this.datakategori.forEach((item) => {
                        this.kategori.push({
                            label: item.nama,
                            code: this.datakategori.indexOf(item) + 1,
                            tag: item.id,
                        });
                    });
                    this.kategori.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
            this.halamanloading = true;
            const mainAPI5 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI5.get("mtipepeta_datakita-GetTipePeta").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipepeta = [];
                        this.tipepeta = [];
                        this.datatipepeta = Response.data.content.data;
                        this.datatipepeta.forEach((item) => {
                            this.tipepeta.push({
                                label: item.nama,
                                code: this.datatipepeta.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipepeta.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI6 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI6.get("mtipepeta_datakita-GetFillTipe").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipe_fill = [];
                        this.tipe_fill = [];
                        this.datatipe_fill = Response.data.content.data;
                        this.datatipe_fill.forEach((item) => {
                            this.tipe_fill.push({
                                label: item.nama,
                                code: this.datatipe_fill.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipe_fill.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI7 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI7.get("mtipepeta_datakita-GetJenisPattern").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipe_pattern = [];
                        this.tipe_pattern = [];
                        this.datatipe_pattern = Response.data.content.data;
                        this.datatipe_pattern.forEach((item) => {
                            this.tipe_pattern.push({
                                label: item.nama,
                                code: this.datatipe_pattern.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipe_pattern.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI8 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI8.get("maps_datakita-GetAsalPeta").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataasalpeta = [];
                        this.asalpeta = [];
                        this.dataasalpeta = Response.data.content.data;
                        this.dataasalpeta.forEach((item) => {
                            this.asalpeta.push({
                                label: item.nama,
                                code: this.dataasalpeta.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.asalpeta.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI9 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI9.get("maps_datakita-GetAPIPeta").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataapipeta = [];
                        this.apipeta = [];
                        this.dataapipeta = Response.data.content.data;
                        this.dataapipeta.forEach((item) => {
                            this.apipeta.push({
                                label: item.nama,
                                code: this.dataapipeta.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.apipeta.unshift({
                            label: 'Tidak Ada',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPIDataSingel.get("maps_datakita-GetGetPetaLokasiByID?id=" + this.secretencData).then(
                Response => {
                    this.defaultSelectedkategori = {
                        code: Response.data.content.data[0].id_kategori,
                        label: Response.data.content.data[0].namakategori,
                        tag: Response.data.content.data[0].id_kategori,
                    };
                    this.selectedkategori = Response.data.content.data[0].id_kategori;

                    this.defaultSelectedasalpeta = {
                        code: Response.data.content.data[0].asal_peta,
                        label: Response.data.content.data[0].namasal,
                        tag: Response.data.content.data[0].asal_peta,
                    };
                    this.selectedasalpeta = Response.data.content.data[0].asal_peta;

                    this.defaultSelectedapipeta = {
                        code: Response.data.content.data[0].api,
                        label: Response.data.content.data[0].namapi,
                        tag: Response.data.content.data[0].api,
                    };
                    this.selectedapipeta = Response.data.content.data[0].api;

                    this.urut_menu = Response.data.content.data[0].urut_menu;
                    this.nama = Response.data.content.data[0].nama;
                    this.nama_eng = Response.data.content.data[0].nama_eng;
                    var label = 'Pilih Salah Satu';

                    if (Response.data.content.data[0].tipe_peta == "1") {
                        label = 'Polygon';
                    }
                    else if (Response.data.content.data[0].tipe_peta == "2") {
                        label = 'Point';
                    }
                    else if (Response.data.content.data[0].tipe_peta == "3") {
                        label = 'Line';
                    }
                    else {
                        label = 'Pilih Salah Satu';
                    }

                    this.defaultSelectedTipePeta = {
                        code: Response.data.content.data[0].tipe_peta,
                        label: label,
                        tag: Response.data.content.data[0].tipe_peta,
                    };
                    this.selectedtipepeta = Response.data.content.data[0].tipe_peta;

                    this.filenya_peta = Response.data.content.data[0].filenya_peta;

                    this.halamanloading = false;

                    if (Response.data.content.data[0].tipe_fill == "1") {
                        var namatipe_fill = 'Fill';
                    }
                    else {
                        var namatipe_fill = 'Pattern';
                    }

                    if (Response.data.content.data[0].tipe_pattern == "1") {
                        var namatipe_pattern = 'hatch';
                    }
                    else {
                        var namatipe_pattern = 'cross';
                    }

                    this.defaultSelectedTipeFill = {
                        code: Response.data.content.data[0].tipe_fill,
                        label: namatipe_fill,
                        tag: Response.data.content.data[0].tipe_fill,
                    };
                    this.selectedtipe_fill = Response.data.content.data[0].tipe_fill;

                    this.defaultSelectedTipePattern = {
                        code: Response.data.content.data[0].tipe_pattern,
                        label: namatipe_pattern,
                        tag: Response.data.content.data[0].tipe_pattern,
                    };
                    this.selectedtipe_pattern = Response.data.content.data[0].tipe_pattern;

                    this.fill = Response.data.content.data[0].fill;
                    this.stroke = Response.data.content.data[0].stroke;
                    this.fill_width = Response.data.content.data[0].fill_width;
                    this.stroke_width = Response.data.content.data[0].stroke_width;
                    this.dash_start = Response.data.content.data[0].dash_start;
                    this.dash_end = Response.data.content.data[0].dash_end;
                    this.filenya_ikon = Response.data.content.data[0].filenya_ikon;
                    this.urut = Response.data.content.data[0].urut;
                    this.sudut = Response.data.content.data[0].sudut;

                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if ((this.selectedkategori == '') || (this.selectedkategori === null) || (this.selectedkategori === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Kategori..',
                });
                return false;
            }
            if (document.getElementById('nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama').focus();
                return false;
            }
            if (document.getElementById('nama_eng').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama_eng').focus();
                return false;
            }
            if ((this.selectedasalpeta == '') || (this.selectedasalpeta === null) || (this.selectedasalpeta === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Sumber Peta..',
                });
                return false;
            }
            if (this.selectedasalpeta == '1') {
                if ((document.getElementById('filenya_peta').value == '') || (document.getElementById('filenya_peta').value === null) || (document.getElementById('filenya_peta').value === undefined)) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan File Geojson..',
                    });
                    return false;
                }
            }

            if (this.selectedasalpeta == '2') {
                if ((this.selectedapipeta == '') || (this.selectedapipeta === null) || (this.selectedapipeta === undefined)) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Pilih API Peta..',
                    });
                    return false;
                }
            }

            if ((this.selectedtipepeta == '') || (this.selectedtipepeta === null) || (this.selectedtipepeta === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Tipe Data Peta..',
                });
                return false;
            }
            if (this.selectedtipepeta == 1) {
                if ((this.selectedtipe_fill == '') || (this.selectedtipe_pattern == '') || (this.selectedtipe_fill === null) || (this.selectedtipe_pattern === null) || (this.selectedtipe_fill === undefined) || (this.selectedtipe_pattern === undefined)) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Pilih Tipe Fill Polygon Peta..',
                    });
                    return false;
                }
            }

            if (this.selectedtipepeta == 2) {
                if ((document.getElementById('filenya_ikon').value == '') || (document.getElementById('filenya_ikon').value === null) || (document.getElementById('filenya_ikon').value === undefined)) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Ikon Point..',
                    });
                    return false;
                }
            }
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("id_kategori", this.selectedkategori);
            fd.append("urut_menu", this.urut_menu);
            fd.append("nama", this.nama);
            fd.append("nama_eng", this.nama_eng);
            fd.append("filenya_peta", document.getElementById('filenya_peta').value);
            fd.append("tipe_peta", this.selectedtipepeta);
            fd.append("fill", this.fill);
            fd.append("stroke", this.stroke);
            fd.append("fill_width", this.fill_width.toString().replaceAll(",", "."));
            fd.append("stroke_width", this.stroke_width.toString().replaceAll(",", "."));
            fd.append("dash_start", this.dash_start.toString().replaceAll(",", "."));
            fd.append("dash_end", this.dash_end.toString().replaceAll(",", "."));
            fd.append("filenya_ikon", document.getElementById('filenya_ikon').value);
            fd.append("urut", this.urut);
            fd.append("tipe_fill", this.selectedtipe_fill);
            fd.append("tipe_pattern", this.selectedtipe_pattern);
            fd.append("asal_peta", this.selectedasalpeta);
            fd.append("api", this.selectedapipeta);
            fd.append("sudut", this.sudut);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("maps_datakita-AddPetaLokasi", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/LayerPeta");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("id_kategori", this.selectedkategori);
            fd.append("urut_menu", this.urut_menu);
            fd.append("nama", this.nama);
            fd.append("nama_eng", this.nama_eng);
            fd.append("filenya_peta", document.getElementById('filenya_peta').value);
            fd.append("tipe_peta", this.selectedtipepeta);
            fd.append("fill", this.fill);
            fd.append("stroke", this.stroke);
            fd.append("fill_width", this.fill_width.toString().replaceAll(",", "."));
            fd.append("stroke_width", this.stroke_width.toString().replaceAll(",", "."));
            fd.append("dash_start", this.dash_start.toString().replaceAll(",", "."));
            fd.append("dash_end", this.dash_end.toString().replaceAll(",", "."));
            fd.append("filenya_ikon", document.getElementById('filenya_ikon').value);
            fd.append("urut", this.urut);
            fd.append("tipe_fill", this.selectedtipe_fill);
            fd.append("tipe_pattern", this.selectedtipe_pattern);
            fd.append("asal_peta", this.selectedasalpeta);
            fd.append("api", this.selectedapipeta);
            fd.append("sudut", this.sudut);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("maps_datakita-UpdatePetaLokasi", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/LayerPeta");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
        else {
            this.AmbilData();
        }
    }
}
</script>
<style></style>